import React, { Fragment, useEffect, useState } from "react";
import "./signup.css";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const Singup = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validate, setvalidate] = useState(false);
  const handleChange = (event) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (pattern.test(event.target.value)) {
      setEmail(event.target.value);
      setvalidate(true);
      return false;
    } else {
      setEmail("");
      setvalidate(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const otp = await fetch(
        "https://admin.simplisend.com/v1/api/tempreg",
        {
          method: "POST",
          body: JSON.stringify({ email }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const result = await otp.json();
      console.log(result.error);
      if (result.error) {
        toast.error(result?.error?.errorMessage);
      } else {
        setTimeout(() => {
          toast.success(result?.response?.message?.successMessage);
        }, 1000);
        props.getallDataforSingup({ email });
        props.Settext("email");
        navigate("/signup_verify");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getEmailotp=async(e)=>{
  //   if(email === ""){
  //     toast.error("enter your email adress")
  //   }
  //   e.preventDefault()
  //   try {
  //     const otp=await fetch("http://18.168.8.82/v1/api/tempreg",{
  //     method:"POST",

  //     body:JSON.stringify({email}),
  //     headers:{
  //       "content-type":"application/json"
  //     }
  //   })
  //   const result=await otp.json()
  //   toast.success(result?.response?.message?.successMessage)
  //   props.getallDataforSingup({email})
  //   // setTimeout(() => {
  //   //   navigate("/signup_pin")
  //   // }, 2000)
  //   // navigate("/signup_pin")

  //   } catch (error) {
  //     console.log(error)
  //   }
  //   }
  const switchTomobile = () => {
    props.setActive("");
    props.Settext("");
  };

  useEffect(() => {
    props.setSignup([]);
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    // props.setActive("")
  }, []);

  return (
    <Fragment>
      <div className="content">
        <Toaster toastOptions={{ duration: 1000 }} />
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" id="sign-up">
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <p className="txt-1">
              Sign up information <span>1 of 6</span>
            </p>
            <p className="responsive">
              <img src="./dist/img/Line 3.png" />
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
            </p>
            <h3 className="pt-5 pb-3">Enter your email</h3>
            <p className="txt-3">
              Enter your email address, and you'll receive a code to verify it's
              you.
            </p>
            <form onSubmit={handleClick}>
              <div className="form-group pt-5">
                <input
                  type="email"
                  // className="form-control"
                  id="exampleInputEmail2"
                  aria-describedby="emailHelp"
                  placeholder="Enter Address"
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#161F4C",
                    lineHeight: "14px",
                    fontFamily: "Outfit",
                    fontStyle: "normal",
                    background: "url('./dist/img/Message_light.png')",
                    outlineColor: "#27AE60",
                    outlineWidth: "2px",
                  }}
                  // value={email}
                  onChange={handleChange}
                  // onClick={handleClick}
                />
              </div>
              <p className="txt-2 py-4">
                By entering and tapping Next, you agree to the{" "}
                <span>
                  Terms, E-Sign
                  <br /> Consent
                </span>{" "}
                & <span>Privacy Policy.</span>
              </p>
              <a
                type="button"
                className="btn btn-1 btn-block"
                onClick={switchTomobile}
              >
                Use Mobile
              </a>
              <button
                disabled={!validate}
                type="submit"
                className="btn btn-2 btn-block"
                style={
                  validate
                    ? { padding: "12px" }
                    : { opacity: "0.2", padding: "12px" }
                }
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Singup;
