import React, { Fragment, useEffect, useState } from "react";
import "./login.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Useraction } from "../../Redux/action/Useraction";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { resetLogin } from "../../Redux/reducer/Userreducer";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as authentication from "../../helpers/Auth";
import { Toaster, toast } from "react-hot-toast";
import SendBird from "sendbird";
import { getProfileDetails } from "../../Redux/action/Profile";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userinfo, setuserinfo] = useState({});
  const provider = new GoogleAuthProvider();
  const provider2 = new FacebookAuthProvider();
  let error = useSelector(
    (state) => state?.login?.login?.data?.error?.errorMessage
  );
  let success = useSelector(
    (state) => state?.login?.login?.data?.response?.message?.successMessage
  );
  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  // const loginHandler = (values) => {
  //   const { username, password } = values;
  //   setLoading(true);

  //   const postData = {
  //     password: password,
  //   };
  //   if (username.includes("@")) {
  //     postData.email = username;
  //   } else {
  //     postData.mobileNo = username;
  //   }
  //   axios
  //     .post("https://admin.simplisend.com/v1/api/login/", postData)
  //     .then((res) => {
  //       if (res.data?.response?.message?.successMessage) {
  //         toast.success(res.data.response.message.successMessage, {
  //           position: "top-right",
  //           style: {
  //             borderBottom: "4px solid #33a34e",
  //             padding: "16px",
  //             color: "#3c5f4b",
  //             marginRight: "25px",
  //           },
  //         });
  //         navigate("/dashboard");
  //         authentication.login(`Bearer ${res.data.response.data.token}`);
  //       } else {
  //         toast.error("Please enter valid Username or Password");
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("An error occurred. Please try again.");
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const loginHandler = (values) => {
    const { username, password } = values;
    setLoading(true);

    const postData = {
      password: password,
    };
    if (username.includes("@")) {
      postData.email = username;
    } else {
      postData.mobileNo = username;
    }
    axios
      .post("https://admin.simplisend.com/v1/api/login/", postData)
      .then(async (res) => {
        if (res.data?.response?.message?.successMessage) {
          toast.success(res.data.response.message.successMessage, {
            position: "top-right",
            style: {
              borderBottom: "4px solid #33a34e",
              padding: "16px",
              color: "#3c5f4b",
              marginRight: "25px",
            },
          });
          authentication.login(`Bearer ${res.data.response.data.token}`);
          const sb = new SendBird({
            appId: "AC74B4DB-10DC-4F4B-90D0-FC9291D1D79B",
          });

          const userId =
            userDetails && userDetails.id ? userDetails.id.toString() : "";
          const nickname = `${userDetails?.firstName} ${userDetails?.lastName}`;
          const profileUrl = userDetails?.profileImage;

          sb.connect(userId, (user, error) => {
            if (error) {
              console.error(error);
            } else {
              sb.updateCurrentUserInfo(
                nickname,
                profileUrl,
                (response, error) => {
                  if (error) {
                    console.error(error);
                  } else {
                    console.log("User created successfully:", response);
                  }
                }
              );
            }
          });

          navigate("/dashboard");
        } else {
          toast.error("Please enter a valid Username or Password");
        }
      })
      .catch((err) => {
        toast.error("An error occurred. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const SigninwithGoogle = () => {
    const devicetype = 1;
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result);
        const email = result.user.reloadUserInfo.providerUserInfo[0].email;
        const id = result.user.reloadUserInfo.providerUserInfo[0].rawId;
        authenticateGoogle(email, id, devicetype);
        setuserinfo(result.user.reloadUserInfo);
        props.setActive("social");
        setTimeout(() => {
          props.getallDataforSingup({
            ...result._tokenResponse,
            social: "true",
          });
        }, 2000);
        // props.getallDataforSingup(result)
      })
      .catch((err) => console.log(err));
  };
  const SigninwithFacebook = () => {
    const devicetype = 2;
    signInWithPopup(auth, provider2)
      .then((result) => {
        const email = result.user.reloadUserInfo.providerUserInfo[0].email;
        const id = result.user.reloadUserInfo.providerUserInfo[0].rawId;
        authenticateGoogle(email, id, devicetype);
        setuserinfo(result.user.reloadUserInfo);
        props.setActive("social");
        setTimeout(() => {
          props.getallDataforSingup({
            ...result._tokenResponse,
            social: "true",
          });
        }, 2000);
        // props.getallDataforSingup(result)
      })
      .catch((err) => console.log(err));
  };
  const authenticateGoogle = async (email, id, devicetype) => {
    try {
      const result = await fetch(
        "https://admin.simplisend.com/v1/api/social_login/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            authServiceProviderType: devicetype,
            authServiceProviderId: id,
          }),
        }
      );
      const data = await result.json();

      if (data.error?.errorCode == 201 || data.error?.errorCode == 302) {
        setTimeout(() => {
          navigate("/signup_dob");
        }, 1000);
      } else if (data.response?.message?.statusCode == 200) {
        navigate("/");
      } else if (data.error?.errorCode == 400) {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginDashboard = () => {};

  const formInitialSchema = {
    username: "",
    password: "",
  };
  const formValidationSchema = Yup.object().shape({
    username: Yup.string()
      // .username("Please enter a valid username address.")
      // .max(12, "Should not exceeds 12 characters.")
      .required("username is required."),

    password: Yup.string()
      .trim()
      .required("Password is required")
      .max(16, "Should not exceeds 16 characters.")
      .min(8, "Must be minimum 8 characters."),
  });
  const handleFormSubmit = async (values) => {};

  return (
    <>
      <Toaster />

      <div className="content">
        {" "}
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" id="sign-up">
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5" id="login">
            <p className="welcome">Welcome back to </p>
            <h3>Simplisend</h3>
            <Formik
              initialValues={formInitialSchema}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={loginHandler}
            >
              {({ errors, handleBlur, handleChange, touched, values }) => (
                <Form>
                  <div className="form-group pt-2">
                    <input
                      type="text"
                      aria-describedby="emailHelp"
                      placeholder="Email/Mobile number"
                      value={values.username}
                      error={Boolean(touched.username && errors.username)}
                      onBlur={handleBlur}
                      name="username"
                      autoComplete="off"
                      // autoComplete="username"
                      onChange={handleChange}
                    />
                    {touched.username && errors.username && (
                      <div className="errorText">{errors.username}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      // autoComplete="password"
                      autoComplete="off"
                      aria-describedby="emailHelp"
                      placeholder="Password"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.password && errors.password && (
                      <div className="errorText">{errors.password}</div>
                    )}
                  </div>
                  <p className="txt-5" style={{ textAlign: "left !important" }}>
                    <span>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                        style={{
                          height: "unset",
                          width: "unset",
                          float: "unset !important",
                        }}
                      />
                      <label
                        htmlFor="vehicle1"
                        style={{
                          color: "#334399 !important",
                          fontWeight: "400",
                        }}
                      >
                        {" "}
                        Remember me{" "}
                      </label>
                    </span>
                    <span>
                      <Link
                        to="/login_forgot_password"
                        style={{
                          color: "#27AE60",
                          float: "right",
                          // marginRight: "58px",
                          cursor: "pointer",
                          fontWeight: 500,
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </span>
                  </p>
                  {/* <Link to="/dashboard"> */}
                  <button
                    type="submit"
                    className="btn btn-2 btn-block"
                    style={{ marginTop: "40px", padding: "10px" }}
                    // onClick={loginDashboard}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Login"}
                  </button>
                  {/* </Link> */}
                  <p className="txt-2">Or</p>
                  <p className="txt-3">
                    <strong style={{ fontWeight: 600 }}>continue with</strong>
                  </p>
                  <p className="text-center image-section-new">
                    <img
                      src="./dist/img/social.png"
                      onClick={SigninwithGoogle}
                    />{" "}
                    <span>
                      <img src="./dist/img/apple.png" />
                    </span>{" "}
                    <span>
                      <img
                        src="./dist/img/facebook.png"
                        onClick={SigninwithFacebook}
                      />
                    </span>
                  </p>
                  <p className="txt-4">
                    Don’t have a account?{" "}
                    <Link to="/signup" style={{ fontWeight: 600 }}>
                      Create new account
                    </Link>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
