import React, { Fragment, useEffect, useState } from "react";
import "./Signupcountry.css";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import loader from "../Loading.json";
import Lottie from "lottie-react";

const SignupCountry = (props) => {
  const navigate = useNavigate();
  const [query, setQuery2] = useState("");
  const [countrylist, setcountryList] = useState([]);
  const [countryListdrop, setcountryListDrop] = useState();
  const [countryDrop, setCountryDrop] = useState(false);
  const [country, setCountry] = useState("");
  const [showinput, setShowinput] = useState(false);
  const [loading, Setlaoding] = useState(true);

  const [userinfo, setUserInfo] = useState({
    fromCountryId: "",
    toCountryIds: [],
    Countryname: [],
  });

  const [visible, setvisible] = useState("");
  const openDrop = () => {
    if (visible === "") {
      setvisible("visible");

      setQuery2("");
    } else {
      setvisible("");
      setQuery2("");
    }
  };

  const handler = (e, item) => {
    const { value, checked } = e.target;

    const { toCountryIds, Countryname } = userinfo;

    console.log(`${value} is ${checked}`);
    if (checked) {
      console.log(e.target.value.length);
      setUserInfo({
        toCountryIds: [...toCountryIds, value],
        Countryname: [...Countryname, item],
      });
    } else {
      setUserInfo({
        toCountryIds: toCountryIds.filter((e) => e !== value),
        Countryname: Countryname.filter((e) => e !== item),
      });
    }
  };
  console.log(userinfo.Countryname);
  const setPinPage = () => {
    if (userinfo.toCountryIds.length === 0) {
      toast.error("you have to select where you want to send money");
    } else if (userinfo.Countryname.length > 5) {
      toast.error("you can select maximum 5 countries");
    } else {
      props.getallDataforSingup({ userinfo });
      navigate("/signup_pin");
    }
  };
  const setpinFromemail = () => {
    console.log("email");
    if (userinfo.toCountryIds.length === 0) {
      toast.error("you have to select where you want to send money");
    } else if (userinfo.Countryname.length > 5) {
      toast.error("you can select maximum 5 countries");
    } else {
      //  const fromCountryId = props.defaultCountry.id;
      props.getallDataforSingup({
        userinfo,
        fromCountryId: country ? country.id : props.defaultCountry.id,
      });
      navigate("/signup_pin");
    }
  };
  const handleShow = () => {
    setCountryDrop(!countryDrop);
    setShowinput(!showinput);
  };
  const handleOptionClick = (option) => {
    setCountry(option);
    setCountryDrop(false);
    setShowinput(false);
  };
  const querySearchTwo = (e) => {
    const newSearchValue = e.target.value;
    setQuery2(e.target.value);
    const newFilteredArray = newSearchValue
      ? countrylist &&
        countrylist.filter((item) =>
          item.countryName.toLowerCase().includes(newSearchValue.toLowerCase())
        )
      : countrylist;
    setcountryListDrop(newFilteredArray);
  };

  useEffect(() => {
    const fecthdata = async () => {
      const data = await fetch(
        "https://admin.simplisend.com/v1/api/country/code/list"
      );
      const res = await data.json();
      const allcountry = [
        ...res?.response?.top5countries,
        ...res?.response?.data,
      ];

      setcountryList(allcountry);
      setcountryListDrop(allcountry);
      Setlaoding(false);
    };
    fecthdata();
  }, [showinput, visible]);
  useEffect(() => {
    if (userinfo.Countryname.length > 5) {
      toast.error("you can select maximum 5 countries");
    }
  }, [userinfo]);

  return (
    <Fragment>
      {loading ? (
        <div className="loader">
          <Lottie animationData={loader} />;
        </div>
      ) : (
        <>
          <div className="content">
            <Toaster toastOptions={{ duration: 2000 }} />
            <div className="header">
              <img src="./dist/img/image 15.png" className="img-fluid" />
            </div>
            <div className="circle-border"></div>

            <div className="circle">
              <img src="./dist/img/Ellipse 5.png" />
              <div className="new-2">
                <img src="./dist/img/Mask group.png" />
              </div>
            </div>
          </div>
          <div className="container my-5" id="sign-up">
            <div className="cross-btn">
              <Link to="/">
                <img src="./dist/img/Vector (14).png" />
              </Link>
            </div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 py-5">
                <p className="txt-1">
                  Sign up information <span>5 of 6</span>
                </p>
                <p className="responsive">
                  <img src="./dist/img/Line 3.png" />{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 4.png" />
                  </span>
                </p>
                <h3 className="pt-5 pb-3">Where do you want to send money?</h3>
                <p className="txt-3">
                  Please select the country or countries where you would like to
                  send your
                  <br /> money.
                </p>
                <form>
                  <div
                    className="form-group pt-5"
                    style={{ position: "relative" }}
                  >
                    {showinput && (
                      <input
                        type="search"
                        id="search-data"
                        placeholder="search country"
                        onChange={querySearchTwo}
                        //  value={query}
                      />
                    )}

                    {props.active === "email" || props.active === "social" ? (
                      <input
                        type="text"
                        // className="form-control new"
                        // id="exampleInputEmail1"
                        // aria-describedby="emailHelp"
                        placeholder="from country"
                        onClick={handleShow}
                        defaultValue={country.countryName}
                        style={{ fontSize: "14px", color: "#161f4c" }}
                      />
                    ) : (
                      ""
                    )}

                    {countryDrop && (
                      <div className="box-class-country">
                        {" "}
                        <ul
                          className="select-options-mobile"
                          style={{ paddingTop: "80px !important" }}
                        >
                          {countryListdrop?.map((option, index) => (
                            <li
                              key={index}
                              className="select-item-new"
                              onClick={() => handleOptionClick(option)}
                            >
                              <img
                                src={option.flagIconUrl}
                                alt={`Flag Icon ${option.id}`}
                              />
                              {option.countryName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* {props.active=== "email" || props.active ==="social" ? <input type="text" className="form-control new" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Sending from"/>:""}  */}
                  </div>
                  <div
                    className="form-group pt-2"
                    style={{ position: "relative" }}
                  >
                    <div
                      id="list1"
                      className={`dropdown-check-list ${visible}`}
                      tabIndex="100"
                    >
                      <span
                        className="anchor"
                        onClick={openDrop}
                        style={{ fontSize: "14px", color: "#161f4c" }}
                      >
                        {userinfo.Countryname.length > 0
                          ? userinfo.Countryname.toString()
                          : "Sending to"}
                      </span>
                      <ul className="items">
                        <li>
                          <div className="input-append">
                            <input
                              data-provide="typeahead"
                              data-items="4"
                              type="text"
                              className="span2 search-query"
                              placeholder="search"
                              style={{
                                paddingLeft: "50px",
                                fontFamily: '"Outfit", sans-serif',
                                border: "1.11475px solid rgba(38, 50, 56, 0.2)",
                                background:
                                  "url('./dist/img/line-1.png') no-repeat 22px 26px",
                                boxShadow:
                                  "0px 10.0328px 39.0164px -4.45902px rgba(0, 0, 0, 0.04)",
                                borderRadius: "20.0656px",
                                height: "73px",
                                width: "334px",
                              }}
                              onChange={querySearchTwo}
                              value={query}
                            />
                          </div>
                        </li>
                        {/* <hr /> */}
                        {/* <li>
                      Select all <input type="checkbox" />
                    </li> */}
                        <hr />
                        {countryListdrop &&
                          countryListdrop.map((item, i) => (
                            <div key={item.id} id="select-country-new">
                              <input
                                id={item.id}
                                type="checkbox"
                                value={item.id}
                                onChange={(e) => handler(e, item.countryName)}
                              />
                              <label
                                htmlFor={item.id}
                                className="dropdown-label"
                              >
                                <img
                                  src={item.flagIconUrl}
                                  style={{
                                    width: "48px",
                                    borderRadius: "50%",
                                    height: "48px",
                                    marginRight: "20px",
                                  }}
                                ></img>
                                {item.countryName}
                              </label>
                              {/* </li> */}
                            </div>
                          ))}

                        <li
                          style={{ paddingTop: "20px", paddingBottom: "15px" }}
                        >
                          {/* <button
                      type="button"
                        className="clear-ok"
                        onClick={getIdofCountry}
                        style={{ color: "#fff", borderRadius: "16px" }}
                      >
                        Continue
                      </button> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a
                    type="button"
                    className="btn btn-2 btn-block mt-5"
                    onClick={props.active === "" ? setPinPage : setpinFromemail}
                  >
                    Next
                  </a>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default SignupCountry;
