import React from "react";
import "./footer.css";
const Footr = () => {
  return (
    <div className="footer-section">
        <div className="container mt-5 " >
      <div className="row justify-content-evenly">
        <div className="col-3">
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
        </div>
        <div className="col-3">
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
        </div>
        <div className="col-3">
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
        </div>
        <div className="col-3">
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
          <div>General</div>
          <div>About us</div>
        </div>
        
      </div>
    </div>
    </div>
  );
};

export default Footr;
