import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
const Metadecorator = ({ title, description, imageurl, imagealt }) => {
  return (
    <Helmet>
      <title>{title} </title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />

      <meta property="og:image" content={imageurl} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content={imageurl} />

      <meta
        property="og:description"
        content="Offering tour packages for individuals or groups."
      />
      <meta
        property="og:image"
        content="https://i.guim.co.uk/img/media/ef8492feb3715ed4de705727d9f513c168a8b196/37_0_1125_675/master/1125.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=d456a2af571d980d8b2985472c262b31"
      />
      <meta property="og:site_name" content="European Travel, Inc." />
      <meta name="twitter:image:alt" content={imagealt} />
    </Helmet>
  );
};
Metadecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageurl: PropTypes.string.isRequired,
  imagealt: PropTypes.string.isRequired,
};
export default Metadecorator;
