import axios from "axios";
import { actiontype } from "../actiontype/Actiontype";

export const Signupaction = (regData) => async (dispatch) => {
  console.log(regData);
  try {
    let data;
    if (regData.mobileno) {
      console.log("mobile");
      data = await axios.post(
        "https://admin.simplisend.com/v1/api/cust/registration",
        {
          // countryCodeId: regData.fromCountryId,
          countryCodeId: regData.fromCountryId,
          firstName: regData.fname,
          lastName: regData.lname,
          mobileNo: regData.mobileno,
          password: regData.password,
          deviceTypeId: 1,
          pin: regData.pin,
          fromCountryId: regData.fromCountryId,
          toCountryIds: regData.userinfo.toCountryIds,
          dob: regData.dob,
          address: regData.address,
          address2: regData.adressto,
          city: regData.city,
          state: regData.state,
          zipcode: regData.zipcode,
        }
      );
    } else if (regData.email) {
      console.log(regData);
      console.log("email");

      data = await axios.post(
        "https://admin.simplisend.com/v1/api/cust/registration",
        {
          firstName: regData.fname,
          lastName: regData.lname,
          email: regData.email,
          password: regData.password,
          deviceTypeId: 1,
          pin: regData.pin,
          fromCountryId: regData.fromCountryId,
          toCountryIds: regData.userinfo.toCountryIds,
          dob: regData.dob,
          address: regData.address,
          address2: regData.adressto,
          city: regData.city,
          state: regData.state,
          zipcode: regData.zipcode,
        }
      );
    } else {
      dispatch({
        type: actiontype.SIGNUP_ERROR,
        payload: "Both email and mobileNo cannot be empty",
      });
      return;
    }
    dispatch({
      type: actiontype.SIGNUP_SUCCESSFULL,
      payload: data.data.response,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: actiontype.SIGNUP_ERROR,
      payload: error.response.data.error.errorMessage,
    });
  }
};

export const SocialSignupReg = (regData) => async (dispatch) => {
  console.log(regData);
  console.log("social");
  try {
    const data = await axios.post(
      "https://admin.simplisend.com/v1/api/social_login_step2/",
      {
        countryCodeId: regData.fromCountryId,

        firstName: regData.firstName,
        lastName: regData.lastName,
        email: regData.email,
        password: "Pradeep%1",
        deviceTypeId: 1,
        pin: regData.pin,
        fromCountryId: regData.fromCountryId,
        toCountryIds: regData.userinfo.toCountryIds,
        dob: regData.dob,
        address: regData.address,
        address2: regData.adressto,
        city: regData.city,
        state: regData.state,
        zipcode: regData.zipcode,
      }
    );
    console.log(data);
    dispatch({ type: actiontype.SOCIAL_SIGNUP_SUCCESS, payload: data });
  } catch (error) {
    console.log("error");
  }
};
