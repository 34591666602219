import { actiontype } from "../actiontype/Actiontype"
const intitialstateOfotp={
    verify:{},
    error:""
}
 const ResetpassVerifyOtp=(state=intitialstateOfotp,action)=>{

    switch (action.type) {
        case actiontype.VERIFY_OTP_REQUEST:
            return {
                ...state,
                verify:{}
            }
        case actiontype.VERIFY_OTP_FOR_RESET_PASS_SUCCESS:
            return {
                ...state,
                verify:action.payload
            }
         
            
         case actiontype.VERIFY_OTP_FOR_RESET_PASS_FAIL:
            return{
            ...state,
            error:action.payload
            }
    
        default:
            return state
    }

}
export default ResetpassVerifyOtp

export const resetOtp=()=>{
    return {type:actiontype.VERIFY_OTP_REQUEST}
}