import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import LineChart from "react-linechart";
import "./dashboard.css";
import "../../../node_modules/react-linechart/dist/styles.css";
import { getProfileDetails } from "../../Redux/action/Profile";
import { useDispatch, useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import ProfileModal from "./ProfileModal";
// import Header from "../header/Header";

export default function Dashboard() {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const options = useMemo(() => countryList().getData(), []);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const changeHandler = (value) => {
    setValue(value);
  };
  const dispatch = useDispatch();
  const data = [
    {
      color: "steelblue",
      points: [
        { x: 1, y: 2 },
        { x: 3, y: 5 },
        { x: 7, y: -3 },
      ],
    },
  ];

  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [userDetails?.length]);

  const handleAPI = () => {
    dispatch(getProfileDetails());
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);
  const transactionList = [
    {
      img: "./assets/img/incoming-call.png",
      name: "Manuel Doe Singh",
      rcv: "Received",
      date: "Today",
      amount: "+1,620.39 GBP",
      amount2: "2,000 USD",
    },

    {
      downimg: "/images/sendMoneyImg.png",
      name: "Manuel Doe Singh",
      rcv: "Received",
      date: "Today",
      downamount: "+1,620.39 GBP",
      amount2: "2,000 USD",
    },
    {
      img: "./assets/img/incoming-call.png",
      name: "Manuel Doe Singh",
      rcv: "Received",
      date: "Today",
      amount: "+1,620.39 GBP",
      amount2: "2,000 USD",
    },
  ];

  return (
    <>
      <Header />
      <div className="main-section m-5">
        <div className="container-fluid" id="headd">
          <div className="row">
            <div className="col-md-5">
              <div className="section-1 p-3">
                <div className="row">
                  <div className="col-md-12">
                    <p className="welcome-txt mb-0">
                      Welcome,
                      {userDetails?.firstName ? userDetails?.firstName : "John"}
                      !
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-6">
                    <p className="dollar-txt mb-0">
                      0.0 <span className="sub-txt">$</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-6">
                    <p className="add-section pt-2 mb-0">
                      <a href="" className="add-btn">
                        <img src="./assets/img/Add.png" />
                        <span className="flagImg">
                          <img
                            src={userDetails?.fromCountryDetail?.flagIconUrl}
                            alt="country flag"
                          />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-12">
                    <p className="welcome-txt">Balance</p>
                  </div>
                </div>
                <div className="inner-section-1">
                  <div className="row text-center m t-3">
                    <div
                      className="col-3 col-md-3"
                      onClick={() => navigate("/addmoney")}
                    >
                      <img src="./assets/img/inner-sec (1).png" />
                      <p>Add Money</p>
                    </div>
                    <div className="col-3 col-md-3">
                      <a href="/withdrawmoney">
                        <img src="./assets/img/inner-sec (2).png" />
                        Withdraw
                      </a>
                    </div>
                    <div
                      className="col-3 col-md-3 cursor-pointer"
                      onClick={() => navigate("/chat")}
                    >
                      <img src="./assets/img/inner-sec (3).png" />
                      <p>Chat</p>
                    </div>
                    <div
                      class="col-3 col-md-3 cursor-pointer"
                      onClick={() => navigate("/accounts")}
                    >
                      <img src="./assets/img/Frame 1000002995.png" />
                      <p>Accounts</p>
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-6 col-md-6">
                    <p className="transaction-txt">Transaction List</p>
                  </div>
                  <div className="col-6 col-md-6 text-right">
                    <a href="trasaction.html" className="see-more-cls">
                      See More
                    </a>
                  </div>
                </div>
                {transactionList.map((item, index) => (
                  <div className="row">
                    <div className="col-2 col-md-2">
                      {index % 2 === 0 ? (
                        <img src={item.img} alt="Image" />
                      ) : (
                        <img src={item.downimg} alt="Down Image" />
                      )}
                    </div>
                    <div className="col-5 col-md-5">
                      <p className="call-name mb-0">{item.name}</p>
                      <p className="call-desc">
                        {item.rcv}
                        <span>{item.date}</span>
                      </p>
                    </div>
                    <div className="col-5 col-md-5 text-right">
                      <p className="call-gbp mb-0">{item.amount}</p>
                      <p className="call-gbp-red mb-0">{item.downamount}</p>
                      <p className="call-desc">{item.amount2}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="section-2 p-3 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <p className="welcome-txt mb-0">Currency Graph</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-6">
                    <p className="dollar-txt mb-0">1 USD=446.87</p>
                  </div>
                  <div className="col-6 col-md-6">
                    <p className="add-section pt-2 mb-0">
                      <a href="" className="add-btn">
                        Send USD
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p className="today-txt">Today</p>
                  </div>
                </div>
                <div>
                  <div className="App">
                    <h1>My First LineChart</h1>
                    <LineChart width={600} height={400} data={data} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-3 p-3">
                <div className="row">
                  <div className="col-md-12">
                    <p className="welcome-txt mb-0">Account Limits</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <p className="dollar-txt mb-0 flagImg">
                      <img src={userDetails?.fromCountryDetail?.flagIconUrl} />{" "}
                      $0.00 left of
                      <span className="sub-txt"> 0.00 this week</span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p className="add-section pt-2 mb-0">
                      <a href="/account_limit" className="add-btn">
                        Learn more <img src="./assets/img/line (2).png" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="section-3 p-3 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <p className="welcome-txt mb-0">Exchange Rate</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <p className="dollar-txt mb-0 flagImg">
                      <img src={userDetails?.fromCountryDetail?.flagIconUrl} />
                      <span>
                        <img
                          className="sentImg"
                          src={userDetails?.sendToContries?.[0]?.flagIconUrl}
                        />
                      </span>{" "}
                      &nbsp;&nbsp; 1 USD=446.87
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p className="add-section pt-2 mb-0">
                      <a href="/exchange_rate" class="add-btn">
                        Learn more <img src="./assets/img/line (2).png" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="section-green p-3 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <img src="./assets/img/3D Salary Day.png" />
                  </div>
                  <div className="col-md-12 mt-3">
                    <p className="dollar-txt mb-0">Payment History</p>
                  </div>
                  <div className="col-md-12">
                    <p className="add-section pt-2 mb-0">
                      Lorem ipsum dolor sit amet consectetur. Felis enim massa
                      rutrum.
                    </p>
                  </div>
                </div>
              </div>
              <div className="section-green p-3 mt-4" id="Green-sectin">
                <div className="row">
                  <div className="col-md-12">
                    <img src="./assets/img/3D bank locker.png" />
                  </div>
                  <div className="col-md-12 mt-3">
                    <p className="dollar-txt mb-0">Pay Bills</p>
                  </div>
                  <div className="col-md-12">
                    <p className="add-section pt-2 mb-0">
                      Lorem ipsum dolor sit amet consectetur. Felis enim massa
                      rutrum.
                    </p>
                  </div>
                </div>
              </div>
              <div className="section-green p-3 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <img src="./assets/img/5.png" />
                  </div>
                  <div className="col-md-12 mt-3">
                    <p className="dollar-txt mb-0">Air Time Top Up</p>
                  </div>
                  <div className="col-md-12">
                    <p className="add-section pt-2 mb-0">
                      Lorem ipsum dolor sit amet consectetur. Felis enim massa
                      rutrum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="section-3 p-3">
                <div className="row">
                  <div className="col-md-12">
                    <p className="welcome-txt mb-0">Global Account</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <p className="dollar-txt mb-0">4 account available</p>
                  </div>
                </div>
                <div className="row pt-2" id="slider">
                  <div className="col-md-6 pl-0">
                    <div className="card">
                      <div className="row">
                        <div className="col-md-6">
                          <img src="./assets/img/Ellipse 962.png" />
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        >
                          <p className="txt-1">NGN</p>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-md-12">
                          <p className="txt-2">0.0</p>
                        </div>
                        <div className="col-md-12">
                          <p className="txt-3">Nigerian Naira</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pr-0">
                    <div className="card">
                      <div className="row">
                        <div className="col-md-6">
                          <img src="./assets/img/Ellipse 962.png" />
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        >
                          <p className="txt-1">NGN</p>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-md-12">
                          <p className="txt-2">0.0</p>
                        </div>
                        <div className="col-md-12">
                          <p className="txt-3">Nigerian Naira</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-3 p-3 mt-4">
                <div className="row">
                  <div className="col-6 col-md-6">
                    <p className="welcome-txt mb-0">Send Money to</p>
                  </div>
                  <div
                    className="col-6 col-md-6"
                    style={{ textAlign: "right" }}
                  >
                    <p className="dollar-txt-1 mb-0">See More</p>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 col-md-3">
                    <img src="./assets/img/add-account.png" />
                    <br />
                    <p className="add-new">Add New</p>
                  </div>
                  <div className="col-3 col-md-3">
                    <img src="./assets/img/1.png" />
                    <br />
                    <p className="add-new">Rosemary</p>
                  </div>
                  <div className="col-3 col-md-3">
                    <img src="./assets/img/1.png" />
                    <br />
                    <p className="add-new">Lasyerlad</p>
                  </div>
                  <div className="col-3 col-md-3">
                    <img src="./assets/img/1.png" />
                    <br />
                    <p className="add-new">Listychan</p>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-2 col-md-2"></div>
                  <div className="col-4 col-md-4">
                    <a href="">
                      <img src="./assets/img/send.png" className="img-fluid" />
                    </a>
                  </div>
                  <div className="col-4 col-md-4 mt-5 pt-3">
                    <a href="request-money">
                      <img
                        src="./assets/img/requestt.png"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="section-3 p-3 mt-4 pb-5"
                style={{ backgroundColor: "#283593", position: "relative" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <p className="welcome-txt mb-0" style={{ color: "#fff" }}>
                      Open Multi-Currency Accounts <br />
                      on Simplisend Finance
                    </p>
                  </div>
                  <div className="col-md-5 mt-3">
                    <a href="#" className="learn-more-btn">
                      Learn More
                    </a>
                  </div>
                  <div className="col-md-7 mt-3">
                    <div className="inner-section-flag">
                      <p>
                        {" "}
                        <span className="zar-flag">
                          <img src="./assets/img/flag-1.png" /> ZAR
                        </span>
                        <span className="zar-flag">
                          <img src="./assets/img/emojione_flag-for-nigeria.png" />{" "}
                          NGN
                        </span>
                      </p>
                      <p style={{ position: "relative", left: "-12px" }}>
                        {" "}
                        <span className="zar-flag">
                          <img src="./assets/img/emojione_flag-for-cameroon.png" />{" "}
                          CFA
                        </span>
                        <span className="zar-flag">
                          <img src="./assets/img/emojione_flag-for-canada.png" />{" "}
                          CAD
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
