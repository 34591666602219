import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { SocialSignupReg } from "../../Redux/action/Userregistration";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Signupaction } from "../../Redux/action/Userregistration";
import { Dispatch } from "react";

const ConfirmpinSave = (props) => {
  const [values, setValues] = useState(["", "", "", ""]);
  const [errorstate, Seterror] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state) => state?.user?.error);

  const user = useSelector(
    (state) => state?.user?.signup?.message?.successMessage
  );
  const signup = useSelector(
    (state) =>
      state?.Socialsignupreducer?.socialmedia?.data?.response?.message
        ?.successMessage
  );

  const confirmpin = (confirmpin) => {
    if (props.pin != confirmpin) {
      props.setconfirmpinPage(false);
      props.setvalue(["", "", "", ""]);
      props.Showerrormessage(true);
    } else if (
      props.pin === confirmpin &&
      (props.active === "email" || props.active === "")
    ) {
      dispatch(Signupaction(props.signUpdata));
      console.log(props.active);
      // alert("email is true")
    } else if (props.pin === confirmpin && props.active === "social") {
      dispatch(SocialSignupReg(props.signUpdata));
      console.log(props.active);

      console.log("social is true");
    } else {
      alert("missing something");
    }
  };

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);

      // Move focus to the next input field if the current field is filled
      if (value.length === 1 && index < 3) {
        document.getElementById(`input-${index + 1}`).focus();
      }

      // Move focus to the previous input field if the current field is emptied
      if (value.length === 0 && index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
      if (newValues.every((val) => val.length === 1)) {
        const queryParams = newValues.join("");
        confirmpin(queryParams);
      }
    }
  };
  const inputColors = ["##283593", "#27AE60", "#FFC634", "#283593"];
  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (signup) {
      toast.success(signup);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else if (user) {
      toast.success(user);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [error, signup, user]);
  return (
    <>
      <div className="content">
        <Toaster toastOptions={{ duration: 1000 }} />
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <p className="txt-1">
              Sign up information <span>6 of 6</span>
            </p>

            <p className="responsive">
              <img src="./dist/img/Line 3.png" />{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>
            </p>
            <h3 className="pt-5 pb-3">Confirm pin</h3>
            {/* {errorstate?<p style={{color:"red"}}>{error&& error || user && user || signup && signup}</p>:""} */}
            <p className="txt-3">
              Protect your account with transaction pin. Add an extra layer of
              security to <br />
              your account
            </p>

            <form className="mt-5 otpform" id="otpform">
              {values.map((value, index) => (
                <input
                  key={index}
                  className="otp"
                  type="password"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleChange(index, e.target.value)}
                  id={`input-${index}`}
                  style={{
                    color: inputColors[index],
                  }}
                  // style={Object.assign({}, inputStyle, index === 0 ? inputFocusedStyle : {})}
                  onFocus={() => index === 0 && setValues(["", "", "", ""])}
                />
              ))}

              {/* <input
                    className="otp"
                    type="password"
                    maxLength="1"
                    id="input5"
                    name="input5"
                    value={input5}
                    style={{
                      color: "#283593",
                      border:
                        "1.11768px solid rgba(22, 31, 76, 0.2)  !important",
                      border: "0px !important",
                    }}
                    placeholder="0"
                    onChange={(e) => onNextmove(e, setInput5)}
                  />
                  <input
                    className="otp"
                    type="password"
                    id="input6"
                    name="input6"
                    value={input6}
                    maxLength="1"
                    style={{ color: "#27AE60" }}
                    placeholder="0"
                    onChange={(e) => onNextmove(e, setInput6)}
                  />
                  <input
                    className="otp"
                    type="password"
                    maxLength="1"
                    style={{ color: "#FFC634" }}
                    placeholder="0"
                    id="input7"
                    name="input7"
                    value={input7}
                    onChange={(e) => onNextmove(e, setInput7)}
                  />
                  <input
                    className="otp"
                    type="password"
                    maxLength="1"
                    id="input8"
                    name="input8"
                    value={input8}
                    style={{ color: "#283593" }}
                    placeholder="0"
                    onChange={(e) => onNextmove(e, setInput8)}
                  /> */}
              {/* <button
                    style={{ marginTop: "30px" }}
                    type="submit"
                    className="btn btn-2 btn-block"
                  >
                    save
                  </button> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmpinSave;
