import Userreducer from "./Redux/reducer/Userreducer";
import ResetpassReducer from "./Redux/reducer/ResetpassReducer";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import SignupReducer from "./Redux/reducer/Signupreducer";
import ResetpassVerifyOtp from "./Redux/reducer/ResetpassVerifyOtp";
import ConfirmpassReducer from "./Redux/reducer/Confirmpassreducer";
import Socialsignupreducer from "./Redux/reducer/Socialsignupreducer";
import ProfileReducers from "./Redux/reducer/Profile";

const reducer = combineReducers({
  user: SignupReducer,
  login: Userreducer,
  reset: ResetpassReducer,
  ResetpassVerifyOtp,
  ConfirmpassReducer,
  Socialsignupreducer,
  ProfileReducers,
});

let initialState = {};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
