import API from "../../helpers/api/index";

export function bankDetails(params) {
    return (dispatch) => {
        dispatch({ type: "REQUEST_BANK_LIST" });
        API.apiGet("bankDetails")
            .then((response) => {
                if (response) {
                    dispatch({
                        type: `SET_BANK_LIST`,
                        payload: response?.bankName?.response,
                    });
                }
            })
            .catch((err) => {
                dispatch({ type: `SET_BANK_LIST`, payload: [] });
            });
    };
}
