import axios from "axios";

const HOST = "https://admin.simplisend.com/";

//https://admin.simplisend.com/v1/api/

const version = "v1/api/";

const API = HOST + version;

const instance = axios.create({
  baseURL: API,
});

export default instance;
