import React, { useEffect, useState } from "react";
import SendBird from "sendbird";

const APP_ID = "AC74B4DB-10DC-4F4B-90D0-FC9291D1D79B";
const sb = new SendBird({ appId: APP_ID });

function Example() {
  const [channelUrl, setChannelUrl] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    sb.connect("41", (user, error) => {
      if (error) {
        console.error("Error connecting to Sendbird:", error);
      } else {
        console.log("Connected to Sendbird:", user);

        const createGroupChannel = (userIds) => {
          const params = new sb.GroupChannelParams();
          params.addUserIds(userIds);

          sb.GroupChannel.createChannel(params, (groupChannel, error) => {
            if (error) {
              console.error("Error creating group channel:", error);
            } else {
              console.log("Group channel created successfully:", groupChannel);
              setChannelUrl(groupChannel.url);
            }
          });
        };

        // User IDs of the two users
        const user1 = "41"; // Replace with the actual User ID
        const user2 = "59"; // Replace with the actual User ID

        // Create a group channel between the two users
        createGroupChannel([user1, user2]);
      }
    });
  }, []);

  // Function to send a message
  const sendMessage = () => {
    if (channelUrl && message) {
      const params = new sb.UserMessageParams();
      params.message = message;

      sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) {
          console.error("Error retrieving group channel:", error);
        } else {
          groupChannel.sendUserMessage(params, (userMessage, error) => {
            if (error) {
              console.error("Error sending message:", error);
            } else {
              console.log("Message sent successfully:", userMessage);
            }
          });
        }
      });
    }
  };

  return (
    <div>
      <h2>Sendbird Chat</h2>
      <p>Channel URL: {channelUrl}</p>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={sendMessage}>Send Message</button>
    </div>
  );
}

export default Example;
