import React from 'react'
import { Fragment } from 'react'
import Metadecorator from '../../Metadecorator'
import Footr from '../Footer/Footr'
import Socialsection from '../socialsection/Socialsection'

import './Refer.css'

const Refer = () => {
  return (
  <>
     <Metadecorator title='refer a friend' description="refer a firend to get exciting prizes"/>
    <div className='refer-wrapper'>
        <p className='typo'>Refer a friend 30 USD for you and 10 USD for friends</p>

    </div>
    <div >
        <p className='info'>Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    </div >
    <div className='refer-button-wrapper'>
      <button className='refer-button'>Refer a friend now</button>
      <img src='./refer1.png' className='refer-img'/>
      <img src='./refer1.png' className='refer-img-1'/>
      <img src='./Vector2.png' className='vector2'/>
      {/* <img src='./Vector3.png'/> */}
      <img src='./Vector.png' className='vector'/>
    </div>
    <hr className='linemap'></hr>
    {/* <div className='img-first'>
    <img src="./Vector 109.png" alt='loading' className='img-first'/>
    </div> */}
   
    <p className='works'>How it works</p>
    <p className='works-text'>Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    <img src='./Ellipse 6164.png' className='ellipse-img'/>
    <img src='./Vector 109.png' className='vector109-img'/>
    <p className='refr-frnd'>Refer a friend</p>
    <p className='refr-frnd-text'> Velit officia consequat duis enim mollit. Exercitation consequat sunt nostrud amet.</p>
   
    
    <img src='./Ellipse 6164.png' className='ellipse-img-1'/>
    <img src='./Vector 109.png' className='vector109-img-1'/>
    <p className='refr-frnd-1'>get 10 usd</p>
    <p className='refr-frnd-text-1'> Velit officia consequat duis enim mollit. Exercitation consequat sunt nostrud amet.</p>
    <img src='./Ellipse 6164.png' className='ellipse-img-2'/>
    <img src='./Vector 109.png' className='vector109-img-2'/>
    <p className='refr-frnd-2'>you get $10</p>
    <p className='refr-frnd-text-2'> Velit officia consequat duis enim mollit. Exercitation consequat sunt nostrud amet.</p>
    
   <p className='client'>What our client says</p>
   <p className='client-text'>Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
   <div className='client-review-box'>
    <p className='one'>Ronald Richards</p>
    <p className='two'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. 
Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    
   </div>
   <div className='client-review-box-2'>
    <p className='one'>Floyd Miels</p>
    <p className='two'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. 
Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    
   </div>
   <div className='client-review-box-3'>
    <p className='one'>justin timber</p>
    <p className='two'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. 
Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    
   </div>
    <div className='socialcomp'>
    <Socialsection/>
    </div>
    <div className='foot'>
      <Footr/>
    </div>
  
    </>
  
  )
}

export default Refer