import { setAuthorization } from "./api";

export function login(token) {
  localStorage.setItem("accessToken", token);
  setAuthorization();
  return true;
}

export function logout() {
  localStorage.removeItem("accessToken");
  setAuthorization();
  window.location.href = `${window.location.origin}/`;
  return true;
}
