import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
// import { usePlaidLink } from 'react-plaid-link';
import API from "../../helpers/api/instance";
import * as authentication from "../../helpers/Auth";
import "./Accounts.css";
import { Toaster, toast } from "react-hot-toast";

const ConnectBank = () => {
  const [linkToken, setLinkToken] = useState("");
  const [plaidShow, setPlaidShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const storageToken = localStorage.getItem("accessToken");
    !storageToken && authentication.logout();
  }, []);

  const fetchLinkToken = async () => {
    setLoading(true);
    setTimeout(setLoading, 2000);
    try {
      const response = await API.get("/link/token/create/web");
      toast.success("Bank Connecting...", {
        position: "top-right",
        style: {
          borderBottom: "4px solid #33a34e",
          padding: "16px",
          color: "#3c5f4b",
          marginRight: "25px",
        },
      });
      const newLinkToken = response?.data?.response?.data?.link_token;
      setLinkToken(newLinkToken);
      localStorage.setItem("linkToken", newLinkToken);
      setPlaidShow(true);
      setLoading(false);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Failed to fetch link token:", error);
    }
  };
  // const handlePlaidEvent = (eventName, metadata) => {
  //     if (eventName === 'EXIT') {
  //         // Handle Plaid web view exit event
  //         // You can perform any necessary cleanup or actions here
  //         setPlaidShow(false);
  //     }
  // };

  // const { ready } = usePlaidLink({
  //     token: linkToken,
  //     onSuccess: (public_token, metadata) => {
  //         // send public_token to server
  //         setLinkToken('');
  //         localStorage.removeItem('linkToken');
  //     },
  //     onEvent: handlePlaidEvent,
  // });

  const handleClick = () => {
    fetchLinkToken();
    setLoading(true);
  };

  return (
    <>
      <div className="position-relative">
        <Container align="center">
          {!plaidShow && (
            <>
              <div className="conHeading mt-5">
                <h5>Connect your bank</h5>
                <p>
                  Please select from the below bank or either you can search
                  your bank.
                </p>
              </div>
              <div>
                <img
                  src="./images/connectBankImg.png"
                  className="img img-fluid"
                  alt="Bank"
                />
              </div>
              <Form>
                <div className="conCheckBox mb-3">
                  <Form.Check
                    type="checkbox"
                    id="1"
                    label="We use bank level encryption to protect your information"
                  />
                  <Form.Check
                    type="checkbox"
                    id="2"
                    label="We use bank level encryption to protect your information"
                  />
                </div>
              </Form>
              <div className="conBtn">
                <Button variant="success" onClick={handleClick}>
                  Continue
                </Button>
              </div>
              <div className="terms mt-4">
                <p>
                  By selecting “Continue”, you agree to{" "}
                  <span>terms & conditions</span>
                </p>
              </div>
            </>
          )}
          {isLoading && (
            <div className="loader-container">
              <div className="lds-roller">
                <img src="./images/logoLoader.gif" />
              </div>
            </div>
          )}

          {plaidShow && linkToken && (
            <div className="plaidContainer">
              <iframe
                title="Plaid Web View"
                id="myIframe"
                src={`https://cdn.plaid.com/link/v2/stable/link.html?token=${linkToken}`}
                width="100%"
                height="630px"
                className="plaidWeb"
                frameBorder="0"
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default ConnectBank;
