import { actiontype } from "../actiontype/Actiontype";

const inititalState={
    socialmedia:{

    }
}

const Socialsignupreducer = (state = inititalState, action) => {
    switch (action.type) {
      case actiontype.SOCIAL_SIGNUP_SUCCESS:
        return {
          ...state,
          socialmedia: action.payload,
        };
  
      case actiontype.SOCIAL_SIGNUP_FAIL:
        return {
          ...state,
          socialmedia: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default Socialsignupreducer;
  