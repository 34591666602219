import React, { useEffect } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { AiOutlineBell, AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import "./Accounts.css";
import { Link } from "react-plaid-link";
import { useNavigate } from "react-router-dom";
import { bankDetails } from "../../Redux/action/BankList";
import { useDispatch, useSelector } from "react-redux";

const Accounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = [
    {
      accType: "Savings Account",
      accNo: "************1234",
    },
    {
      accType: "Savings Account",
      accNo: "************1234",
    },
  ];

  const [bankLists] = useSelector((Gstate) => [
    Gstate?.BankReducers?.bankLists,
  ]);

  useEffect(() => {
    dispatch(bankDetails());
  }, [bankLists?.length]);

  const handleAPI = () => {
    dispatch(bankDetails());
  };
  useEffect(() => {
    localStorage.removeItem("linkToken");
  }, []);
  return (
    <>
      <div className="mainBox mt-4">
        <Container>
          <div className="buttonBox d-flex align-items-center">
            <AiOutlineBell style={{ color: "#161F4C", fontSize: "30px" }} />
            <Form.Select aria-label="Default select example">
              <option>Manage payment Methods</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
          <hr />
          <div className="headingBox d-flex justify-content-between pt-4">
            <h3 onClick={handleAPI}>Bank account Connected (2)</h3>
            <p onClick={() => navigate("/connect-bank")}>
              <AiOutlinePlus />
              Add new account
            </p>
          </div>
          {data.map((item) => (
            <>
              <div className="rowBox p-3 mb-4">
                <Row className="d-flex align-items-center">
                  <Col lg={1} md={1} sm={1} xs={2}>
                    <img
                      className="img img-fluid"
                      src="./images/citiBankImg.png"
                    />
                  </Col>
                  <Col lg={9} md={5} sm={5} xs={5}>
                    <h2>{item.accType}</h2>
                    <p>{item.accNo}</p>
                  </Col>
                  <Col lg={2} md={6} sm={6} xs={5}>
                    <div className="d-flex justify-content-evenly">
                      <Button variant="success" className="editBtn">
                        Edit
                      </Button>
                      <div className="deleteBox">
                        <AiOutlineDelete />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ))}
          <div className="headingBox d-flex justify-content-between pt-4">
            <h3>Cards (2)</h3>
            <p>
              <AiOutlinePlus />
              Add new card{" "}
            </p>
          </div>
          {data.map((item) => (
            <>
              <div className="rowBox p-3 mb-4">
                <Row className="d-flex align-items-center">
                  <Col lg={1} md={1} sm={1} xs={2}>
                    <img
                      className="img img-fluid"
                      src="./images/citiBankImg.png"
                    />
                  </Col>
                  <Col lg={9} md={5} sm={5} xs={5}>
                    <h2>{item.accType}</h2>
                    <p>{item.accNo}</p>
                  </Col>
                  <Col lg={2} md={6} sm={6} xs={5}>
                    <div className="d-flex justify-content-evenly">
                      <Button className="editBtn">Edit</Button>
                      <div className="deleteBox">
                        <AiOutlineDelete />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ))}
        </Container>
      </div>
    </>
  );
};

export default Accounts;
