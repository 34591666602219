import React from 'react'
import Metadecorator from '../../Metadecorator'
import { Fragment } from 'react'
const Howitworks = () => {
  return (
    <Fragment>
    <Metadecorator title='want to know how it works' description="follow these steps"/>
       <h1>Howitworks</h1>
       </Fragment>
  )
}
export default Howitworks