import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useEffect } from 'react';
import '../Dashboard/dashboard.css';
import { Link } from "react-router-dom";
import Header from '../Dashboard/Header';

export default function Accountdetails() {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const changeHandler = value => {
        setValue(value)
    }
    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, []);
    return (
        <>
            <Header />
            <section className="add-money">
                <div className="main-section m-5">
                    <div className="container-fluid" id="headd">
                        <div className="bg-1">
                            <img src="./assets/img/cross.png" class="img-fluid" />
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-3">
                                <img src="./assets/img/1 (2).png" className="img-fluid" />
                            </div>
                            <div className="col-8 col-md-6">
                                <div className="inner-section-2 px-1">
                                    <h3 className="add-txt">Sending and receiving amount for USD</h3>
                                    <p className="balance pt-3">Here are your sending and receiving amounts limits.</p>
                                </div>
                                <form id="add-money-form">
                                    <div className="form-group mb-4">
                                        <label for="exampleInputEmail1" id="add-account">Receiving Limit</label>
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                <Link to="/account_detail">
                                                    <label for="radio1" className="selector-item_label">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <p className="today-1">Today</p>
                                                            </div>
                                                            <div className="col-12 col-md-12">
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", backgroundColor: "#27AE60", borderRadius: "8px" }}>
                                                                        <span className="sr-only">70% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mt-3">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p className="today-2">1000.00 USD <br /> <span>Spent</span></p>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        <p className="today-2">400.00 USD <br /> <span>Left</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label for="exampleInputEmail1" id="add-account">Sending Limit</label>
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                <Link to="/account_detail">
                                                    <label for="radio1" className="selector-item_label">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <p className="today-1">Today</p>
                                                            </div>
                                                            <div className="col-12 col-md-12">
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", backgroundColor: "#27AE60", borderRadius: "8px" }}>
                                                                        <span className="sr-only">70% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mt-3">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p className="today-2">1000.00 USD <br /> <span>Spent</span></p>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        <p className="today-2">400.00 USD <br /> <span>Left</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                <Link to="/account_detail">
                                                    <label for="radio1" className="selector-item_label">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <p className="today-1">Today</p>
                                                            </div>
                                                            <div className="col-12 col-md-12">
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", backgroundColor: "#27AE60", borderRadius: "8px" }}>
                                                                        <span className="sr-only">70% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mt-3">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p className="today-2">1000.00 USD <br /> <span>Spent</span></p>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        <p className="today-2">400.00 USD <br /> <span>Left</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                <Link to="/account_detail">
                                                    <label for="radio1" className="selector-item_label">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <p className="today-1">Today</p>
                                                            </div>
                                                            <div className="col-12 col-md-12">
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", backgroundColor: "#27AE60", borderRadius: "8px" }}>
                                                                        <span className="sr-only">70% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mt-3">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p className="today-2">1000.00 USD <br /> <span>Spent</span></p>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        <p className="today-2">400.00 USD <br /> <span>Left</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
