import React from 'react'
import './socialsection.css'
const Socialsection = () => {
  return (
    <div className="socialsection">
        <p className="download">Download the simplisend app</p>
       <p className="download-link">Get the link to download the app</p>
       <input type="number" placeholder="102020020202" className="number"/>
        <p className="download-text">Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
        <button className="send-sms">Send sms</button>
        <select className="country-code"></select>
        <img src='./apple.png' className="apple"/>
        <img src="./google.png" className="google"/>
        <img src="./Rectangle 2.png" className="socialimage"/>
        <img src="./Chatbox .png" className="chatbox" alt="hllo"/>
        <img src="./Chatbox 1.png" className="chatbox-1"/>
        <img src="./chatscreen.png" className="chatbox-2"/>
      </div>
  )
}

export default Socialsection