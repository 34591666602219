import React, { useState } from "react";
import Header from "../Dashboard/Header";
import {
  Container,
  Form,
  Accordion,
  FormCheck,
  FormControl,
  Button,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import "./Profile.css";
import { AiOutlineGift, AiOutlineSend, AiOutlineCopy } from "react-icons/ai";

function Referral() {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const [shareModal, setShareModal] = useState(false);

  const handleShare = () => {
    setShareModal(true);
  };

  return (
    <>
      <Header />
      <Container>
        <div className="profileHeadingBox my-5">
          <h5>Referral & Credits</h5>
        </div>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="settingSelect">
              {" "}
              <AiOutlineGift className="headingIcon" />
              Gift cards
            </Accordion.Header>
            <Accordion.Body>
              <div className="my-4 p-4">
                <Row>
                  <Col lg={3} md={3}>
                    <img
                      src="./images/giftCard1.png"
                      className="img img-fluid"
                    />
                  </Col>
                  <Col lg={3} md={3}>
                    <img
                      src="./images/giftCard2.png"
                      className="img img-fluid"
                    />
                  </Col>
                  <Col lg={3} md={3}>
                    <img
                      src="./images/giftCard3.png"
                      className="img img-fluid"
                    />
                  </Col>
                  <Col lg={3} md={3}>
                    <img
                      src="./images/giftCard4.png"
                      className="img img-fluid"
                    />
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="settingSelect">
              <AiOutlineSend className="headingIcon" /> Refer a Friend
            </Accordion.Header>
            <Accordion.Body>
              <div className="referralTypo">
                <p>We’re all set</p>
                <h2>
                  Refer a friend <br />
                  30 USD for you and 10 USD for friends{" "}
                </h2>
                <h6>
                  Minim mollit non deserunt ullamco est sit aliqua dolor do amet
                  sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </h6>
              </div>
              <div className="shareBtn1 d-flex justify-content-between align-items-center my-4">
                <div>
                  <p>SSREQPAYMENT123@#SS</p>{" "}
                </div>
                <div className="d-flex align-items-center">
                  <h6>
                    Copy{" "}
                    <AiOutlineCopy
                      style={{ color: "#161F4C", marginRight: "30px" }}
                    />
                  </h6>

                  <Button variant="success" onClick={handleShare}>
                    Share
                  </Button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {shareModal && (
          <>
            <div>
              <Modal className="shareModal" show={shareModal}>
                <div>hellow</div>
              </Modal>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Referral;
