import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useEffect } from 'react';
import '../Dashboard/dashboard.css';
import { Link } from "react-router-dom";
import Header from '../Dashboard/Header';

export default function Accountlimit() {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const changeHandler = value => {
        setValue(value)
    }
    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, []);
    return (
        <>
            <Header />
            <section className="add-money">
                <div className="main-section m-5">
                    <div className="container-fluid" id="headd">
                        <div className="bg-1">
                            <img src="./assets/img/cross.png" class="img-fluid" />
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-3">
                                <img src="./assets/img/1 (4).png" className="img-fluid" />
                            </div>
                            <div className="col-8 col-md-6">
                                <div className="inner-section-2 px-1">
                                    <h3 className="add-txt">Account limits</h3>
                                    <p className="balance pt-3">Choose an account to see send and receive limit</p>
                                </div>
                                <form id="add-money-form">
                                    <div className="form-group mb-4">
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                    <Link to="/account_detail">
                                                        <label for="radio1" className="selector-item_label">
                                                            <div className="row">
                                                                <div className="col-5 col-md-2">
                                                                    <img src="./assets/img/1 (1).png" className="img-fluid" />
                                                                </div>
                                                                <div className="col-7 col-md-7">
                                                                    <p className="account-info mb-0 mt-2" style={{color: "#283593",}}>US Dollar</p>
                                                                </div>
                                                                <div className="col-12 col-md-3 text-right"> <p className="amount mt-2"><img src="./assets/img/arrow.png" className="img-fluid" /></p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                    <Link to="/account_detail">
                                                        <label for="radio1" className="selector-item_label">
                                                            <div className="row">
                                                                <div className="col-5 col-md-2">
                                                                    <img src="./assets/img/1 (1).png" className="img-fluid" />
                                                                </div>
                                                                <div className="col-7 col-md-7">
                                                                    <p className="account-info mb-0 mt-2" style={{color: "#283593",}}>Nigerian naira</p>
                                                                </div>
                                                                <div className="col-12 col-md-3 text-right"> <p className="amount mt-2"><img src="./assets/img/arrow.png" className="img-fluid" /></p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
