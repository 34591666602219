import React, { useState } from "react";
import Header from "../Dashboard/Header";
import { Form, Row, Col, Button, FormControl } from "react-bootstrap";
import RequestSend from "./RequestSend";
import { useNavigate } from "react-router-dom";
import { AiOutlineCopy } from "react-icons/ai";
function RequestContact() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [old, setOld] = useState(true);
  const [enter, setEnter] = useState(false);
  const [heading, setHeading] = useState(true);
  const [roadMap, setRoadMap] = useState(true);
  const [roadMap1, setRoadMap1] = useState(false);
  const [step3, setStep3] = useState(false);
  const [requestsend, setRequestSend] = useState(false);
  const [customLink, setCustomLink] = useState(false);
  const [shareit, setShareIt] = useState(false);
  const [roadMap2, setRoadMap2] = useState(false);
  const [roadMap3, setRoadMap3] = useState(false);
  const [roadMapfinal, setRoadMapFinal] = useState(false);

  const handleShow = () => {
    setShow(true);
    setOld(false);
  };
  const handleEnter = () => {
    setShow(false);
    setHeading(false);
    setEnter(true);
    setRoadMap(false);
    setRoadMap1(true);
  };
  const hanldeStep3 = () => {
    setStep3(true);
    setEnter(false);
    setRoadMap2(true);
    setRoadMap1(false);
  };
  const handleSend = () => {
    setStep3(false);
    setRequestSend(true);
    setRoadMap2(false);
  };
  const handleLink = () => {
    setStep3(false);
    setCustomLink(true);
    setRoadMap2(false);
    setRoadMap3(true);
  };
  const handleShare = () => {
    setCustomLink(false);
    setShareIt(true);
    setRoadMap3(false);
    setRoadMapFinal(true);
  };
  return (
    <>
      <div className="mt-5">
        <Row>
          <Col lg={2} md={2}>
            {roadMap ? (
              <>
                <div>
                  <img src="./images/roadMap.png" className="img img-fluid" />
                </div>
              </>
            ) : roadMap1 ? (
              <>
                <div>
                  <img src="./images/roadMap1.png" className="img img-fluid" />
                </div>
              </>
            ) : roadMap2 ? (
              <>
                <div>
                  <img src="./images/roadMap2.png" className="img img-fluid" />
                </div>
              </>
            ) : roadMap3 ? (
              <>
                <div>
                  <img src="./images/roadMap3.png" className="img img-fluid" />
                </div>
              </>
            ) : roadMapfinal ? (
              <>
                <div>
                  <img
                    src="./images/roadmapFinal.png"
                    className="img img-fluid"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
          <Col lg={10} md={10}>
            {heading && (
              <>
                <div className="requestBox">
                  <h6>From whom are you requesting money?</h6>
                  <p>
                    Please select the contact or search the contact by their
                    name, email or phone to request money.
                  </p>
                </div>
              </>
            )}

            {old ? (
              <>
                <div className="searchBox">
                  <Form>
                    <Form.Control
                      type="text"
                      placeholder="Name, email, phone"
                    />
                  </Form>
                </div>
                <div className="classH5 py-3">
                  <h5>Your Contact</h5>
                </div>
                <div className="contactBox d-flex p-4">
                  <div>
                    <img
                      src="./images/contactImg.png"
                      className="img img-fluid me-4"
                    />
                    <span>
                      <img src="./images/flagImg.png" />
                    </span>
                  </div>
                  <div align="left" onClick={handleShow}>
                    <h6>Wade Warren</h6>
                    <p>GBP account ending in 9044</p>
                  </div>
                </div>
                <div className="contactListBox"></div>
              </>
            ) : show ? (
              <>
                <div className="classH5 py-3">
                  <h5>Requesting money from</h5>
                </div>
                <div className="contactBox d-flex justify-content-between p-4">
                  <div className="d-flex">
                    <div>
                      <img
                        src="./images/contactImg.png"
                        className="img img-fluid me-4"
                      />
                      <span>
                        <img src="./images/flagImg.png" />
                      </span>
                      <div className="changeBtn mt-5">
                        <h6>Change</h6>
                      </div>
                    </div>
                    <div align="left">
                      <h6>Wade Warren</h6>
                      <p>GBP account ending in 9044</p>
                    </div>
                  </div>
                  <div>
                    <h5>VISA</h5>
                    <p>0332 3222 4536</p>
                  </div>
                </div>
                <div className="getPaidBox mt-5">
                  <Button onClick={handleEnter} variant="success">
                    Continue
                  </Button>
                </div>
              </>
            ) : enter ? (
              <>
                <div className="contactBox d-flex justify-content-between p-4">
                  <div className="d-flex">
                    <div>
                      <img
                        src="./images/contactImg.png"
                        className="img img-fluid me-4"
                      />
                      <span>
                        <img src="./images/flagImg.png" />
                      </span>
                    </div>
                    <div align="left">
                      <h6>Wade Warren</h6>
                      <p>GBP account ending in 9044</p>
                    </div>
                  </div>
                  <div>
                    <h5>VISA</h5>
                    <p>0332 3222 4536</p>
                  </div>
                </div>
                <div className="enterAmtBox mt-4">
                  <div className="enterTypo">
                    <h6>Enter Amount</h6>
                    <h4>$ 500</h4>
                  </div>
                  <div className="enterTypo text-left mt-4">
                    <h5>Category </h5>
                    <div className="tagBox text-center mt-3">
                      <p>Movie</p>
                    </div>
                  </div>
                  <div className="enterTextField mt-5">
                    <FormControl
                      type="text"
                      placeholder="Write a note........"
                    />
                  </div>
                </div>
                <div className="getPaidBox mt-5">
                  <Button onClick={hanldeStep3} variant="success">
                    Continue
                  </Button>
                </div>
              </>
            ) : step3 ? (
              <>
                <div className="classH5 py-3">
                  <h5>Requesting money from</h5>
                </div>
                <div className="contactBox d-flex justify-content-between p-4">
                  <div className="d-flex">
                    <div>
                      <img
                        src="./images/contactImg.png"
                        className="img img-fluid me-4"
                      />
                      <span>
                        <img src="./images/flagImg.png" />
                      </span>
                      <div className="changeBtn mt-5">
                        <h6>Change</h6>
                      </div>
                    </div>
                    <div align="left">
                      <h6>Wade Warren</h6>
                      <p>GBP account ending in 9044</p>
                    </div>
                  </div>
                  <div>
                    <h5>VISA</h5>
                    <p>0332 3222 4536</p>
                  </div>
                </div>
                <div className="step3Box d-flex justify-content-between mt-4">
                  <div>
                    <p>Fee</p>
                    <p>Recipient</p>
                    <p>Email</p>
                    <p>Phone Number</p>
                  </div>
                  <div>
                    <h6>0.05% fee capped at 5.00 GBP</h6>
                    <h6>Satish Ray</h6>
                    <h6>satishray@gmail.com</h6>
                    <h6>0332 3222 4536</h6>
                  </div>
                </div>
                <div className="getPaidBox mt-5">
                  <Button variant="success" onClick={handleSend}>
                    Send request
                  </Button>
                </div>
                <div className="customLinkBtn mt-4 pb-5">
                  <Button variant="link" onClick={handleLink}>
                    {" "}
                    Create Custom link
                  </Button>
                </div>
              </>
            ) : customLink ? (
              <>
                <div className="requestBox">
                  <h6>Share your link</h6>
                  <p>
                    Your payment link for $123.56 has been created. Share your
                    link to start receiving payment.
                  </p>
                </div>
                <div className="shareBtn d-flex justify-content-between align-items-center">
                  <div>
                    <p>SSREQPAYMENT123@#SS</p>{" "}
                  </div>
                  <div>
                    <h6>
                      Copy <AiOutlineCopy style={{ color: "#161F4C" }} />
                    </h6>
                  </div>
                  <div>
                    <Button variant="success" onClick={handleShare}>
                      Share
                    </Button>
                  </div>
                </div>
                <div className="step3Box d-flex justify-content-between mt-4">
                  <div>
                    <p>Fee</p>
                    <p>Recipient</p>
                    <p>Email</p>
                    <p>Phone Number</p>
                  </div>
                  <div>
                    <h6>0.05% fee capped at 5.00 GBP</h6>
                    <h6>Satish Ray</h6>
                    <h6>satishray@gmail.com</h6>
                    <h6>0332 3222 4536</h6>
                  </div>
                </div>
                <div className="getPaidBox mt-5">
                  <Button
                    variant="success"
                    onClick={() => navigate("/dashboard")}
                  >
                    Go to dashboard
                  </Button>
                </div>
              </>
            ) : shareit ? (
              <>
                <div className="requestBox">
                  <h6>
                    Your request is ready - how would you like to share it?
                  </h6>
                  <p className="mt-4">
                    This link is valid until Oct 26, 2023. You can cancel this
                    request from your account later. Some of your details will
                    be shared through this link. <span>Learn more</span>
                  </p>
                  <div className="shareTypo mt-5">
                    <h6>Copy or share your request link:</h6>
                    <p>
                      Copy link <span>SIMayskjkjkddfjsj23578</span>{" "}
                      <AiOutlineCopy style={{ color: "#161F4C" }} />
                    </p>
                  </div>
                </div>
                <div className="getPaidBox mt-5">
                  <Button
                    variant="success"
                    onClick={() => navigate("/dashboard")}
                  >
                    Share it
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      {requestsend && <RequestSend />}
    </>
  );
}

export default RequestContact;
