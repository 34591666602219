const INITIAL_STATE = {
  userDetails: [],
  loading: false,
};

const ProfileReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "REQUEST_PROFILE_LIST":
      return {
        ...state,
        loading: true,
      };
    case "SET_PROFILE_LIST":
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };
    default:
      return state;
  }
};

export default ProfileReducers;
