import { Container, ModalBody } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "../../Redux/action/Profile";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdNavigateNext } from "react-icons/md";
import { AiOutlineSetting, AiOutlineCustomerService } from "react-icons/ai";
import { BsDiamondHalf } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function ProfileModal({ show, handleClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = [
    {
      icon: <AiOutlineSetting />,
      name: "Account Settings",
      path: "/settings",
    },
    {
      icon: <BsDiamondHalf />,
      name: "Referral & Credits",
      path: "/referral&credit",
    },
    {
      icon: <AiOutlineCustomerService />,
      name: "Support",
      path: "/support",
    },
    {
      icon: <TiDocumentText />,
      name: "Legal",
      path: "/legal",
    },
  ];

  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [userDetails?.length]);

  const handleAPI = () => {
    dispatch(getProfileDetails());
  };

  const handleLinkClick = (path) => {
    navigate(path);
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/");
  };

  return (
    <>
      <div className="profileModalCSS">
        <Modal show={show} onHide={handleClose}>
          <Container className="mt-4">
            <div
              className="d-flex justify-content-between classH6"
              onClick={() => navigate("/myprofile")}
            >
              <div className="d-flex">
                {userDetails?.profileImage ? (
                  <img
                    src={userDetails?.profileImage}
                    className="img img-fluid me-2 profileMenuImg"
                  />
                ) : (
                  <span className="noImg me-2">
                    {userDetails?.firstName?.[0]}
                  </span>
                )}
                <div>
                  <h6>
                    {userDetails?.firstName} {userDetails?.lastName}
                  </h6>
                  <p>{userDetails?.email}</p>
                </div>
              </div>
              <div align="end">
                <MdNavigateNext style={{ color: "#000" }} />
              </div>
            </div>
            <hr style={{ margin: "0" }} />
          </Container>
          <Container className="mt-4">
            {profileData.map((item) => (
              <div
                className="d-flex justify-content-between classH6 mb-3"
                onClick={() => handleLinkClick(item.path)}
              >
                <div className="d-flex ">
                  <div className="text-black me-2 classIcon">{item.icon}</div>
                  <div>
                    <h6>{item.name}</h6>
                  </div>
                </div>
                <div align="end">
                  <MdNavigateNext style={{ color: "#000" }} />
                </div>
              </div>
            ))}
          </Container>
          <Container>
            <div
              className="d-flex justify-content-between classH6"
              onClick={handleLogout}
            >
              <div className="d-flex">
                <div className="text-red me-2 classIcon">
                  <AiOutlinePoweroff />
                </div>

                <div>
                  <h5>Logout</h5>
                </div>
              </div>
            </div>
          </Container>
        </Modal>
      </div>
    </>
  );
}

export default ProfileModal;
