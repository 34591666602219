import React, { Fragment, useEffect, useState } from "react";
import "./setuppin.css";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import ConfirmpinSave from "./ConfirmpinSave";

const Setuppin = (props) => {
  const [values, setValues] = useState(["", "", "", ""]);

  const [pin, setpin] = useState("");
  const [showconfirmpinPage, setconfirmpinPage] = useState(false);
  const [errormessage, Showerrormessage] = useState(false);

  const getPinValue = (newValues) => {
    setconfirmpinPage(true);
    const allvalues = newValues.join("");
    setValues(allvalues);
    setpin(allvalues);
    props.getallDataforSingup({
      pin: allvalues,
    });
  };

  const handleChange = (index, value) => {
    Showerrormessage(false);
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);

      // Move focus to the next input field if the current field is filled
      if (value.length === 1 && index < 3) {
        document.getElementById(`input-${index + 1}`).focus();
      }

      // Move focus to the previous input field if the current field is emptied
      if (value.length === 0 && index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
      if (newValues.every((val) => val.length === 1)) {
        getPinValue(newValues);
      }
    }
  };

  const inputColors = ["##283593", "#27AE60", "#FFC634", "#283593"];

  return (
    <Fragment>
      {!showconfirmpinPage ? (
        <>
          {" "}
          <div className="content">
            <Toaster toastOptions={{ duration: 2000 }} />
            <div className="header">
              <img src="./dist/img/image 15.png" className="img-fluid" />
            </div>
            <div className="circle-border"></div>
            <div className="circle">
              <img src="./dist/img/Ellipse 5.png" />
              <div className="new-2">
                <img src="./dist/img/Mask group.png" />
              </div>
            </div>
          </div>
          <div className="container my-5">
            <div className="cross-btn">
              <Link to="/">
                <img src="./dist/img/Vector (14).png" />
              </Link>
            </div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 py-5">
                <p className="txt-1">
                  Sign up information <span>6 of 6</span>
                </p>
                <p className="responsive">
                  <img src="./dist/img/Line 3.png" />{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>
                </p>
                <h3 className="pt-5 pb-3">Setup a pin</h3>
                <p style={{ color: "red" }}>
                  {errormessage ? "pin doesn't match" : ""}
                </p>
                <p className="txt-3">
                  Protect your account with transaction pin. Add an extra layer
                  of security to <br />
                  your account
                </p>
                <form onSubmit={getPinValue} className="mt-5 " id="otpform">
                  {values.map((value, index) => (
                    <input
                      key={index}
                      className="otp"
                      type="password"
                      maxLength={1}
                      value={value}
                      onChange={(e) => handleChange(index, e.target.value)}
                      id={`input-${index}`}
                      style={{
                        color: inputColors[index],
                        border:
                          "1.11768px solid rgba(22, 31, 76, 0.2)  !important",
                        border: "0px !important",
                      }}
                      onFocus={() => index === 0 && setValues(["", "", "", ""])}
                    />
                  ))}
                  {/* <input
                    className="otp"
                    type="number"
                    maxLength="1"
                    id="input1"
                    name="input1"
                    value={input1}
                    style={{
                      color: "#283593",
                      border:
                        "1.11768px solid rgba(22, 31, 76, 0.2)  !important",
                      border: "0px !important",
                    }}
                    placeholder="0"
                    onChange={(e) => moveToNext(e, setInput1)}
                  /> */}

                  {/* <button
                style={{ marginTop: "30px" }}
                type="submit"
                className="btn btn-2 btn-block"
              >
                Next
              </button> */}
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ConfirmpinSave
            pin={pin}
            active={props.active}
            signUpdata={props.signUpdata}
            setconfirmpinPage={setconfirmpinPage}
            setvalue={setValues}
            Showerrormessage={Showerrormessage}
          />
        </>
      )}
    </Fragment>
  );
};

export default Setuppin;
