import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useEffect } from 'react';
import '../Dashboard/dashboard.css';

export default function Addmoney() {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const changeHandler = value => {
        setValue(value)
    }
    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, []);
    return (
        <>
            <nav className="navbar navbar-expand-lg px-5">
                <a className="navbar-brand" href="/dashboard"><img src="./dist/img/logo.png " /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto pl-5">
                        <li className="nav-item active px-3">
                            <a className="nav-link" href="/dashboard">Home</a>
                        </li>
                        <li className="nav-item mx-4 px-3">
                            <a className="nav-link" href="/transactions">Transaction</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className="nav-link" href="#">Recipient</a>
                        </li>
                    </ul>
                    <div className="right-side">
                        <div className="langage">
                            {/* <select className="vodiapicker">
                <option value="en" className="test" data-thumbnail="https://cdn.shopify.com/s/files/1/0491/2689/9880/files/aste.png?v=1611693503">EN</option>
                <option value="au" data-thumbnail="https://cdn.shopify.com/s/files/1/0403/1584/6819/files/ch-flag.png?v=1591397127">US</option>
                <option value="uk" data-thumbnail="https://cdn.shopify.com/s/files/1/0491/2689/9880/files/france.png?v=1611686479">IN</option>

              </select>
              <div className="lang-select">
                <button className="btn-select" value=""></button>
                <div className="b">
                  <ul id="a"></ul>
                </div>
              </div> */}
                            <Select
                                options={countries}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#283593'
                                    },
                                })}
                                value={selectedCountry}
                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                            />
                        </div>
                        <div className="profile-btn">
                            <a href=""><img src="./dist/img/profile.png" /></a>
                        </div>
                    </div>
                </div>
            </nav>
            <section className="add-money">
                <div className="main-section m-5">
                    <div className="container-fluid">
                        <div className="bg-1">
                            <img src="./dist/img/cross.png" class="img-fluid" />
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-4">
                                <img src="./dist/img/progress (2).png" className="img-fluid" />
                            </div>
                            <div className="col-8 col-md-8">
                                <div className="inner-section-2">
                                <h3 className="add-txt">Choose how do you want to send <br />1000 USD</h3>
                                <p className="balance pt-3">Please select the default payment method or you can either go with <br />another payment method as well.</p>
                                </div>
                                <form id="add-money-form">
                                    <div className="form-group mb-4">
                                        <label for="exampleInputEmail1" id="add-account">Bank account <span className="inner-span"><a href="#"><img src="./dist/img/Vector (24).png" /> Add New Account</a></span></label>
                                        <div className="selector pb-2" id="selected-section">
                                            <label>Selected payment method</label>
                                            <hr className="mt-0" />
                                                <div className="selecotr-item">
                                                    <input type="radio" id="radio1" name="selector" className="selector-item_radio" />
                                                        <label for="radio1" className="selector-item_label">
                                                            <div className="row">
                                                                <div className="col-12 col-md-2">
                                                                    <img src="./dist/img/BOA.png" className="img-fluid" />
                                                                </div>
                                                                <div className="col-7 col-md-7">
                                                                    <p className="account-info">Bank of America  <br />
                                                                        <span>************1234</span></p>
                                                                </div>
                                                                <div className="col-3 col-md-3" style={{textAlign: 'right',}}> <img src="./dist/img/check.png" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </label>
                                                </div>
                                                <a href="/addmoney_paymentmethod" className="method-cls"> Change Payment Method</a>
                                        </div>
                                    </div>
                                    <a href="/addmoney_enterpin" className="send-btn btn-block">Continue</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
