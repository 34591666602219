import React, { useState, useEffect } from "react";
import Header from "../Dashboard/Header";
import {
  Container,
  Accordion,
  FormCheck,
  FormControl,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import "./Profile.css";
import {
  AiOutlineSetting,
  AiOutlineWallet,
  AiOutlineSchedule,
  AiOutlineBell,
  AiOutlineLock,
  AiOutlineScan,
  AiOutlineUser,
  AiOutlineSend,
  AiOutlineCalendar,
} from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import {
  MdOutlinePrivacyTip,
  MdOutlinePayments,
  MdOutlineDashboardCustomize,
} from "react-icons/md";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import API from "../../helpers/api/instance";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TiHeartFullOutline } from "react-icons/ti";
import { Toaster, toast } from "react-hot-toast";

function AccountSetting() {
  // const [checked, setChecked] = useState(false);
  const [showR, setShowR] = useState(false);
  const [upperCase, setUppercase] = useState(false);
  const [number, SetNumber] = useState(false);
  const [character, setCharacter] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  // useEffect(() => {
  //   API.get("/update/password")
  //     .then((response) => {
  //       setCurrentPassword(response?.error?.errorMessage);
  //       console.log("getres",response)
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  const formInitialSchema = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    uppercase: false,
    lowercase: false,
    specialCharacter: false,
    minLength: false,
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Current Password is required."),
    newPassword: Yup.string()
      .required("New Password is required.")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[!@#$%^&*]).{8,}$/,
        "New Password must contain at least one uppercase letter, one lowercase letter, one special character, and be at least 8 characters long."
      )
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New Password must be different from the Current Password."
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required.")
      .oneOf([Yup.ref("newPassword")], "Password must match the New Password."),
  });
  const handleSubmit = (values) => {
    // e.preventDefault();

    const { oldPassword, newPassword } = values;
    const postData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    // if (currentPassword !== oldPassword) {
    //   console.error("Incorrect current password");
    //   return;
    // }
    API.post("/update/password", postData)
      .then((response) => {
        if (response.data) {
          toast.success(response.data, {
            position: "top-right",
            style: {
              borderBottom: "4px solid #33a34e",
              padding: "16px",
              color: "#3c5f4b",
              marginRight: "25px",
            },
          });
        } else {
          toast.error("Please enter valid Username or Password");
        }
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleShowR = () => {
    setShowR((prevShowR) => !prevShowR);
  };

  const notifyData = [
    {
      heading: "Common",
      typo: "General Notification",
    },
    {
      typo: "Cards transactions",
    },
    {
      heading1: "System & services update",
      typo: "App updates",
    },
    {
      typo: "Bill Reminder",
    },
    {
      typo: "Lorem ipsum",
    },
    {
      typo: "Discount ",
    },
    {
      typo: "Payment Request",
    },
    {
      heading1: "Others",
    },
    {
      typo: "New Service Available",
    },
    {
      typo: "New Tips Available",
    },
  ];

  const handleNewPasswordChange = (event, setFieldValue) => {
    const newPassword = event.target.value;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasLowercase = /[0-9]/.test(newPassword);
    const hasSpecialCharacter = /[!@#$%^&*]/.test(newPassword);
    const isMinLength = newPassword.length >= 8;

    setFieldValue("uppercase", hasUppercase);
    setFieldValue("lowercase", hasLowercase);
    setFieldValue("specialCharacter", hasSpecialCharacter);
    setFieldValue("minLength", isMinLength);
  };

  return (
    <>
      <Header />
      <div>
        <Container>
          <div className="profileHeadingBox my-5">
            <h5>Account Settings</h5>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="settingSelect">
                {" "}
                <AiOutlineSetting className="headingIcon" />
                Manage payment Methods
              </Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="settingSelect">
                {" "}
                <AiOutlineWallet className="headingIcon" /> Receive Funds
                settings
              </Accordion.Header>
              <Accordion.Body>
                <div className="classH5 mt-3">
                  <h5>Choose how people can find and pay you on Simplisend</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6>Simplisend.me Link</h6>
                      <p>
                        Allow other to find me at simplisend.me/@andrew.ainsley
                      </p>
                    </div>
                    <FormCheck
                      type="switch"
                      id="custom-switch"
                      // checked={checked}
                      // onChange={handleChange}
                    />
                  </div>
                  <h6>Phone Number</h6>
                  <p>Allow other to find me with +9217348732 </p>
                  <h6>Email Address</h6>
                  <p>Allow other to find me with andrew09@gmail.com</p>{" "}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="settingSelect">
                <BiUserCircle className="headingIcon" />
                Privacy & Security
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="innerAccordion">
                      <AiOutlineLock
                        style={{
                          color: "#161F4C",
                          fontSize: "20px",
                          marginRight: "15px",
                        }}
                      />
                      Change Password
                    </Accordion.Header>
                    <Formik
                      initialValues={formInitialSchema}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        errors,
                        values,
                        handleBlur,
                        touched,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form>
                          <Accordion.Body>
                            <div className="changePwdBox">
                              <div className="position-relative innerDiv">
                                <span className="span1">
                                  <AiOutlineLock />
                                </span>
                                <span
                                  className="span2"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <RiEyeLine />
                                  ) : (
                                    <RiEyeCloseLine />
                                  )}
                                </span>
                                <FormControl
                                  name="oldPassword"
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Current Password"
                                  className="mt-3"
                                  value={values.oldPassword}
                                  error={Boolean(
                                    touched.oldPassword && errors.oldPassword
                                  )}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <ErrorMessage
                                name="oldPassword"
                                component="div"
                                className="errorText"
                              />

                              <div className="position-relative innerDiv">
                                <span className="span1">
                                  <AiOutlineLock />
                                </span>
                                <span
                                  className="span2"
                                  onClick={() => setNewPassword(!newPassword)}
                                >
                                  {newPassword ? (
                                    <RiEyeLine />
                                  ) : (
                                    <RiEyeCloseLine />
                                  )}
                                </span>
                                <FormControl
                                  name="newPassword"
                                  type={newPassword ? "text" : "password"}
                                  placeholder="New Password"
                                  className="my-3"
                                  value={values.newPassword}
                                  error={Boolean(
                                    touched.newPassword && errors.newPassword
                                  )}
                                  onBlur={handleBlur}
                                  onChange={(event) => {
                                    handleChange(event);
                                    handleNewPasswordChange(
                                      event,
                                      setFieldValue
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="newPassword"
                                  component="div"
                                  className="errorText"
                                />
                              </div>
                              <div className="position-relative innerDiv">
                                <span className="span1">
                                  <AiOutlineLock />
                                </span>
                                <span
                                  className="span2"
                                  onClick={() =>
                                    setConfirmPassword(!confirmPassword)
                                  }
                                >
                                  {confirmPassword ? (
                                    <RiEyeLine />
                                  ) : (
                                    <RiEyeCloseLine />
                                  )}
                                </span>
                                <FormControl
                                  type={confirmPassword ? "text" : "password"}
                                  placeholder="Confirm Password"
                                  className="my-3"
                                  name="confirmPassword"
                                  value={values.confirmPassword}
                                  error={Boolean(
                                    touched.confirmPassword &&
                                      errors.confirmPassword
                                  )}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="confirmPassword"
                                  component="div"
                                  className="errorText"
                                />
                              </div>
                              <div className="d-flex pl-5 checkboxCircle">
                                <FormCheck
                                  name="uppercase"
                                  type="checkbox"
                                  checked={values.uppercase}
                                  className="me-2"
                                  style={{ borderRadius: "50% !important" }}
                                />
                                <p
                                  style={{
                                    color: values.uppercase
                                      ? "#161F4C"
                                      : "#737994",
                                  }}
                                >
                                  At least one upper case
                                </p>
                              </div>
                              <div className="d-flex pl-5 checkboxCircle">
                                <FormCheck
                                  type="checkbox"
                                  className="me-2"
                                  name="lowercase"
                                  checked={values.lowercase}
                                />
                                <p
                                  style={{
                                    color: values.lowercase
                                      ? "#161F4C"
                                      : "#737994",
                                  }}
                                >
                                  At least one number
                                </p>
                              </div>

                              <div className="d-flex pl-5 checkboxCircle">
                                <FormCheck
                                  type="checkbox"
                                  name="specialCharacter"
                                  className="me-2"
                                  checked={values.specialCharacter}
                                />
                                <p
                                  style={{
                                    color: values.specialCharacter
                                      ? "#161F4C"
                                      : "#737994",
                                  }}
                                >
                                  At least one special character(e.g $%&)
                                </p>
                              </div>
                              <div className="d-flex pl-5 checkboxCircle">
                                <FormCheck
                                  type="checkbox"
                                  name="minLength"
                                  className="me-2"
                                  checked={values.minLength}
                                />
                                <p
                                  style={{
                                    color: values.minLength
                                      ? "#161F4C"
                                      : "#737994",
                                  }}
                                >
                                  At least 8 Characters
                                </p>
                              </div>
                              <Button type="submit" variant="success">
                                Save
                              </Button>
                            </div>
                          </Accordion.Body>
                        </Form>
                      )}
                    </Formik>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header className="innerAccordion">
                      <MdOutlineDashboardCustomize
                        style={{
                          color: "#161F4C",
                          fontSize: "20px",
                          marginRight: "15px",
                        }}
                      />
                      Transaction PIN
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="classH5 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h6>Simplisend.me Link</h6>
                            <p>
                              Allow other to find me at
                              simplisend.me/@andrew.ainsley
                            </p>
                          </div>
                          <FormCheck
                            type="switch"
                            id="custom-switch"
                            // checked={checked}
                            // onChange={handleChange}
                          />
                        </div>
                        <h6>Phone Number</h6>
                        <p>Allow other to find me with +9217348732 </p>
                        <h6>Email Address</h6>
                        <p>
                          Allow other to find me with andrew09@gmail.com
                        </p>{" "}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="privacyBox">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      <AiOutlineScan
                        style={{ color: "#161f4c", fontSize: "20px" }}
                      />
                      <p>
                        Allow other to find me at simplisend.me/@andrew.ainsley
                      </p>
                    </div>
                    <FormCheck
                      type="switch"
                      id="custom-switch"
                      // checked={checked}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      <AiOutlineLock
                        style={{ color: "#161f4c", fontSize: "20px" }}
                      />
                      <div>
                        <p>Auto Lock Security</p>
                        <h6>Require Face ID after 10 minutes of inactivity</h6>
                      </div>
                    </div>
                    <FormCheck
                      type="switch"
                      id="custom-switch"
                      // checked={checked}
                      // onChange={handleChange}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="settingSelect">
                <AiOutlineCalendar className="headingIcon" /> Scheduled
                Transfers
              </Accordion.Header>
              <Accordion.Body>
                <Tabs
                  className="d-flex justify-content-around scheduleBox"
                  defaultActiveKey="first"
                >
                  <Tab eventKey="first" title="Scheduled transfers">
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="tab1Box p-4 mt-4">
                          <div className="d-flex justify-flex-start">
                            <div>
                              <img
                                src="./images/timeIcon.png"
                                className="img img-fluid me-3"
                              />
                            </div>
                            <div>
                              <h6>07:20</h6>
                              <p>Scheduled time</p>
                            </div>
                          </div>
                          <div className="d-flex justify-flex-start">
                            <div>
                              <img
                                src="./images/calenderIcon.png"
                                className="img img-fluid me-3"
                              />
                            </div>
                            <div>
                              <h6>04 January 2023</h6>
                              <p>Scheduled time</p>
                            </div>
                          </div>
                          <div className="d-flex justify-flex-start">
                            <div>
                              <img
                                src="./images/calenderIcon.png"
                                className="img img-fluid me-3"
                              />
                            </div>
                            <div>
                              <h6>Yara Khalil</h6>
                              <p>Scheduled time</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="tab1Box p-4 mt-4">
                          <div className="d-flex justify-flex-start">
                            <div>
                              <img
                                src="./images/timeIcon.png"
                                className="img img-fluid me-3"
                              />
                            </div>
                            <div>
                              <h6>07:20</h6>
                              <p>Scheduled time</p>
                            </div>
                          </div>
                          <div className="d-flex justify-flex-start">
                            <div>
                              <img
                                src="./images/calenderIcon.png"
                                className="img img-fluid me-3"
                              />
                            </div>
                            <div>
                              <h6>04 January 2023</h6>
                              <p>Scheduled time</p>
                            </div>
                          </div>
                          <div className="d-flex justify-flex-start">
                            <div>
                              <img
                                src="./images/calenderIcon.png"
                                className="img img-fluid me-3"
                              />
                            </div>
                            <div>
                              <h6>Yara Khalil</h6>
                              <p>Scheduled time</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab>

                  <Tab eventKey="second" title="Cancelled"></Tab>
                </Tabs>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="settingSelect">
                <MdOutlinePrivacyTip className="headingIcon" />
                Account Limits
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Header className="innerAccordion">
                    How much money I can withdraw from wallet?
                  </Accordion.Header>
                  <Accordion.Item eventKey="9"></Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Header className="innerAccordion">
                    How many transaction I can make in one day?
                  </Accordion.Header>
                  <Accordion.Item eventKey="9"></Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Header className="innerAccordion">
                    Can I have more than one account ?
                  </Accordion.Header>
                  <Accordion.Item eventKey="9"></Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" onClick={handleShowR}>
              <Accordion.Header className="settingSelect">
                <AiOutlineBell className="headingIcon" />
                Notifications
                {showR && (
                  <div className="notifyHead d-flex">
                    <FormCheck aria-label="option 1" label="Push" />
                    <FormCheck
                      aria-label="option 2"
                      className="ml-5"
                      label="Email"
                    />
                  </div>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <div className="notificationBox p-4">
                  {notifyData.map((data) => (
                    <>
                      <h6>{data.heading}</h6>
                      <h6 className="mt-3 mb-5">{data.heading1}</h6>
                      <Row>
                        <Col lg={9} md={9} sm={9} xs={8}>
                          <h5>{data.typo}</h5>
                        </Col>
                        <Col lg={2} md={9} sm={9} xs={2}>
                          <FormCheck aria-label="option 1" />
                        </Col>
                        <Col lg={1} md={9} sm={9} xs={2}>
                          <FormCheck aria-label="option 2" />
                        </Col>
                      </Row>
                    </>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header className="settingSelect">
                <MdOutlinePayments className="headingIcon" />
                Requested Payments
              </Accordion.Header>
              <Accordion.Body>
                <Tabs
                  className="d-flex justify-content-around scheduleBox"
                  defaultActiveKey="first"
                >
                  <Tab eventKey="first" title="My request ">
                    <div className="requestPaymentBox my-4 d-flex justify-content-between">
                      <h6>16 September, 2020 (4)</h6>
                      <h5>Send all payment</h5>
                    </div>
                    <div className="myrequestBox p-3 my-4 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src="./images/requestImg.png"
                          className="img img-fluid me-4"
                        />
                        <div className="myrequestTypo">
                          <h6>Mayank Tyagi</h6>
                          <p>$10.50</p>
                        </div>
                      </div>
                      <div>
                        <Button variant="success">
                          <AiOutlineSend
                            style={{
                              transform: "rotate(270deg)",
                              marginBottom: "5px",
                            }}
                          />{" "}
                          Send
                        </Button>
                      </div>
                    </div>
                    <div className="myrequestBox p-3 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src="./images/requestImg.png"
                          className="img img-fluid me-4"
                        />
                        <div className="myrequestTypo">
                          <h6>Mayank Tyagi</h6>
                          <p>$10.50</p>
                        </div>
                      </div>
                      <div>
                        <Button variant="success">
                          <AiOutlineSend
                            style={{
                              transform: "rotate(270deg)",
                              marginBottom: "5px",
                            }}
                          />{" "}
                          Send
                        </Button>
                      </div>
                    </div>
                    <div className="myrequestBox p-3 my-4 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src="./images/requestImg.png"
                          className="img img-fluid me-4"
                        />
                        <div className="myrequestTypo">
                          <h6>Mayank Tyagi</h6>
                          <p>$10.50</p>
                        </div>
                      </div>
                      <div>
                        <Button variant="success">
                          <AiOutlineSend
                            style={{
                              transform: "rotate(270deg)",
                              marginBottom: "5px",
                            }}
                          />{" "}
                          Send
                        </Button>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="second" title="Pending requests"></Tab>
                  <Tab eventKey="third" title="Cancelled"></Tab>
                </Tabs>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </>
  );
}

export default AccountSetting;
