import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import './verify.css'
const SignupName = (props) => {
  
  const [fname, setfname] = useState("");
  const [lname, setLname] = useState("");
  const [disable, setdisable] = useState(true);
  const navigate = useNavigate();
  const getFullName = (e) => {
    e.preventDefault();
    console.log(fname, lname);

     props.getallDataforSingup({fname,lname})
     navigate('/signup_dob')
  };
  const getfName = (e) => {
    const { value } = e.target;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      setfname(value);
    }
  };
  const getLname = (e) => {
    const { value } = e.target;

    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      if (value.length >= 1) {
        setdisable(false);
      }
      if (value.length == 0) {
        setdisable(true);
      }
      setLname(value);
    }
  };
  return (
    <Fragment>
      <div className="content">
        <Toaster toastOptions={{ duration: 2000 }} />
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" >
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <p className="txt-1">
              Sign up information <span>3 of 6</span>
            </p>
            <p className="responsive">
              <img src="./dist/img/Line 3.png" />{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
            </p>
            <h3 className="pt-5 pb-3" id="name-cls">Enter your full name</h3>
            <p className="txt-3">
              Please enter your name or nickname or tell us how would you liked
              to
              <br /> be called in the app.
            </p>
            <form onSubmit={getFullName}>
              <div className="form-group pt-5">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail5"
                  aria-describedby="emailHelp"
                  placeholder="First Name"
                  style={{ background: "url('./dist/img/line.png')" }}
                  onChange={getfName}
                  value={fname}
                />
              </div>
              <div className="form-group pt-2 pb-5">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail6"
                  aria-describedby="emailHelp"
                  placeholder="Last Name"
                  style={{ background: "url('./dist/img/line.png')" }}
                  onChange={getLname}
                  value={lname}
                />
              </div>
              <button
                type="submit"
                disabled={disable}
                className= {!disable ?"btn btn-2 btn-block":"btn btn-2 btn-block disable-button"}
                style={!disable ? {paddingTop:"10px"}:{opacity:"0.2" ,paddingTop:"10px"}}
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignupName;
