// import React, { Fragment, useEffect, useState } from "react";
// import "./verify.css";
// import { Link, useNavigate } from "react-router-dom";
// import toast from "react-hot-toast"
// import { Toaster } from "react-hot-toast";
//  import { RecaptchaVerifier } from "firebase/auth";
// import { auth } from "../../firebase";
// import { signInWithPhoneNumber } from "firebase/auth";

// const Verify = (props) => {
//   // console.log("active",props.Settext)

//   const navigate=useNavigate()
//   const [inputValues, setInputValues] = useState(Array(6).fill(""));
//   const [showemail,setemail]=useState("")
//   const [number,setnumber]=useState("")
//   // console.log(props.signup.email)

//   function onOTPVerify() {

//     window.confirmationResult
//       .confirm(concatenatedValues)
//       .then(async (res) => {
//         alert("otp verified");
//         navigate("/singup_name");
//       })
//       .catch((err) => {
//         alert(err);
//       });

//   }
//   const Emailverify=async()=>{
//     console.log(inputValues)
//     const values= inputValues.join("");
//     const otptobeVerified=+(values)

//     try {
//        const email=props.signup.email

//       const otp=await fetch("http://18.168.8.82/v1/api/emailverifyotp",{
//       method:"POST",

//       body:JSON.stringify({email,otp:otptobeVerified}),
//       headers:{
//         "content-type":"application/json"
//       }
//     })
//     const result=await otp.json()
//     console.log(result)
//     if(result.error){
//       toast.error(result?.error?.errorMessage)
//     }
//     else{
//       setTimeout(() => {
//         toast.success(result?.response?.message?.successMessage)
//        }, 1000);

//     props.getallDataforSingup({email})

//       navigate("/singup_name")

//     }
//     } catch (error) {
//       console.log(error)
//     }

//     }
//     const onCaptchVerify = () => {
//       if (!window.recaptchaVerifier) {
//         window.recaptchaVerifier = new RecaptchaVerifier(
//           "recaptcha-container",
//           {
//             size: "invisible",
//             callback: (response) => {
//               ResendotpMobile();
//             },
//             "expired-callback": () => {},
//           },
//           auth
//         );
//       }
//     };

//     const ResendotpMobile = () => {

//       let Formatphone = "";

//         Formatphone = `+${props.defaultCountry.phoneCode}` + props.signup.mobileno;
//         console.log(Formatphone)
//       onCaptchVerify();

//         const appVerifier = window.recaptchaVerifier;
//         signInWithPhoneNumber(auth, Formatphone, appVerifier)
//           .then((confirmationResult) => {
//             window.confirmationResult = confirmationResult;
//           toast.success("OTP sended successfully!");

//           })
//           .catch((error) => {
//             console.log(error)
//             toast.error(error.code)

//           });
//       }

//   const handleChange = (index, value) => {

//     const newInputValues = [...inputValues];
//     newInputValues[index] = value;
//     setInputValues(newInputValues);
//     if (value.length === 1 && index < inputValues.length - 1) {
//       const nextInput = document.querySelectorAll(".otp")[index + 1];
//       nextInput.focus();
//     nextInput.style.outlineColor="green"

//     }

//   };
//   const getOtpAgain=async()=>{
//     const email=props.signup.email
//     try {
//       const otp=await fetch("http://18.168.8.82/v1/api/tempreg",{
//       method:"POST",

//       body:JSON.stringify({email}),
//       headers:{
//         "content-type":"application/json"
//       }
//     })
//     const result=await otp.json()
//     console.log(result.error)
//     if(result.error){
//       toast.error(result?.error?.errorMessage)
//     }
//     else{
//       toast.success(result?.response?.message?.successMessage)
//     }

//    } catch (error) {
//       console.log(error)
//     }

//   }
//   const concatenatedValues = inputValues.join("");
//   useEffect(()=>{
//     if(props.active === ""){
//       const maskNumber = (numberValue) => {
//         if (numberValue.length > 8) {
//           const maskedChars = "****";
//           const lastChars = numberValue.slice(-4);
//           return setnumber(maskedChars + lastChars);
//         }
//         return numberValue;
//       };
//       maskNumber(props.signup.mobileno)
//     }
//     else if(props.active === "email"){
//      const maskEmail = (emailValue) => {
//       const atPosition = emailValue.indexOf("@");
//       if (atPosition > 2) {
//         let maskedChars = emailValue.slice(0, 2);
//         for (let i = 2; i < atPosition; i++) {
//           maskedChars += "*";
//         }
//         maskedChars += emailValue.slice(atPosition);

//         return  setemail( maskedChars);

//       }
//       return emailValue;
//     };
//     maskEmail(props.signup.email)
//     }

//   },[])

//   return (
//     <Fragment>
//       <div className="content">

//       <Toaster toastOptions={{ duration: 2000 }}/>

//         <div className="header">

//           <img src="./dist/img/image 15.png" className="img-fluid" />
//         </div>
//         <div className="circle-border"></div>
//         <div className="circle">
//           <img src="./dist/img/Ellipse 5.png" />
//           <div className="new-2">
//             <img src="./dist/img/Mask group.png" />
//           </div>
//         </div>
//       </div>
//       <div className="container my-5" id="sign-up">
//         <div className="cross-btn">
//           <Link to="/">
//             <img src="./dist/img/Vector (14).png" />
//           </Link>
//         </div>
//         <div className="row">
//           <div className="col-md-2"></div>
//           <div className="col-md-7 py-5">
//             <p className="txt-1">
//               Sign up information <span>2 of 6</span>
//             </p>
//             <p>
//               <img src="./dist/img/Line 3.png" />{" "}
//               <span className="span-1">
//                 <img src="./dist/img/Line 3.png" />
//               </span>
//               <span className="span-1">
//                 <img src="./dist/img/Line 4.png" />
//               </span>
//               <span className="span-1">
//                 <img src="./dist/img/Line 4.png" />
//               </span>
//               <span className="span-1">
//                 <img src="./dist/img/Line 4.png" />
//               </span>
//               <span className="span-1">
//                 <img src="./dist/img/Line 4.png" />
//               </span>
//             </p>
//             <h3 className="pt-5 pb-3">Is it really you?</h3>
//             <p className="txt-3">
//               {props.Settext === "email"
//                 ? "Please enter the verification code send to your email address"
//                 : `${props.text}`}{" "}
//               <br />
//               <span>
//                 {props.Settext === "email" ? showemail && showemail : number && number}
//               </span>
//             </p>
//             <form action="" className="mt-5" id="otpform">
//               {inputValues.map((value, index) => (
//                 <input
//                   key={index}
//                   className="otp input"
//                   type="text"
//                   maxLength="1"
//                   value={value}

//                   onChange={(e) => handleChange(index, e.target.value)}
//                 />
//               ))}
//                  <div id="recaptcha-container"></div>
//               <p className="txt-2 py-4 text-center">
//                 I didn’t receive code? <span onClick={props.active==="email" ?getOtpAgain:ResendotpMobile} style={{cursor:"pointer"}}> Resend Code</span>
//               </p>
//               <a
//                 type="button"
//                 className="btn btn-2 btn-block"
//               onClick={(props.active === "" || props.active ==="social") ? onOTPVerify :Emailverify}
//                 // onClick={()=>navigate("/singup_name")}
//               >
//                 Next
//               </a>
//             </form>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default Verify;
import React, { Fragment, useEffect, useState } from "react";
import "./verify.css";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../firebase";
import { signInWithPhoneNumber } from "firebase/auth";
import { useRef } from "react";
import { signOut } from "firebase/auth";

const Verify = (props) => {
  const mobilenumber = localStorage.getItem("mobile");
  const email = localStorage.getItem("email");
  console.log("code", props.signup.phoneNumber);
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);

  const inputRefs = useRef([]);
  const [otpsent, Setotpsent] = useState(false);
  const [showemail, setemail] = useState("");
  const [number, setnumber] = useState("");

  function onOTPVerify() {
    window.confirmationResult
      .confirm(concatenatedValues)
      .then(async (res) => {
        console.log(res);
        alert("otp verified");
        navigate("/singup_name");
      })
      .catch((err) => {
        alert(err.code);
      });
  }
  const Emailverify = async () => {
    const values = inputValues.join("");
    const otptobeVerified = +values;

    try {
      const email = props.signup.email;

      const otp = await fetch(
        "https://admin.simplisend.com/v1/api/emailverifyotp",
        {
          method: "POST",

          body: JSON.stringify({ email, otp: otptobeVerified }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const result = await otp.json();

      if (result.error) {
        toast.error(result?.error?.errorMessage);
      } else {
        setTimeout(() => {
          toast.success(result?.response?.message?.successMessage);
        }, 1000);

        props.getallDataforSingup({ email });

        navigate("/singup_name");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCaptchVerify = () => {
    if (window.recaptchaVerifier) {
      
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            ResendotpMobile();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }

    // window.recaptchaVerifier.render().then((widgetId) => {
    //   window.grecaptcha.reset(widgetId);
    // });
  };
  
  const ResendotpMobile = () => {
    let Formatphone = "";
    if (props.signup.phoneNumber == "" || null || undefined) {
      Formatphone =
        `+${props.defaultCountry.phoneCode}` + props.signup.mobileno;
    } else {
      Formatphone = props.signup.phoneNumber + props.signup.mobileno;
    }

    console.log(Formatphone);
    onCaptchVerify();
    

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, Formatphone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP sended successfully!");
        Setotpsent(true);
      })
      .catch((error) => {
        // toast.error(error.code)
        console.log(error);
        if (error.code || undefined) {
          {
            window.recaptchaVerifier.render().then((widgetId) => {
              window.grecaptcha.reset(widgetId);
            });
          }
        }
      });
  };
  const handlepaste = (event) => {
    const pastedData = event.clipboardData.getData("text");
    const newInputValues = [...inputValues];
    for (let i = 0; i < 6; i++) {
      if (pastedData[i]) {
        newInputValues[i] = pastedData[i];
      }
    }
    setInputValues(newInputValues);
  };

  const handleChange = (index, e) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = e.target.value;
    setInputValues(newInputValues);
    const nextIndex = index + 1;
    if (
      nextIndex < inputValues.length &&
      e.target.value.length === e.target.maxLength
    ) {
      inputRefs.current[nextIndex].focus();
    }
  };
  const getOtpAgain = async () => {
    const email = props.signup.email;
    try {
      const otp = await fetch(
        "https://admin.simplisend.com/v1/api/tempreg",
        {
          method: "POST",

          body: JSON.stringify({ email }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const result = await otp.json();
      console.log(result.error);
      if (result.error) {
        toast.error(result?.error?.errorMessage);
      } else {
        toast.success(result?.response?.message?.successMessage);
        Setotpsent(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isFormValid = inputValues.every((value) => value);
  const concatenatedValues = inputValues.join("");
  useEffect(() => {
  
    if (props.active === "") {
      const maskNumber = (numberValue) => {
        if (numberValue.length > 8) {
          const maskedChars = "****";
          const lastChars = numberValue.slice(-4);
          localStorage.setItem("mobile", maskedChars + lastChars);
          return setnumber(maskedChars + lastChars);
        }
        return numberValue;
      };
      maskNumber(props.signup.mobileno);
    } else if (props.active === "email") {
      const maskEmail = (emailValue) => {
        const atPosition = emailValue.indexOf("@");
        if (atPosition > 2) {
          let maskedChars = emailValue.slice(0, 2);
          for (let i = 2; i < atPosition; i++) {
            maskedChars += "*";
          }
          maskedChars += emailValue.slice(atPosition);
          localStorage.setItem("email", maskedChars);
          return setemail(maskedChars);
        }
        return emailValue;
      };
      maskEmail(props.signup.email);
    }
  }, [localStorage,window]);

  const handleKeyDown = (index, e) => {
    if (e.keyCode === 8 && !inputValues[index]) {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        inputRefs.current[prevIndex].focus();
      }
    }
  };
  return (
    <Fragment>
      <div className="content">
        <Toaster toastOptions={{ duration: 2000 }} />

        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <p className="txt-1">
              Sign up information <span>2 of 6</span>
            </p>
            <p className="responsive">
              <img src="./dist/img/Line 3.png" />{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
            </p>
            <h3 className="pt-5 pb-3">Is it really you?</h3>
            <p className="txt-3">
              {props.Settext === "email"
                ? "Please enter the verification code send to your email address"
                : `${props.text}`}{" "}
              <br />
              <span style={{ color: "rgba(39, 174, 96, 1)" }}>
                {props.Settext === "email"
                  ? email && email
                  : mobilenumber && mobilenumber}
              </span>
            </p>
            <form className="mt-5" id="otporm">
              {inputValues.map((value, index) => (
                <input
                  key={`input${index}`}
                  name={`input${index}`}
                  value={value}
                  maxLength={1}
                  // className="otp input"
                  type="text"
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onChange={(e) => handleChange(index, e)}
                  ref={(input) => (inputRefs.current[index] = input)}
                  onPaste={handlepaste}
                  className={`otp input ${
                    value ? "input-field has-value" : "input-field"
                  }`}
                  style={{
                    width: "60px",
                    margin: "0 5px",
                    border: value
                      ? "2px solid #27AE60"
                      : "1.5px solid rgba(22, 31, 76, 0.2)",
                    // border:"2px"
                  }}
                />
              ))}
              <div id="recaptcha-container"></div>
              <p className="txt-2 py-4 text-center">
                I didn’t receive code?{" "}
                <span
                  onClick={
                    props.active === "email" ? getOtpAgain : ResendotpMobile
                  }
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Resend Code
                </span>
              </p>
              <a
                type="button"
                className={
                  isFormValid
                    ? "btn btn-2 btn-block"
                    : "btn btn-2 btn-blck disable-button"
                }
                disabled={!isFormValid}
                style={{ backgroundColor: isFormValid && "#27ae60" }}
                onClick={
                  props.active === "" || props.active === "social"
                    ? onOTPVerify
                    : Emailverify
                }
                //  onClick={()=>console.log(inputValues)}
              >
                Next
              </a>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Verify;
