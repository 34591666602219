import React, { Fragment, useEffect, useState, CSSProperties } from "react";
import "./SignupMobile.css";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { ClipLoader, PulseLoader } from "react-spinners";
import loader from "../Loading.json";
import Lottie from "lottie-react";
import { async } from "@firebase/util";

const SignupMobile = (props) => {
  const navigate = useNavigate();
  let [color, setColor] = useState("#ffffff");
  const [loading, setLoading] = useState(false);
  const [isloaded, setisloaded] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    text: "",
    flag: "",
    code: "",
    countryId: "",
  });
  const [input, setInput] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [textx, Settext] = useState("");
  const [showSearchInput, setShowsearchInput] = useState(false);
  const [query, setQuery] = useState("");
  const [countryListdrop, setcountryListDrop] = useState();
  const handleSelectBoxClick = () => {
    setShowOptions(!showOptions);
    setShowsearchInput(!showSearchInput);
    setQuery("");
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /[0-9]/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const Handler = (e) => {
    setInput(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOption({
      ...selectedOption,
      text: option.countryName,
      flag: option.flagIconUrl,
      code: option.phoneCode,
      countryId: option.id,
    });
    setShowOptions(false);
    setShow(true);

    setPhoneNumber(`+${option.phoneCode}`);
  };
  const TextView = () => {
    props.Settext("");
    props.setActive("email");
  };
  const onCaptchVerify = async () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.recaptcha.reset(window.recaptchaWidgetId);
    } else {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // sign in with phone number
          },
        },
        auth
      );
      window.recaptchaWidgetId = await window.recaptchaVerifier.render();
    }

    //   if (!window.recaptchaVerifier) {
    //     window.recaptchaVerifier = new RecaptchaVerifier(
    //       "recaptcha-container",
    //       {
    //         size: "invisible",
    //         callback: (response) => {
    //           onSignup();
    //         },
    //         "expired-callback": () => {},
    //       },
    //       auth
    //     );
    //   }

    //  if(window.recaptchaVerifier){
    //   window.recaptchaVerifier = new RecaptchaVerifier(
    //     "recaptcha-container",
    //     {
    //       size: "invisible",
    //       callback: (response) => {
    //         onSignup();
    //       },
    //       "expired-callback": () => {},
    //     },
    //     auth
    //   );
    //  }

    //   window.recaptchaVerifier.render().then((widgetId) => {
    //             window.grecaptcha.reset(widgetId);
    //           });
    // if(window.recaptchaVerifier){
    //   window.recaptchaVerifier = new RecaptchaVerifier(
    //     "recaptcha-container",
    //     {
    //       size: "invisible",
    //       callback: (response) => {
    //         onSignup();
    //       },
    //       "expired-callback": () => {

    //       },
    //     },
    //     auth
    //   );
    // }
  };

  const onSignup = async (e) => {
    setLoading(true);
    e.preventDefault();
    let Formatphone = "";

    try {
      const response = await fetch(
        "https://admin.simplisend.com/v1/api/account/existence/check",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobileNo: input,
          }),
        }
      );
      const result = await response.json();

      if (result.error?.statusCode == 422) {
        toast.error("please enter your mobile number");
        setLoading(false);
      } else if (result.response?.isExists) {
        toast.error("user already exits");
        setLoading(false);
      } else if (!result.response?.isExists) {
        if (phoneNumber === "" || null || undefined) {
          Formatphone = `+${props.defaultCountry.phoneCode}` + input;
        } else {
          Formatphone = phoneNumber + input;
        }

        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, Formatphone, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;

            const fromCountryId =
              selectedOption?.countryId || props.defaultCountry?.id;
            const mobileno = input && input;
            props.getallDataforSingup({ fromCountryId, mobileno, phoneNumber });

            toast.success("OTP sended successfully!");
            setLoading(false);
            navigate("/signup_verify");
          })
          .catch((error) => {
            toast.error(error.code);
            console.log(error);
            if (error.code || error) {
              {
                // window.recaptchaVerifier.render().then((widgetId) => {
                //   window.grecaptcha.reset(widgetId);
                // });
              }
            }
            setLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }

    // if (input === "") {
    //   toast.error("mobile number is neccessary");
    //   setLoading(false);
    // } else {
    //   onCaptchVerify();

    //   const appVerifier = window.recaptchaVerifier;
    //   signInWithPhoneNumber(auth, Formatphone, appVerifier)
    //     .then((confirmationResult) => {
    //       window.confirmationResult = confirmationResult;

    //         const fromCountryId=selectedOption?.countryId || props.defaultCountry?.id
    //         const mobileno=input && input
    //         props.getallDataforSingup({fromCountryId,mobileno,phoneNumber})

    //       toast.success("OTP sended successfully!");
    //       setLoading(false);
    //       navigate('/signup_verify')
    //     })
    //     .catch((error) => {
    //       toast.error(error.code)
    //       console.log(error)
    //       if (error.code ||undefined) {
    //         {
    //           window.recaptchaVerifier.render().then((widgetId) => {
    //             window.grecaptcha.reset(widgetId);
    //           });
    //         }
    //       }
    //       setLoading(false);
    //     });
    // }
  };
  const querySearch = (e) => {
    const newsearchvale = e.target.value;
    setQuery(newsearchvale);
    const newFilteredArray = newsearchvale
      ? props.countries &&
        props.countries.filter((item) =>
          item.countryName.toLowerCase().includes(newsearchvale.toLowerCase())
        )
      : props.countries && props.countries;
    setcountryListDrop(newFilteredArray);
  };
  const getData = () => {
    const fromCountryId = selectedOption?.countryId || props.defaultCountry?.id;
    const mobileno = input && input;
    props.getallDataforSingup({ fromCountryId, mobileno });
    navigate("/signup_verify");
  };
  useEffect(() => {
    props.setSignup([]);
    props.setActive("");
    localStorage.removeItem("email");
    localStorage.removeItem("mobile");

    setcountryListDrop(props.countries && props.countries);
  }, [props.countries, showSearchInput]);

  return (
    <Fragment>
      {props.defaultCountry.flagIconUrl && countryListdrop ? (
        <>
          <div className="content" id="">
            <Toaster toastOptions={{ duration: 2000 }} />
            <div className="header">
              <img src="./dist/img/image 15.png" className="img-fluid" />
            </div>
            <div className="circle-border"></div>
            <div className="circle">
              <img src="./dist/img/Ellipse 5.png" />
              <div className="new-2">
                <img src="./dist/img/Mask group.png" />
              </div>
            </div>
          </div>
          <div className="container my-5" id="sign-up-mobile">
            <div className="cross-btn">
              <Link to="/">
                <img src="./dist/img/Vector (14).png" />
              </Link>
            </div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 py-5">
                <p className="txt-1">
                  Sign up information <span>1 of 6</span>
                </p>
                <p className="responsive">
                  <span className="span-1">
                    <img src="./dist/img/Line 3.png" />
                  </span>{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 4.png" />
                  </span>{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 4.png" />
                  </span>{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 4.png" />
                  </span>{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 4.png" />
                  </span>{" "}
                  <span className="span-1">
                    <img src="./dist/img/Line 4.png" />
                  </span>
                </p>
                <h3 className="pt-5 pb-3">Lets Get Started</h3>
                <p className="txt-3">
                  Enter your phone number, and you'll receive a code to verify
                  it's you.
                </p>

                <form onSubmit={getData}>
                  <div className="form-group pt-5">
                    <div className="row">
                      <div className="col-3 col-md-3">
                        {/* <!-- Dev. by munkgorn -->
                                <!-- Use This! #just fix width+height IMG  --> */}
                        <div className="class-2" id="new-1-section">
                          {showSearchInput && (
                            <div className="new-1">
                              <input
                                type="search"
                                id="search"
                                placeholder="search"
                                value={query}
                                onChange={querySearch}
                                style={{
                                  backgroundPosition:
                                    "top 14px left 17px !important",
                                }}
                              />
                            </div>
                          )}

                          <div
                            className="select-box"
                            onClick={handleSelectBoxClick}
                          >
                            <div className="select-box-selected">
                              {show ? (
                                <img
                                  src={selectedOption?.flag}
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : // <img
                              //   src="https://simplisend-admin-bucket.s3.amazonaws.com/India.png"
                              //   alt="loading"
                              //   style={{
                              //     width: "34px", height: "34px",
                              //     borderRadius: "50%",
                              //   }}
                              // />
                              props.defaultCountry ? (
                                <img
                                  src={props.defaultCountry.flagIconUrl}
                                  alt="loading"
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                ""
                              )}{" "}
                            </div>

                            {showOptions && (
                              <div
                                className="box-class-country"
                                id="signup-page-mobile"
                              >
                                <ul className="select-options-mobile">
                                  {countryListdrop &&
                                    countryListdrop.map((option, index) => (
                                      <li
                                        key={index}
                                        className="select-item"
                                        onClick={() =>
                                          handleOptionClick(option)
                                        }
                                      >
                                        <img
                                          src={option.flagIconUrl}
                                          alt={`Flag Icon ${option.id}`}
                                        />
                                        {option.countryName}{" "}
                                        <span
                                          style={{ float: "right" }}
                                        >{`+${option.phoneCode}`}</span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          <input
                            type="hidden"
                            className="option"
                            name="namesubmit"
                            value=""
                          />
                        </div>
                        {/* <!-- End This --> */}
                      </div>
                      <div
                        className="col-8 col-md-8"
                        style={{ paddingLeft: "0px" }}
                      >
                        <span
                          style={{
                            fontWeight: 500,

                            outlineColor: "27ae60",
                            fontSize: "14px",
                            color: "#161f4c",
                            position: "absolute",

                            top: "23px",
                            left: "25px",
                          }}
                        >
                          {selectedOption && selectedOption.code
                            ? `+${selectedOption.code}`
                            : props.defaultCountry && props.defaultCountry
                            ? `+${props.defaultCountry.phoneCode}`
                            : ""}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Mobile Number"
                          onChange={Handler}
                          onKeyPress={handleKeyPress}
                          value={input}
                          style={{
                            background: "#fff",
                            width: "413.33px",
                            outlineColor: "27ae60",
                            paddingLeft: "80px",
                            fontSize: "14px",
                            color: "#161f4c",
                            fontWeight: 500,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      id="recaptcha-container"
                      style={{
                        marginLeft: "18vh",
                        marginTop: "30px",
                        position: "absolute",
                      }}
                    ></div>
                  </div>
                  <p className="txt-2 py-4">
                    By entering and tapping Next, you agree to the{" "}
                    <span>
                      Terms, E-Sign
                      <br /> Consent
                    </span>{" "}
                    & <span>Privacy Policy.</span>
                  </p>
                  <a
                    type="button"
                    className="btn btn-1 btn-block"
                    onClick={TextView}
                  >
                    Use Email
                  </a>
                  <button
                    type="submit"
                    className="btn btn-2 btn-block"
                    onClick={onSignup}
                    style={{ padding: "12px" }}
                  >
                    {loading ? (
                      <PulseLoader
                        color="#161f4c"
                        loading={loading}
                        // cssOverride={override}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "Next"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loader">
          <Lottie animationData={loader} />;
        </div>
      )}
    </Fragment>
  );
};

export default SignupMobile;
