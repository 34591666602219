import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useEffect } from 'react';
import '../Dashboard/dashboard.css';
import Header from '../Dashboard/Header';

export default function Transactions() {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const changeHandler = value => {
        setValue(value)
    }
    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, []);
    return (
        <>
            <Header />
            <section className="main-section m-5" id="transaction-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>My Transaction <span><a href="/insights"><img src="./assets/img/insights.png" /> View insights</a></span></h3>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-5 col-md-5">
                            <input type="search" id="gsearch" value="Search transaction" />
                        </div>
                        <div className="col-4 col-md-4">
                            <select id="filter-transaction">
                                <option value="Filter by" selected>Filter by</option>
                                <option value="today" selected>Today</option>
                                <option value="yesterday" selected>Yesterday</option>
                                <option value="last month" selected>Last Month</option>
                                <option value="Cancelled" selected>Cancelled</option>
                                <option value="received" selected>Received</option>
                                <option value="send" selected>Send</option>
                            </select>
                        </div>
                        <div className="col-3 col-md-3">
                            <a href="" className="search-btn">Search</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="table-title">
                                Today
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <table className="table gfz">
                                <tbody>
                                    <tr>
                                        <th scope="row"><img src="./assets/img/name-1.png" className="img-fluid" /></th>
                                        <td>Wade Warren</td>
                                        <td>wadewarren@example.com</td>
                                        <td>(704) 555-0127</td>
                                        <td>+ 1250 USD</td>
                                        <td><span className="pill">Received</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><img src="./assets/img/name-2.png" className="img-fluid" /></th>
                                        <td>Theresa Webb</td>
                                        <td>webbtheresa @example.com</td>
                                        <td>(704) 555-0127</td>
                                        <td>+ 1250 USD</td>
                                        <td><span className="pill">Received</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="table-title">
                                Yesterday
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <table className="table gfz">
                                <tbody>
                                    <tr>
                                        <th scope="row"><img src="./assets/img/name-1.png" className="img-fluid" /></th>
                                        <td>Wade Warren</td>
                                        <td>wadewarren@example.com</td>
                                        <td>(704) 555-0127</td>
                                        <td style={{ color: "#e44e52", }}>750 USD</td>
                                        <td><span class="pill-2">Cancelled</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><img src="./assets/img/name-2.png" class="img-fluid" /></th>
                                        <td>Theresa Webb</td>
                                        <td>webbtheresa @example.com</td>
                                        <td>(704) 555-0127</td>
                                        <td>+ 1250 USD</td>
                                        <td><span className="pill">Sent</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="table-title">
                                Last Month
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <table className="table gfz">
                                <tbody>
                                    <tr>
                                        <th scope="row"><img src="./assets/img/name-1.png" className="img-fluid" /></th>
                                        <td>Wade Warren</td>
                                        <td>wadewarren@example.com</td>
                                        <td>(704) 555-0127</td>
                                        <td style={{ color: "#e44e52", }}>750 USD</td>
                                        <td><span className="pill-2">Cancelled</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>We, Simplisend, are committed to protecting the privacy of our users. This Privacy Policy outlines the types of personal information we collect and process, and how we use, disclose, and protect that information. We collect information from our users in various ways, including but not limited to, when they register for an account, subscribe to our services, or complete a form. The types of information we may collect include name, email address, postal address, telephone number, and payment information.</p>
                        </div>
                    </div>
                </div>
            </footer>

        </>

    )
}
