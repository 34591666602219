import React, { useState, useEffect } from 'react';

function Check() {
  const [count,setcount]=useState(0)
  const [iscounting,Setiscounting]=useState(false)
 
const handleClick=()=>{
  Setiscounting(!iscounting)
  const hello= "30"+ 44444
  console.log(typeof hello)


}
useEffect(()=>{
  if(iscounting){
    setTimeout(() => {
      setcount(count+1)
    }, 1000);
    
  }
  else{
    setcount(0)
  }
 
},[iscounting,count])

  return (
    <div>
      <button onClick={handleClick} className="hug">Click me</button>
      <h1 style={{color:"green"}}>{count}</h1>
     
    </div>
  );
}

export default Check;
