import axios from "./instance";
// import * as auth from "../auth";
import * as auth from "../Auth";
import apiKeys from "./apiKeys";

const getUrlByKey = (key) => {
  return apiKeys[key];
};

class API {
  // eslint-disable-next-line lines-around-comment
  /**
   * auth2 login api
   * @param {string} url String
   * @param {object} payload Object
   * @param {object} action Object e.g {type: 'AUTH', dispatch: function(){} }
   * @returns {Promise<void>} void
   */

  static apiGet = async (key, args) => {
    if (typeof args === "string") {
      return axios.get(getUrlByKey(key) + args, {
        withCredentials: false,
      });
    }
    return axios.get(getUrlByKey(key), {
      data: args,
      withCredentials: false,
    });
  };

  static apiPost = async (key, args, headers) => {
    return axios.post(getUrlByKey(key), args, headers);
  };

  static apiPut = async (key, args, headers) => {
    return axios.put(getUrlByKey(key), args, headers);
  };
}

export default API;

axios.interceptors.request.use(
  (configs) => {
    // const loading = true;
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // const loading = false;
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // eslint-disable-next-line no-console
      auth.logout();
    }
    return Promise.reject(error);
  }
);

export const setAuthorization = () => {
  axios.defaults.headers.common.Authorization =
    localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : "";
};

setAuthorization();
