import { actiontype } from "../actiontype/Actiontype";

const inititalState = {
  signup: [],
};
const SignupReducer = (state = inititalState, action) => {
  switch (action.type) {
    case actiontype.SIGNUP_SUCCESSFULL:
      return {
        ...state,
        signup: action.payload,
      };

    case actiontype.SIGNUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SignupReducer;
