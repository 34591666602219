import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import './Signupdob.css'
function SignupDob(props) {

  const navigate = useNavigate()

  const [startDate, setStartDate] = useState(null);
  const [disable, setdisable] = useState(true)
  const [inputValue, setInputValue] = useState('');
  const getDob = () => {

    const dob = startDate.toLocaleDateString()
    props.getallDataforSingup({ dob })
    navigate('/signup_location')
  }
  const getDateVale = (data) => {

    setStartDate(data)
    setdisable(!data)
  }
  return (
    <Fragment>
      <div className="content">
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" id="dob_page">
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <p className="txt-1">
              Sign up information <span>4 of 8</span>
            </p>
            <p className="increament-img responsive">
              <img src="./dist/img/Line 3.png" alt="line" />{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" alt="line" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" alt="line" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" alt="line" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" alt="line" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" alt="line" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" alt="line" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" alt="line" />
              </span>
            </p>
            <h3 className="pt-5 pb-3 heading_dob">Enter your date of birth</h3>
            <p className="txt-3">
              Please enter your date of birth so that we can better understand you.
            </p>
            <div className="date-picker">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(data, e) => getDateVale(data, e)}
                placeholderText="DD/MM/YY"
                maxDate={new Date()}
                showYearDropdown
                showMonthDropdown



              />

            </div>
            <button type="button" disabled={disable} className="btn btn-2 btn-block" style={disable ? { marginTop: "30px", padding: "12px", opacity: "0.2" } : { marginTop: "30px", padding: "12px" }} onClick={getDob}>
              Next
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SignupDob;
