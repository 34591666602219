import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  FormControl,
  Dropdown,
  Button,
} from "react-bootstrap";
import Header from "../Dashboard/Header";
import "./Profile.css";
import { CiUser, CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { TbAddressBook } from "react-icons/tb";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import API from "../../helpers/api/instance";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "../../Redux/action/Profile";
import { uploadFileToS3 } from "../utils/S3";
import { v4 as uuidv4 } from "uuid";

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };

  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};

function EditProfile() {
  const [countryCode, setCountryCode] = useState("");
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [imageCrop, setimageCrop] = useState();
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    mobileNo: "",
    email: "",
    address: "",
    profileImage: null,
  });

  const handleCountryCodeChange = (value) => {
    setCountryCode(value);
  };

  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [userDetails?.length]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageSelection = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      const file = event.target.files[0];
      console.log("file", file);
      const url = await uploadFileToS3(file, file.type);
      formData.profileImage = url;
    };
    input.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await API.put("/get/update/profile", formData);
      console.log("response", response);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="profileHeadingBox mt-4">
              <h5>My Profile</h5>
            </div>
            <Row className="editProfileCard mt-5">
              <Col align="center" lg={6} md={6} sm={6}>
                <div>
                  {userDetails?.profileImage ? (
                    <img
                      src={userDetails?.profileImage}
                      className="img img-fluid me-2 selectImg"
                    />
                  ) : (
                    <span className="noImg me-2">
                      {userDetails?.firstName?.[0]}
                    </span>
                  )}
                </div>
                <div className="imgDownText mt-4">
                  <h6>
                    {userDetails?.firstName} {userDetails?.lastName}
                  </h6>
                  <p>{userDetails?.userName}</p>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <div className="col2Text">
                  <h6>{userDetails?.email}</h6>
                  <p>Email address</p>
                </div>
                <div className="col2Text">
                  <h6>{userDetails?.mobileNo}</h6>
                  <p>Mobile Number</p>
                </div>
                <div className="col2Text">
                  <h6>{userDetails?.address}</h6>
                  <p>Address</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={1} className="d-none d-lg-block"></Col>
          <Col lg={5} md={6} sm={12}>
            <div className="editprofileCard1">
              <div className="profileHeadingBox">
                <h5>Edit my profile</h5>
              </div>
              <div align="center" className="position-relative mt-4 editImg">
                {formData.profileImage ? (
                  <img
                    src={formData.profileImage}
                    className="img img-fluid me-2 selectImg"
                  />
                ) : (
                  <img
                    src={userDetails?.profileImage}
                    className="img img-fluid me-2 selectImg"
                  />
                )}
                <span onClick={handleImageSelection}>
                  <img src="./images/editIcon.png" />
                </span>{" "}
              </div>
              <div className="editTextField mt-3">
                <Form>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <CiUser />
                    </InputGroup.Text>
                    <FormControl
                      name="firstName"
                      placeholder={userDetails?.firstName}
                      value={formData.firstName}
                      onChange={handleChange}
                      // defaultValue="hello"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <CiUser />
                    </InputGroup.Text>
                    <FormControl
                      name="lastName"
                      placeholder={userDetails?.lastName}
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text style={{ top: "1%" }}>@</InputGroup.Text>
                    <FormControl
                      name="userName"
                      placeholder={userDetails?.userName}
                      value={formData.userName}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <Row>
                    <Col lg={4} md={4} sm={4} xs={4}>
                      <PhoneInput
                        country={"us"}
                        value={countryCode}
                        onChange={handleCountryCodeChange}
                      />
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <BiPhone />
                        </InputGroup.Text>
                        <FormControl
                          // placeholder="Mobile"
                          // value={countryCode}
                          // onChange={(e) => setCountryCode(e.target.value)}
                          name="mobileNo"
                          placeholder={userDetails?.mobileNo}
                          value={formData.mobileNo}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <CiMail />
                    </InputGroup.Text>
                    <FormControl
                      name="email"
                      placeholder={userDetails?.email}
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <TbAddressBook />
                    </InputGroup.Text>
                    <FormControl
                      name="address"
                      placeholder={userDetails?.add}
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <div className="saveBtn">
                    <Button
                      type="submit"
                      variant="success"
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditProfile;
