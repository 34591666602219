import React, { useState } from "react";
import { Button, Col, FormControl, Row, InputGroup } from "react-bootstrap";
import "./Chat.css";
import Select from "react-select";
import OtpInput from "react-otp-input";

import { ReactCountryFlag } from "react-country-flag";

function ChatPay() {
  const [amt, setAmt] = useState(true);
  const [payment, setPayment] = useState(false);
  const [pin, setPin] = useState(false);
  const [otp, setOtp] = useState("");

  const handlePayment = () => {
    setAmt(false);
    setPayment(true);
  };
  const handlePin = () => {
    setPayment(false);
    setPin(true);
  };

  const countryData = [
    { value: "us", currencyCode: "USD" },
    { value: "ca", currencyCode: "CAD" },
    { value: "gb", currencyCode: "GBP" },
    { value: "eu", currencyCode: "EUR" },
    { value: "au", currencyCode: "AUD" },
    { value: "nz", currencyCode: "NZD" },
  ];

  const CountryOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <span className="country-flag">
        <ReactCountryFlag
          style={{ marginRight: "5px", width: "35px" }}
          countryCode={data.value}
          svg
        />
      </span>
      <span className="country-label">{label}</span>
      <span className="country-currency">{data.currencyCode}</span>
    </div>
  );

  const SingleValue = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <span className="country-flag">
        <ReactCountryFlag
          style={{
            borderRadius: "50%",
            marginRight: "5px",
            width: "35px",
            height: "35px",
          }}
          countryCode={data.value}
          svg
        />
      </span>
      <span className="country-label">{label}</span>
      <span className="country-currency">{data.currencyCode}</span>
    </div>
  );

  return (
    <>
      <div className="p-5">
        <Row>
          <Col lg={3}>
            <div>
              {amt ? (
                <img src="./images/CroadMap.png" className="img img-fluid" />
              ) : payment ? (
                <img src="./images/CroadMap1.png" className="img img-fluid" />
              ) : pin ? (
                <img src="./images/CroadMap2.png" className="img img-fluid" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col lg={9} align="left">
            <div className="chatPayBox pt-3">
              {amt ? (
                <>
                  <h2>How much do you want to pay?</h2>
                  <div className="mt-4">
                    <p>Available Balance: $1920.00</p>
                  </div>

                  <div className="mt-5">
                    <h6>Enter Amount</h6>
                    <div className="payFormControl mt-2">
                      <InputGroup>
                        <FormControl />
                        <InputGroup.Text>
                          <Select
                            options={countryData}
                            components={{ Option: CountryOption, SingleValue }}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                  <Button variant="success" onClick={handlePayment}>
                    Next
                  </Button>
                </>
              ) : payment ? (
                <>
                  <h2>Choose how do you want to send 1000 USD</h2>
                  <div className="mt-4">
                    <p>
                      Please select the default payment method or you can either
                      go with another payment method as well.
                    </p>
                  </div>
                  <div className="payMethodBox mt-3">
                    <h6>Selected payment method</h6>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <div>
                          <img
                            src="./images/Bank.svg"
                            className="img img-fluid me-3"
                          />
                        </div>
                        <div>
                          <h5>Bank of America </h5>
                          <p>***********5554</p>
                        </div>
                      </div>

                      <img
                        src="./images/fillCheckBox.png"
                        className="img img-fluid"
                      />
                    </div>
                    <h4 className="mt-2">Change payment method</h4>
                  </div>
                  <Button variant="success" onClick={handlePin}>
                    Continue
                  </Button>
                </>
              ) : pin ? (
                <>
                  <h2>Enter Pin</h2>
                  <div className="mt-4">
                    <p>
                      Please input your pin to securely transfer money to Lekan
                      Busayo
                    </p>
                  </div>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputType="password"
                    onKeyDown
                    containerStyle={{
                      padding: "50px",
                      marginLeft: "50px",
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                    inputStyle={{
                      width: "66px",
                      height: "80px",
                      borderRadius: "45%",
                      marginRight: "20px",
                      border: "2px solid #5CC38F",
                      color: "#283593",
                      fontSize: "70px",
                    }}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />

                  <Button variant="success" onClick={handlePin}>
                    Send $1000
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ChatPay;
