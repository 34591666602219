import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useEffect } from 'react';
import '../Dashboard/dashboard.css';
import { Link } from "react-router-dom";
import { getProfileDetails } from "../../Redux/action/Profile";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineArrowRight } from 'react-icons/ai';
import Header from '../Dashboard/Header';
export default function Exchangerate() {
    const [value, setValue] = useState('')
    const dispatch = useDispatch();

    const options = useMemo(() => countryList().getData(), [])
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const changeHandler = value => {
        setValue(value)
    }
    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, []);

    const [userDetails] = useSelector((Gstate) => [
        Gstate?.ProfileReducers?.userDetails,

    ]);
    useEffect(() => {
        dispatch(getProfileDetails());
    }, [userDetails?.length]);


    return (
        <>
        <Header />
            <section className="add-money">
                <div className="main-section m-5">
                    <div className="container-fluid" id="headd">
                        <div className="bg-1">
                            <img src="./assets/img/cross.png" class="img-fluid" />
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-3">
                                <img src="./assets/img/1 (3).png" className="img-fluid" />
                            </div>
                            <div className="col-8 col-md-6">
                                <div className="inner-section-2 px-1">
                                    <h3 className="add-txt">Exchange rates</h3>
                                    <p className="balance pt-3">Here are exchange rates of popular countries</p>
                                </div>
                                <form id="add-money-form">
                                    <div className="form-group mb-4">
                                        <div className="selector pb-2">
                                            <div className="selecotr-item">
                                                <div className="row">
                                                    {/* {userDetails && userDetails?.map((data, index) => ( */}
                                                    <>
                                                        <div className="col-md-4">
                                                            <p className="usd-cls">{userDetails?.fromCountryDetail?.currencyCode} <br /> <span>{userDetails?.fromCountryDetail?.currencyName}</span></p>
                                                        </div>
                                                        <div className="col-md-4 text-center">
                                                            <div className="inner-new-sub mx-2" >
                                                                <p className="mb-0">
                                                                    <img src={userDetails?.fromCountryDetail?.flagIconUrl} className="img-fluid exchangeImgIcon" />
                                                                    <span>&nbsp;<img src="./assets/img/arrow-1.png" className="img-fluid" /></span>&nbsp;
                                                                    <img src={userDetails?.sendToContries?.[0]?.flagIconUrl} className="img-fluid exchangeImgIcon" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 text-right">
                                                            <p className="usd-cls">{userDetails?.sendToContries?.[0]?.currencyCode} <br /> <span>{userDetails?.sendToContries?.[0]?.currencyName}</span></p>
                                                        </div>
                                                    </>
                                                     {/* ))} */}

                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <p className="new-usd">1 USD=446.87 NGN</p>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <a href='' className="send-btn-new-1">Send {userDetails?.sendToContries?.[0]?.currencyCode}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                                     
                                </form>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
