import React, { useState, useEffect } from "react";
import { FormControl, Row, Col, Container, InputGroup } from "react-bootstrap";
import "./Chat.css";
import { BsThreeDotsVertical, BsCurrencyDollar } from "react-icons/bs";
import ChatPay from "./ChatPay";
import Header from "../Dashboard/Header";
import { AiOutlineSend } from "react-icons/ai";
import axios from "axios";
import SendBird from "sendbird";
import * as authentication from "../../helpers/Auth";
import { getProfileDetails } from "../../Redux/action/Profile";
import { useDispatch, useSelector } from "react-redux";
import ChannelList from "@sendbird/uikit-react/ChannelList";

function ChatUI() {
  const [sendBird, setSendBird] = useState(null);
  const [channel, setChannel] = useState(null);
  const [channelUrl, setChannelUrl] = useState("");
  const [groupChannel, setGroupChannel] = useState(null);
  const [sentMessage, setSentMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState("");

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [messages1, setMessages1] = useState([]);

  const [receiverName, setReceiverName] = useState("");
  const [receiverProfilePic, setReceiverProfilePic] = useState("");
  const [receiverLastMessage, setReceiverLastMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [lastMessage, setLastMessage] = useState("");

  const [lastSeen, setLastSeen] = useState("");
  const dispatch = useDispatch();
  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  const APP_ID = "AC74B4DB-10DC-4F4B-90D0-FC9291D1D79B";
  // const USER_ID = userDetails?.id.toString();
  const USER_ID =
    userDetails && userDetails.id ? userDetails.id.toString() : "";
  const sb = new SendBird({ appId: APP_ID });

  useEffect(() => {
    const storageToken = localStorage.getItem("accessToken");
    !storageToken && authentication.logout();
  }, []);

  const searchUsers = async (letter) => {
    if (letter.trim() === "") {
      setUsers([]);
      return;
    }

    try {
      const response = await axios.post(
        "https://admin.simplisend.com/v1/api/search/users",
        { searchKeyword: letter },
        {
          headers: {
            Authorization: localStorage?.getItem("accessToken"),
          },
        }
      );

      setUsers(response?.data?.response?.customers);
    } catch (error) {
      console.error("Failed to retrieve users:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
    searchUsers(event.target.value);
  };

  useEffect(() => {}, []);
  const handleGroup = () => {
    sb.connect(USER_ID, (user, error) => {
      if (error) {
        console.error("Error connecting to Sendbird:", error);
      } else {
        console.log("Connected to Sendbird:", user);

        const createGroupChannel = (userIds) => {
          const params = new sb.GroupChannelParams();
          params.addUserIds(userIds);

          sb.GroupChannel.createChannel(params, (groupChannel, error) => {
            if (error) {
              if (error.code === 400201) {
                sb.GroupChannel.getChannel(
                  groupChannel.url,
                  (existingChannel, error) => {
                    if (error) {
                      console.error(
                        "Error retrieving existing group channel:",
                        error
                      );
                    } else {
                      console.log(
                        "Existing group channel retrieved:",
                        existingChannel
                      );
                      setGroupChannel(existingChannel);
                      setChannelUrl(existingChannel.url);
                      const receiver = existingChannel.members.find(
                        (member) => member.userId !== USER_ID
                      );
                      setReceiverName(receiver?.nickname);
                      setReceiverProfilePic(receiver?.profileUrl);
                      setLastSeen(receiver?.lastSeenAt);
                      setLastMessage(lastMessage?.message);
                    }
                  }
                );
              } else {
                console.error("Error creating group channel:", error);
              }
            } else {
              console.log("Group channel created successfully:", groupChannel);
              setGroupChannel(groupChannel);
              setChannelUrl(groupChannel.url);
              const receiver = groupChannel.members.find(
                (member) => member.userId !== USER_ID
              );
              setReceiverName(receiver?.nickname);
              setReceiverProfilePic(receiver?.profileUrl);
              setLastMessage(lastMessage?.message);
            }
          });
        };
        const user1 = USER_ID;
        const user2 = selectedUser;
        createGroupChannel([user1, user2]);
      }
    });
  };

  const sendMessage = () => {
    if (groupChannel && message) {
      const params = new sb.UserMessageParams();
      params.message = message;

      groupChannel.sendUserMessage(params, (message, error) => {
        if (error) {
          console.error("Error sending message:", error);
        } else {
          setSentMessage(message);
          setMessage("");
          setMessages((prevMessages) => [...prevMessages, message]);
          setLastMessage(lastMessage?.message);
        }
      });
      groupChannel.onMessageReceived = (channel, message1) => {
        setMessages1((prevMessages) => [...prevMessages, message]);
        setLastMessage(lastMessage?.message);
      };
    }
  };

  const favChat = [
    {
      img: "./images/favImg.png",
      name: "Jashne",
    },
    {
      img: "./images/favImg.png",
      name: "Jashne",
    },

    {
      img: "./images/favImg.png",
      name: "Jashne",
    },

    {
      img: "./images/favImg.png",
      name: "Jashne",
    },
    {
      img: "./images/favImg.png",
      name: "Jashne",
    },
    {
      img: "./images/favImg.png",
      name: "Jashne",
    },
    {
      img: "./images/favImg.png",
      name: "Jashne",
    },
    {
      img: "./images/favImg.png",
      name: "Jashne",
    },
  ];

  const chatData = [
    {
      img: receiverProfilePic,
      name: receiverName,
      typo: receiverLastMessage,
      lastmsg: lastMessage,
    },
  ];

  const [showDiv, setShowDiv] = useState(false);
  const [chatPay, setChatPay] = useState(false);
  const [chat, setChat] = useState(true);
  const handlePay = () => {
    setChatPay(true);
    setChat(false);
  };

  const handleClick = () => {
    setShowDiv(!showDiv);
  };

  const handleUserClick = (userId, event) => {
    event.preventDefault();
    setSelectedUser(userId);
    setSearchKeyword("");
    handleGroup();
    // createGroupChannel([USER_ID, userId]);
  };

  return (
    <div>
      <Header />
      {chat && (
        <div className="px-5 py-3">
          <Row>
            <Col lg={5} md={6} sm={12} xs={12}>
              <div className="leftSideBox">
                <div className="searchControl">
                  <FormControl
                    type="text"
                    placeholder="search..."
                    onChange={handleSearchChange}
                    value={searchKeyword}
                  />
                  <ul className="searchList">
                    {searchUsers &&
                      // users?.length &&
                      users?.map((item) => (
                        <li
                          className="text-dark py-2 cursor-pointer"
                          onClick={(event) =>
                            handleUserClick(item?.id.toString(), event)
                          }
                        >
                          {" "}
                          {item?.id.toString()}
                          {item?.firstName} {item?.lastName}
                        </li>
                      ))}
                  </ul>
                </div>
                <div align="left" className="favAccount mt-4">
                  <h5>Favorite Account</h5>
                </div>
                <div className="d-flex mb-4 favBox">
                  {favChat.map((item) => (
                    <>
                      <div>
                        <div className="my-3 mx-2">
                          <img src={item.img} />
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                <div align="left">
                  <h5>Chats</h5>
                </div>
                <div className="chatLeftBox">
                  {chatData.map((data) => {
                    return (
                      <>
                        <div className="chatBox cursor-pointer">
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  src={data.img ? data.img : ""}
                                  className="img img-fluid me-3 imgGet"
                                />
                                {/* <figure>
                                <GoPrimitiveDot />
                              </figure> */}
                              </div>
                              <div align="left">
                                <h6>{data.name}</h6>
                                <p>{data.lastmsg}</p>
                              </div>
                            </div>
                            <div>
                              <p>
                                {lastSeen
                                  ? new Date(lastSeen).toLocaleTimeString()
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <hr className="mt-1" />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col lg={7} md={6} sm={12} xs={12}>
              <div className="chatBoxRight">
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={receiverProfilePic}
                      className="me-2 img img-fluid imgGet"
                    />
                    <div align="left">
                      <>
                        <h6>{receiverName ? receiverName : ""}</h6>
                        <p>simplisend.me/@darrel.steward</p>
                      </>
                    </div>
                  </div>
                  <BsThreeDotsVertical style={{ color: "#000" }} />
                </div>
                <div className="bodyBox">
                  {messages.map((message1) => (
                    <div align="left" className="rcvMsg mt-3">
                      <p key={message.messageId}>{message.message}</p>
                    </div>
                  ))}
                  <div align="right">
                    {messages.map((message) => (
                      <div
                        align="left"
                        className="sendMsg mt-3"
                        key={message.messageId}
                      >
                        <span className="user-nickname">
                          {message._sender.nickname}
                        </span>
                        <span className="sender">
                          {message._sender.userId}:
                        </span>
                        <p>{message.message}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="footerBox d-flex align-items-center position-relative">
                  <div
                    onClick={handleClick}
                    className="dollarIconBox d-flex justify-content-center align-items-center me-2"
                  >
                    <BsCurrencyDollar />
                  </div>
                  {showDiv && (
                    <>
                      <div
                        className="payBox d-flex justify-content-center align-items-center me-2"
                        onClick={handlePay}
                      >
                        <p>
                          {" "}
                          <BsCurrencyDollar />
                          Pay
                        </p>
                      </div>
                      <div className="payBox d-flex justify-content-center align-items-center me-2">
                        <p> Request</p>
                      </div>
                    </>
                  )}
                  <FormControl
                    className="chatFormControl"
                    placeholder="Type a message here....."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                  <div
                    onClick={sendMessage}
                    className="sendIconBox position-absolute d-flex justify-content-center align-items-center"
                  >
                    <AiOutlineSend />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {chatPay && <ChatPay />}
    </div>
  );
}

export default ChatUI;
