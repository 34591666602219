import React, { Fragment } from "react";
import "./success.css";
import { Link } from "react-router-dom";
const SuccessPage = () => {
  return (
    <Fragment>
      <div className="content">
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" id="sign-up">
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5" style={{ textAlign: "center" }}>
            <img src="./dist/img/Group 2712.png" className="img-fluid" />
            <h4 className="txt-1">Password changed successfully !</h4>
            <p className="txt-3">
              Your password reset was successful! Now you can explore the app
              <br /> features. If you have any further questions or need
              assistance with
              <br /> your account, please don't hesitate to ask .
            </p>
            <Link
              type="button"
              className="btn continue btn-block"
              style={{ backgroundColor: "white" }}
              to="/"
            >
              Continue
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SuccessPage;
