import React, { useEffect } from "react";
import Header from "../Dashboard/Header";
import { Button, Row, Col, Container } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "../../Redux/action/Profile";
function MyProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [userDetails?.length]);
  return (
    <>
      <Header />
      <Container>
        <div>
          <div className="profileHeadingBox mt-4">
            <h5>My Profile</h5>
          </div>
          <div className="profileCard">
            <Row>
              <Col align="center" lg={3} md={3} sm={5} xs={12}>
                <div>
                  {userDetails?.profileImage ? (
                    <img
                      src={userDetails?.profileImage}
                      className="img img-fluid me-2 selectImg"
                    />
                  ) : (
                    <span className="noImg me-2">
                      {userDetails?.firstName?.[0]}
                    </span>
                  )}
                </div>
                <div className="imgDownText mt-4">
                  <h6>
                    {userDetails?.firstName} {userDetails?.lastName}
                  </h6>
                  <p>{userDetails?.userName}</p>
                </div>
              </Col>
              <Col lg={3} md={6} sm={7} xs={12}>
                <div className="col2Text mt-1 mb-5">
                  <h6>{userDetails?.email}</h6>
                  <p>Email address</p>
                </div>
                <div className="col2Text">
                  <h6>{userDetails?.address}</h6>
                  <p>Address</p>
                </div>
              </Col>
              <Col align="center" lg={3} md={3} sm={12} xs={12}>
                <div className="col2Text mt-1 mb-5">
                  <h6>{userDetails?.mobileNo}</h6>
                  <p>Mobile Number</p>
                </div>
                <div className="profileBtn">
                  <Button
                    variant="success"
                    onClick={() => navigate("/edit-profile")}
                  >
                    <BsPencil /> Edit profile
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

export default MyProfile;
