import React from "react";
import { Col, Row, Button } from "react-bootstrap";

function SchedulePayment() {
  return (
    <>
      <div>
        <Row>
          <Col lg={3}>
            <img src="./images/iRoadMap5.png" />
          </Col>
          <Col lg={9}>
            <div className="requestBox">
              <h6>Who are you sending to?</h6>
              <p>
                Please select the recipient or search the recipient by their
                name, email or phone to send money.
              </p>
            </div>
            <div className="getPaidBox mt-5">
              <Button variant="success">Schedule pay 1009.18 USD</Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SchedulePayment;
