import React, { useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Row,
  Col,
  Button,
  InputGroup,
} from "react-bootstrap";
import Header from "../Dashboard/Header";
import "./sendMoney.css";
import SendTo from "./SendTo";
import Select from "react-select";
import { ReactCountryFlag } from "react-country-flag";

function SendMoney() {
  const [international, setInternational] = useState(true);
  const [send, setSend] = useState(false);
  const handleNext = () => {
    setSend(true);
    setInternational(false);
  };
  const countryData = [
    { value: "us", currencyCode: "USD" },
    { value: "ca", currencyCode: "CAD" },
    { value: "gb", currencyCode: "GBP" },
    { value: "eu", currencyCode: "EUR" },
    { value: "au", currencyCode: "AUD" },
    { value: "nz", currencyCode: "NZD" },
  ];

  const CountryOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <span className="country-flag">
        <ReactCountryFlag
          style={{ marginRight: "5px", width: "35px" }}
          countryCode={data.value}
          svg
        />
      </span>
      <span className="country-label">{label}</span>
      <span className="country-currency">{data.currencyCode}</span>
    </div>
  );

  const SingleValue = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <span className="country-flag">
        <ReactCountryFlag
          style={{
            borderRadius: "50%",
            marginRight: "5px",
            width: "35px",
            height: "35px",
          }}
          countryCode={data.value}
          svg
        />
      </span>
      <span className="country-label">{label}</span>
      <span className="country-currency">{data.currencyCode}</span>
    </div>
  );

  return (
    <>
      <Header />
      <div>
        {international ? (
          <>
            <div className="p-5">
              <img className="cancelImg" src="./images/cancelImg.png" />
              <Row className="">
                <Col lg={3}>
                  <div>
                    <img
                      src="./images/iRoadMap.png"
                      className="img img-fluid"
                    />
                  </div>
                </Col>
                <Col lg={9}>
                  <Nav
                    className="d-flex justify-content-around"
                    variant="tabs"
                    defaultActiveKey="/send-money"
                  >
                    <Nav.Item>
                      <Nav.Link href="/send-money" className="tabTypo">
                        International
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-1" className="tabTypo">
                        Local
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="iFirstBox mt-4">
                    <div align="center">
                      <div className="d-flex justify-content-between formControl">
                        <div align="start">
                          <p>You send exactly</p>
                          <FormControl type="text" defaultValue="1000" />
                        </div>
                        <div className="">
                          <Select
                            options={countryData}
                            components={{
                              Option: CountryOption,
                              SingleValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex">
                      <img
                        src="./images/iLine.png"
                        className="img img-fluid lineImgClass"
                      />
                      <div className="lineTypo ml-1">
                        <p>
                          2 USD <span> Connected bank account (ACH) </span> fee
                        </p>
                        <p>7.18 USD Our fee</p>
                        <p>9.18 USD Total fees</p>
                        <hr />
                        <p className="mt-1">990.82 USD Total fees</p>
                        <p>
                          0.828466 <span>Guaranteed exchange rate(28 h) </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div align="center">
                      <div
                        align="center"
                        className="d-flex justify-content-between formControl"
                      >
                        <div align="start">
                          <p>Recipient gets</p>
                          <FormControl type="text" defaultValue="820.86068" />
                        </div>

                        <div>
                          <h2>hello</h2>
                        </div>
                      </div>
                    </div>

                    <div className="lineTypo ml-5 pl-5">
                      <p>
                        You save up to <span>20 USD </span>
                      </p>
                      <p>
                        Should arrive in <span>seconds</span>
                      </p>
                    </div>
                  </div>
                  <div className="nextBtn mt-4">
                    <Button variant="success" onClick={handleNext}>
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : send ? (
          <>
            <SendTo />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default SendMoney;
