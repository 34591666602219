import React, { Fragment, useState ,useRef} from "react";
import { useNavigate } from "react-router-dom";

import "./Signuplocation.css";

import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";


const Signuplocation = (props) => {
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const navigate = useNavigate();
  const [isConditionMet, setIsConditionMet] = useState(false);
  const [fetchedData, setfetchdata] = useState([]);
  const [selectedInput, setSelectedInput] = useState("");
  const [alladdress,setalladress]=useState("")
  const [streetadress,Setstreetadress]=useState("")
  const [adressTo, Setadressto] = useState({
    state: "",
    city: "",
    adressto: "",
    zipcode: "",
  });
  const GetLocation = (e) => {
    e.preventDefault();

    if (
      adressTo.city === "" ||
      adressTo.state === "" ||
      adressTo.zipcode === ""
    ) {
    } else if (props.active === "social") {
      props.getallDataforSingup({ ...adressTo, address: alladdress});
      navigate("/signup_country");
    } else {
      props.getallDataforSingup({ ...adressTo, address: alladdress});
      navigate("/signup_password");
    }
  };

  const getValue = (e) => {
   

    setSelectedInput(e.target.value);
    const data = () => {
      fetch(`https://maps.googleapis.com/maps/api/place/queryautocomplete/json?input=${e.target.value}&key=AIzaSyCqjo3AQCEImGNRkXFtecRGAREMo2S-JcE`,{
        headers:{
          "Access-Control-Allow-Origin":"https://simplisend.apponward.com/signup_location",
          "content-type":"application/json"
        }
      })
        .then((response) => response.json())
        .then((data) => {
         
          setfetchdata(data.predictions);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    data();
  };
  const handlePlaceChanged = (item) => {
    // console.log(inputRef.current)
    // setSelectedInput(item.description);
   
    setfetchdata([]);
    const [ place ] =autocompleteRef.current.getPlaces();
   console.log(place)
 
 
    if(place) { 
      Setstreetadress(place.vicinity)
      // console.log(place.vicinity)
      setalladress(place.name)
      const adressdata = place.address_components;
    // inputRef.current.value=place.formatted_address

          
          
          const zipcodeobj = adressdata.find((obj) =>
            obj.types.includes("postal_code")
          );
          const zipcode = zipcodeobj ? zipcodeobj.long_name : "";
          const cityobj = adressdata.find((obj) =>
            obj.types.includes("locality")
          );
          const city = cityobj ? cityobj.long_name : "";
          const neeighbourobj = adressdata.find((obj) =>
            obj.types.includes("neighborhood")
          );
          const neeighbour = neeighbourobj ? neeighbourobj.long_name : "";
          const stateobj = adressdata.find((obj) =>
            obj.types.includes("administrative_area_level_1")
          );
          const localityobj = adressdata.find((obj) =>
            obj.types.includes("sublocality_level_1")
          );
          const locality = localityobj ? localityobj.long_name : "";
          const localityob1 = adressdata.find((obj) =>
            obj.types.includes("sublocality_level_2")
          );
          const locality1 = localityob1 ? localityob1.long_name : "";
          const localityob2 = adressdata.find((obj) =>
            obj.types.includes("sublocality_level_3")
          );
          const locality2 = localityob2 ? localityob2.long_name : "";

          const localityob3 = adressdata.find((obj) =>
            obj.types.includes("administrative_area_level_3")
          );
          const locality3 = localityob3 ? localityob3.long_name : "";
          const localityob4 = adressdata.find((obj) =>
            obj.types.includes("route")
          );
          const locality4 = localityob4 ? localityob4.long_name : "";
          const state = stateobj ? stateobj.long_name : "";
          const address =
            neeighbour +
            " " +
            locality +
            " " +
            locality1 +
            " " +
            locality2 +
            " " +
            locality3 +
            " " +
            locality4;

          Setadressto({ ...adressTo, zipcode, city, state, adressto: address });
          // inputRef.current.value= address
   
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     });
    // };
    // data();
    }
  };
 console.log(adressTo)
  const isFormValid = () => {
    // return Object.values(adressTo).every((value) => value); 
    const {state,city,zipcode}=adressTo
    if (inputRef.current && state && city && zipcode ){
      return true
    }
    
  };
  const getdata = (e) => {
    Setadressto({ ...adressTo, [e.target.name]: e.target.value });
  };
console.log(inputRef.ref)
  return (
    <Fragment>
      <div className="content">
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="cross-btn">
          <a href="" className="cross-btn-1">
            <img src="./dist/img/Vector (14).png" />
          </a>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <p className="txt-1">
              Sign up information <span>5 of 8</span>
            </p>
            <p className="increament-img responsive">
              <img src="./dist/img/Line 3.png" />{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 3.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>{" "}
              <span className="span-1">
                <img src="./dist/img/Line 4.png" />
              </span>
            </p>
            <h3 className="pt-5 pb-3">Address</h3>
            <p className="txt-3">Fill in any details that are missing</p>
            <form onSubmit={GetLocation}>
              <div className="form-group pt-5">
                 <LoadScript googleMapsApiKey="AIzaSyCqjo3AQCEImGNRkXFtecRGAREMo2S-JcE" libraries={["places"]}>
                <StandaloneSearchBox
                     onLoad={automcomplete =>autocompleteRef.current=automcomplete}
                    onPlacesChanged={handlePlaceChanged}
                >
                    <input
                        type="text"
                        id="exampleInputEmail7"
                        // className="form-control"
                        ref={inputRef}
                       
                        placeholder="Street address"
                        style={{ outlineColor: "#27ae60" ,padding:'15px'}}
                    />
                </StandaloneSearchBox>
        </LoadScript>
               
             <>
                  <ul
                    style={{
                      backgroundColor: "#fff",
                      position: "absolute",
                      width: "90%",listStyleType: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", maxHeight: "400px", overflowY: "scroll",
                    }}
                  >
                    {fetchedData &&
                      fetchedData.map((item, i) => (
                        <li
                          className="hover"
                          key={i}
                         // onClick={() => handleSelect(item)}
                          style={{ cursor: "pointer", color: "#000" }}
                        >
                          {/* {item.description} */}
                        </li>
                      ))}
                  </ul>
                </>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  id="exampleInputEmail7"
                  // className="form-control"
                  placeholder="Address 2 (apt or suite#)"
                  // value={adressTo.adressto}
                  onChange={getdata}
                  name="adressto"
                  style={{ outlineColor: "#27ae60",padding:'15px' }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="exampleInputEmail7"
                  // className="form-control"
                  placeholder="City"
                  value={adressTo.city}
                  onChange={getdata}
                  name="city"
                  style={{ outlineColor: "#27ae60",padding:'15px' }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  // className="form-control"
                  id="exampleInputEmail7"
                  placeholder="State"
                  value={adressTo.state}
                  onChange={getdata}
                  name="state"
                  style={{ outlineColor: "#27ae60" ,padding:'15px'}}
                />
              </div>
              <div className="form-group pb-5">
                <input
                  type="text"
                  id="exampleInputEmail7"
                  // className="form-control"
                  placeholder="Zip Code"
                  value={adressTo.zipcode}
                  onChange={getdata}
                  name="zipcode"
                  style={{ outlineColor: "#27ae60",padding:'15px' }}
                />
              </div>
              <button
                // href="signup-password.html"
                type="submit"
                disabled={!isFormValid()}
                className="btn btn-2 btn-block"
                style={!isFormValid()?{ padding: "12px",opacity:"0.2" }:{padding: "12px"}}
              >
                Next
              </button>
            </form>
            <img src='./dist/img/image 491.png' style={{marginLeft:"13rem",marginTop:"33px",width:"149px",height:'19px'}}/>
            
          </div>
       
        </div>
       
          
          </div>
        
     
    </Fragment>
  );
};

export default Signuplocation;
