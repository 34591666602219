import { actiontype } from "../actiontype/Actiontype";

const inititalState = {
  login: [],
  error: "",
};
const Userreducer = (state = inititalState, action) => {
  switch (action.type) {
    case actiontype.USER_LOGIN:
      return {
        ...state,
        login: action.payload,
      };

    case actiontype.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case "LOGIN_REQUEST":
      return {
        ...state,
        login: [],
        error: "",
      };

    default:
      return state;
  }
};

export default Userreducer;

export const resetLogin = () => {
  return { type: "LOGIN_REQUEST" };
};
