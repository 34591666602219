import { actiontype } from "../actiontype/Actiontype";
const inititalState={
    confirmPass:{},
    error:""
}
const ConfirmpassReducer=(state=inititalState,action)=>{
    switch (action.type) {
        case actiontype.SET_CONFIRM_PASS_REQUEST:
            return {
              ...state,
              confirmPass:{}
            }
        case actiontype.SET_CONFIRM_PASS_SUCCESS:
            return {
                ...state,
                confirmPass:action.payload
            }
         
            
         case actiontype.SET_CONFIRM_PASS_FAIL:
            return{
            ...state,
            error:action.payload
            }
    
        default:
            return state
    }
}

export default ConfirmpassReducer

export const confirmPassrest=()=>{
    return {type:actiontype.SET_CONFIRM_PASS_REQUEST}

}

