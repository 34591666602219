import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AiOutlinePlus, AiFillApple } from "react-icons/ai";
import "./sendMoney.css";
import API from "../../helpers/api/instance";
import * as authentication from "../../helpers/Auth";
import { Toaster, toast } from "react-hot-toast";
import OtpInput from "react-otp-input";
import { number } from "yup";
import SchedulePayment from "./SchedulePayment";

function SendTo() {
  const [linkToken, setLinkToken] = useState("");
  const [plaidShow, setPlaidShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [connectBank, setConnectBank] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);
  const [enterpin, setEnterPin] = useState(false);
  const [payDone, setPayDone] = useState(false);
  const [schedule,setSchedule] = useState(false);

  const [roadmap1, setRoadMap1] = useState(true);
  const [roadmap2, setRoadMap2] = useState(false);
  const [roadmap3, setRoadMap3] = useState(false);

  const [heading, setHeading] = useState(true);
  const [heading1, setHeading1] = useState(false);
  const [heading2, setHeading2] = useState(false);

  const [def, setDef] = useState(true);
  const [other, setOther] = useState(false);

  const [four, setFour] = useState(false);
  const handleShow = () => {
    setFirst(false);
    setSecond(true);
  };
  const handleEnter = () => {
    setRoadMap1(false);
    // setRoadMap2(true);
    setSecond(false);
    setThird(true);
    setHeading(false);
  };
  const handleFour = () => {
    setThird(false);
    setFour(true);
    setRoadMap3(true);
    setHeading1(true);
  };
  const handleOther = () => {
    setDef(false);
    setOther(true);
  };
  const handleConnect = () => {
    setConnectBank(true);
    setOther(false);
    setFour(false);
    setRoadMap3(false);
    setHeading1(false);
  };

  const handleSelectBank = () => {
    setConnectBank(false);
    setRoadMap3(true);
    setHeading2(true);
    setFive(true);
  };
  const handleNext = () => {
    setSix(true);
    setFive(false);
    setHeading2(false);
  };

  const handleSchedule = () => {
    setSeven(true);
    setSix(false);
    setRoadMap3(false);
  };

  const handlePin = () => {
    setEnterPin(true);
    setSeven(false);
  };

  const handleSuccess = () => {
    setEnterPin(false);
    setPayDone(true);
  };

  const handleSchedulePage = () =>{
    setEnterPin(false);
    setSchedule(true);
    setSeven(false);

  }
  useEffect(() => {
    const storageToken = localStorage.getItem("accessToken");
    !storageToken && authentication.logout();
  }, []);

  const fetchLinkToken = async () => {
    setLoading(true);
    setTimeout(setLoading, 2000);
    try {
      const response = await API.get("/link/token/create/web");
      toast.success("Bank Connecting...", {
        position: "top-right",
        style: {
          borderBottom: "4px solid #33a34e",
          padding: "16px",
          color: "#3c5f4b",
          marginRight: "25px",
        },
      });
      const newLinkToken = response?.data?.response?.data?.link_token;
      setLinkToken(newLinkToken);
      localStorage.setItem("linkToken", newLinkToken);
      setPlaidShow(true);
      setLoading(false);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Failed to fetch link token:", error);
    }
  };

  const handleClick = () => {
    fetchLinkToken();
    setLoading(true);
  };
  return (
    <>
      <div className="p-5">
        <Row spacing={5}>
          <Col lg={3} md={2}>
            <div align="left">
              {roadmap1 ? (
                <img src="./images/iRoadMap1.png" className="img img-fluid" />
              ) : roadmap2 ? (
                <img src="./images/iRoadMap2.png" className="img img-fluid" />
              ) : roadmap3 ? (
                <img src="./images/iRoadMap3.png" className="img img-fluid" />
              ) : seven ? (
                <img src="./images/iRoadMap4.png" className="img img-fluid" />
              ) : enterpin ? (
                <img src="./images/iRoadMap5.png" className="img img-fluid" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col lg={9}>
            <div className="manageWidth my-3">
              {heading ? (
                <div className="requestBox">
                  <h6>Who are you sending to?</h6>
                  <p>
                    Please select the recipient or search the recipient by their
                    name, email or phone to send money.
                  </p>
                </div>
              ) : heading1 ? (
                <div className="requestBox">
                  <h6>Choose how do you want to send 1009.18 USD</h6>
                  <p>
                    Please select the default payment method or you can either
                    go with another payment method as well.
                  </p>
                </div>
              ) : heading2 ? (
                <div className="requestBox">
                  <h6>Find my bank</h6>
                  <p>
                    Please select from the below bank or either you can search
                    your bank.
                  </p>
                </div>
              ) : six ? (
                <>
                  <div className="requestBox">
                    <h6>Choose how do you want to send 1009.18 USD</h6>
                    <p>
                      Please select the default payment method or you can either
                      go with another payment method as well.
                    </p>
                  </div>
                </>
              ) : seven ? (
                <>
                  <div className="requestBox">
                    <h6>Schedule transfer time</h6>
                    <p>
                      Please select the default payment method or you can either
                      go with another payment method as well.
                    </p>
                  </div>
                </>
              ) : enterpin ? (
                <>
                  <div className="requestBox">
                    <h6>Enter Pin</h6>
                    <p>
                      Please input your pin to securely transfer money to Lekan
                      Busayo
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {first ? (
                <>
                  <div className="searchBox">
                    <Form>
                      <Form.Control
                        type="text"
                        placeholder="Name, email, phone"
                      />
                    </Form>
                  </div>
                  <div className="classH5 pt-3 d-flex justify-content-between align-items-center">
                    <h5>Your recipient</h5>
                    <div className="d-flex align-items-center classP">
                      <AiOutlinePlus className="me-2" />
                      <p>Add new recipient</p>
                    </div>
                  </div>
                  <div className="contactBox d-flex p-4">
                    <div>
                      <img
                        src="./images/contactImg.png"
                        className="img img-fluid me-4"
                      />
                      <span>
                        <img src="./images/flagImg.png" />
                      </span>
                    </div>
                    <div align="left" onClick={handleShow}>
                      <h6>Wade Warren</h6>
                      <p>GBP account ending in 9044</p>
                    </div>
                  </div>
                </>
              ) : second ? (
                <>
                  <div className="classH5 py-3">
                    <h5>Receiver</h5>
                  </div>
                  <div className="contactBox p-4">
                    <div className="d-flex">
                      <div>
                        <img
                          src="./images/contactImg.png"
                          className="img img-fluid me-4"
                        />
                        <span>
                          <img src="./images/flagImg.png" />
                        </span>
                      </div>
                      <div align="left">
                        <h6>Wade Warren</h6>
                        <p>simplisend.me/@wade.warren</p>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div>
                        <img
                          src="./images/Bank.svg"
                          className="img img-fluid me-4"
                        />
                        <span>
                          <img src="./images/flagImg.png" />
                        </span>
                        <div className="changeBtn mt-5">
                          <h6>Change</h6>
                        </div>
                      </div>
                      <div align="left">
                        <h6>Savings Account</h6>
                        <div className="d-flex align-items-center">
                          <img
                            src="./images/flagImg.png"
                            className="img img-fluid"
                          />
                          <p className="mb-0">***********5554</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="getPaidBox mt-5">
                    <Button onClick={handleEnter} variant="success">
                      Continue
                    </Button>
                  </div>
                </>
              ) : four ? (
                <>
                  <div className="defaultBox">
                    {def ? (
                      <>
                        <h6>Default payment method</h6>
                        <hr />
                        <div className="d-flex">
                          <div>
                            <img
                              src="./images/cardIcon.png"
                              className="img img-fluid me-5"
                            />
                          </div>
                          <div>
                            <h5>Link your bank account(ACH)</h5>
                            <p>
                              7.40 USD in total fees. Pay with ACH by connecting
                              your bank account to simplisend. Recipient gets
                              822.34 GBP. Should arrive in seconds.
                            </p>
                          </div>
                        </div>
                      </>
                    ) : other ? (
                      <>
                        <h6>Other payment method</h6>
                        <hr />
                        <div className="d-flex">
                          <div>
                            <img
                              src="./images/cardIcon.png"
                              className="img img-fluid me-5"
                            />
                          </div>
                          <div>
                            <h5>Apple Pay</h5>
                            <p>
                              With a debit card from your wallet, it's 18.85 USD
                              in total fees, so recipient gets 812.85 GBP. With
                              a credit card from your wallet, it's 46.44 USD in
                              total fees, so recipient gets 789.99 GBP. Should
                              arrive in seconds.
                            </p>
                            <div className="payBtn">
                              <Button variant="dark" onClick={handleConnect}>
                                <AiFillApple /> Pay
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-4">
                          <div>
                            <img
                              src="./images/wireTransferIcon.png"
                              className="img img-fluid me-5"
                            />
                          </div>
                          <div>
                            <h5>Wire Transfer</h5>
                            <p>
                              8.91 USD in total fees. Your bank may charge an
                              additional fee. Recipient gets 821.08 GBP. Should
                              arrive by Tuesday.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mt-4">
                          <div>
                            <img
                              src="./images/debitCardIcon.png"
                              className="img img-fluid me-5"
                            />
                          </div>
                          <div>
                            <h5>Debit Card</h5>
                            <p>
                              18.85 USD in total fees. Your bank may charge an
                              additional fee. Recipient gets 821.08 GBP. Should
                              arrive by Tuesday.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mt-4">
                          <div>
                            <img
                              src="./images/creditCardIcon.png"
                              className="img img-fluid me-5"
                            />
                          </div>
                          <div>
                            <h5>Credit Card</h5>
                            <p>
                              46.44 USD in total fees. Your bank may charge an
                              additional fee. Recipient gets 821.08 GBP. Should
                              arrive by Tuesday.
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {def ? (
                    <>
                      <div className="getPaidBox mt-5">
                        <Button variant="success" onClick={handleClick}>
                          Continue
                        </Button>
                      </div>
                      <div className="customLinkBtn mt-4 pb-5">
                        <Button variant="link" onClick={handleOther}>
                          {" "}
                          Use another payment method
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : five ? (
                <>
                  <div className="searchBox">
                    <Form>
                      <Form.Control type="text" placeholder="Search Bank" />
                    </Form>
                  </div>
                  <div
                    className="defaultBox p-1 pl-4 mt-3"
                    onClick={handleNext}
                  >
                    <div className="d-flex mt-4">
                      <div>
                        <img
                          src="./images/wireTransferIcon.png"
                          className="img img-fluid me-3"
                        />
                      </div>
                      <div>
                        <h5>Add your bank manually</h5>
                        <p>Link with routing & account number instead</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : six ? (
                <>
                  <div className="payMethodBox">
                    <h6>Selected payment method</h6>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <div>
                          <img
                            src="./images/Bank.svg"
                            className="img img-fluid me-3"
                          />
                        </div>
                        <div>
                          <h5>Bank of America </h5>
                          <p>***********5554</p>
                        </div>
                      </div>

                      <img
                        src="./images/fillCheckBox.png"
                        className="img img-fluid"
                      />
                    </div>
                    <h4 className="mt-2">Change payment method</h4>
                  </div>
                  <div align="center" className="getPaidBox mt-5">
                    <Button variant="success" onClick={handleSchedule}>
                      Schedule transfer time
                    </Button>
                  </div>
                </>
              ) : seven ? (
                <>
                  <div className="scheduleBox d-flex align-items-center my-4">
                    <img
                      src="./images/sendImedietlyImg.png"
                      className="img img-fluid me-3"
                    />
                    <div>
                      <h6>Send Immediately</h6>
                      <p>
                        Send immediately will send your money at the moment.
                      </p>
                    </div>
                  </div>
                  <div className="scheduleBox d-flex align-items-center " onClick={handleSchedulePage}>
                    <img
                      src="./images/schedulePaymentImg.png"
                      className="img img-fluid me-3"
                    />
                    <div>
                      <h6>Schedule payment</h6>
                      <p>
                        You can schedule your transfer by choosing the time and
                        date.
                      </p>
                    </div>
                  </div>
                  <div align="center" className="getPaidBox mt-5">
                    <Button variant="success" onClick={handlePin}>
                      Schedule transfer time
                    </Button>
                  </div>
                </>
              ) : enterpin ? (
                <>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputType="password"
                    onKeyDown
                    containerStyle={{
                      padding: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    inputStyle={{
                      width: "66px",
                      height: "80px",
                      borderRadius: "45%",
                      marginRight: "20px",
                      border: "2px solid #5CC38F",
                      color: "#283593",
                      fontSize: "70px",
                    }}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  <div align="center" className="getPaidBox mt-5">
                    <Button variant="success" onClick={handleSuccess}>
                      Pay 1009.18 USD
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        {third && (
          <Row>
            <Col lg={2}>
              <div>
                <img src="./images/iRoadMap2.png" className="img img-fluid" />
              </div>
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={6}>
                  <div className="selectTypo">
                    <h6>Total amount (Entered amount + Charges)</h6>
                  </div>
                  <div className="selectBox">
                    <h1>
                      1009.18 <span>$</span>
                    </h1>
                    <div className="d-flex align-items-center mt-4">
                      <img
                        src="./images/dot.png"
                        className="img img-fluid me-2"
                      />
                      <p>2 USD Connected bank account (ACH) fee</p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <img
                        src="./images/dot.png"
                        className="img img-fluid me-2"
                      />
                      <p>7.18 USD Our fee</p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <img
                        src="./images/plusIcon.png"
                        className="img img-fluid me-2"
                      />
                      <p>9.18 USD Total fees</p>
                    </div>
                    <div className="my-4">
                      <hr />
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <img
                        src="./images/equalIcon.png"
                        className="img img-fluid me-2"
                      />
                      <p>1009.18 USD Total fees</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="selectTypo">
                    <h6>Exchange rate used</h6>
                  </div>
                  <div className="selectBox2">
                    <h5>0.828466</h5>
                    <p>
                      This is the guaranteed exchange rate for this transfer
                    </p>
                  </div>
                  <div className="classH5 py-3 mt-2">
                    <h5>Receiver</h5>
                  </div>
                  <div className="contactBox p-4">
                    <div className="d-flex">
                      <div>
                        <img
                          src="./images/contactImg.png"
                          className="img img-fluid me-4"
                        />
                        <span>
                          <img src="./images/flagImg.png" />
                        </span>
                      </div>
                      <div align="left">
                        <h6>Wade Warren</h6>
                        <p>simplisend.me/@wade.warren</p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img
                          src="./images/Bank.svg"
                          className="img img-fluid me-4"
                        />
                        <span>
                          <img src="./images/flagImg.png" />
                        </span>
                        <div className="changeBtn mt-5">
                          <h6>Change</h6>
                        </div>
                      </div>
                      <div align="left">
                        <h6>Savings Account</h6>
                        <div className="d-flex align-items-center">
                          <img
                            src="./images/flagImg.png"
                            className="img img-fluid"
                          />
                          <p className="mb-0">***********5554</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <div align="center" className="getPaidBox mt-5">
                  <Button variant="success" onClick={handleFour}>
                    Select payment method
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        )}
        {connectBank && (
          <>
            <Container align="center">
              <div className="conBankH5 mb-5">
                <h5>Connect your bank</h5>
              </div>
              <div>
                <img
                  src="./images/connectBankSendImg.png"
                  className="img img-fluid"
                  alt="Bank"
                />
              </div>
              <Form>
                <div className="conCheckBox my-3">
                  <Form.Check
                    type="checkbox"
                    id="1"
                    label="We use bank level encryption to protect your information"
                  />
                  <Form.Check
                    type="checkbox"
                    id="2"
                    label="We use bank level encryption to protect your information"
                  />
                </div>
              </Form>
              <div className="conBtn mt-5">
                <Button variant="success" onClick={handleSelectBank}>
                  Select payment method
                </Button>
              </div>
              <div className="terms mt-4">
                <p>
                  By selecting “Continue”, you agree to{" "}
                  <span>terms & conditions</span>
                </p>
              </div>
            </Container>
          </>
        )}
        {/* success page */}
        {payDone && (
          <>
            <div align="center" className="successBox">
              <div>
                <img src="./images/sendTick.png" />
              </div>
              <div>
                <h6>Transaction completed successfully</h6>
                <p>You have successfully transferred $ 1009.18</p>
              </div>

              <div className="successBoxBank mt-4">
                <div className="d-flex align-items-center">
                  <img
                    src="./images/contactImg.png"
                    className="img img-fluid me-3"
                  />
                  <div align="left">
                    <h6>Mayank Tyagi</h6>
                    <h5>simplisend.me/@wade.warren</h5>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div>
                    <img
                      src="./images/Bank.svg"
                      className="img img-fluid me-3"
                    />
                  </div>
                  <div align="left">
                    <h6>Savings Account</h6>
                    <div className="d-flex align-items-center">
                      <img
                        src="./images/flagImg.png"
                        className="img img-fluid"
                      />
                      <p className="mb-0">***********5554</p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4>View transaction details</h4>
                </div>
              </div>
              <div className="successBoxBank d-flex justify-content-between align-items-end my-4">
                <div>
                  <h1>Wallet Details</h1>
                  <p>Current wallet balance</p>
                  <h1>920.00</h1>
                </div>
                <div>
                  <Button>Add Money</Button>
                </div>
              </div>
            </div>
            <div align="center" className="getPaidBox mt-5">
              <Button variant="success" onClick={handleSuccess}>
                Go to home
              </Button>
            </div>
          </>
        )}

        {/* schedule payment */}
        {schedule && (
          <SchedulePayment />
        )}
      </div>
    </>
  );
}

export default SendTo;
