import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyOtpaction } from "../../Redux/action/Useraction";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { resetOtp } from "../../Redux/reducer/ResetpassVerifyOtp";
import { resetPassword } from "../../Redux/action/Useraction";
import { resetPass } from "../../Redux/reducer/ResetpassReducer";
import { auth } from "../../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";

const Loginverify = (props) => {
  const emailaddress=localStorage.getItem("email")
  const mobileNumber=localStorage.getItem("mobile")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showemail, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [otpsent,Setotpsent]=useState(false)
  const inputRefs=useRef([])
  

  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const error = useSelector(
    (state) => state?.ResetpassVerifyOtp?.verify?.data?.error?.errorMessage
  );
  const success = useSelector(
    (state) =>
      state?.ResetpassVerifyOtp?.verify?.data?.response?.message?.successMessage
  );
  const resendSuccess = useSelector(
    (state) =>
      state.reset?.Email_reset_pass?.data?.response?.message?.successMessage
  );
  const ResendOtpemail = () => {
    dispatch(resetPassword(props.verifyemail));
    Setotpsent(true)
  };

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onOtpforMobile();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
    if(window.recaptchaVerifier){
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onOtpforMobile();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
    window.recaptchaVerifier.render().then((widgetId) => {
      window.grecaptcha.reset(widgetId);
    });
  };
  const onOtpforMobile = (result) => {
    const phone = result.toString();

    const Formatphone = `+${phone}` + props.verifyemail;

    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, Formatphone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        toast.success("OTP sended successfully!");
        Setotpsent(true)
      })

      .catch((error) => {
        alert(error);
      });
  };
  const ResendOtpMobile = async () => {
    try {
      const response = await fetch(
        "https://admin.simplisend.com/v1/api/account/existence/check",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobileNo: props.verifyemail,
          }),
        }
      );
      const result = await response.json();

      if (!result.response.isExists) {
        toast.error("this account is not exists");
      } else {
        onOtpforMobile(result.response.countryCode);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const Resetmobileverify = (e) => {
    const concatenatedValue = inputValues.join("");
    e.preventDefault();
    window.confirmationResult
      .confirm(concatenatedValue)
      .then(async (res) => {
      localStorage.removeItem("mobile")
        navigate("/login_password_reset");
      })
      .catch((err) => {
        toast.error(err.code)
        if (error.code || undefined) {
          {
            window.recaptchaVerifier.render().then((widgetId) => {
              window.grecaptcha.reset(widgetId);
            });
          }
        }
      });
  };
  const handleKeyDown = (index, e) => {
    if (e.keyCode === 8 && !inputValues[index]) {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        inputRefs.current[prevIndex].focus();
      }
    }
  };
  const handlepaste = (event) => {
    const pastedData = event.clipboardData.getData("text");
    const   newInputValues = [...inputValues];
    for (let i = 0; i < 6; i++) {
      if (pastedData[i]) {
        newInputValues[i] = pastedData[i];
      }
    }
    setInputValues(newInputValues);
  };
  const handleChange = (index, e) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = e.target.value;
    setInputValues(newInputValues);
    const nextIndex = index + 1;
    if (
      nextIndex < inputValues.length &&
      e.target.value.length === e.target.maxLength
    ) {
      inputRefs.current[nextIndex].focus();
    }
  };
  const resetpassVerify = (e) => {
    const concatenatedValues = inputValues.join("");
    e.preventDefault();
    dispatch(verifyOtpaction(props.verifyemail, concatenatedValues));
  };
  const isFormValid = inputValues.every((value) => value);

  useEffect(() => {
    if (success) {
      toast.success(success);
      localStorage.removeItem("email")
      setTimeout(() => {
        navigate("/login_password_reset");
      }, 2000);
    } else if (resendSuccess) {
      toast.success(resendSuccess);
    
    } else if (error) {
      toast.error(error);
    }
    if (props.action === "mobile") {
      const maskNumber = (numberValue) => {
        if (numberValue.length > 8) {
          const maskedChars = "****";
          const lastChars = numberValue.slice(-4);
          localStorage.setItem("mobile",maskedChars + lastChars)
          return setnumber(maskedChars + lastChars);
        }
        return numberValue;
      };
      maskNumber(props.verifyemail);
    } else if (props.action === "email") {
      const maskEmail = (emailValue) => {
        const atPosition = emailValue.indexOf("@");
        if (atPosition > 2) {
          let maskedChars = emailValue.slice(0, 2);
          for (let i = 2; i < atPosition; i++) {
            maskedChars += "*";
          }
          maskedChars += emailValue.slice(atPosition);
          localStorage.setItem("email",maskedChars)
          return setemail(maskedChars);
        }
        return emailValue;
      };
      maskEmail(props.verifyemail);
    }

    return () => {
      dispatch(resetOtp());
      dispatch(resetPass());
    };
  }, [success, error, dispatch, resendSuccess,localStorage]);
 
 

  return (
   
    <Fragment>
   
      <div className="content">
        <Toaster toastOptions={{ duration: 2000 }} />
        <div
          id="recaptcha-container"
          style={{
            marginLeft: "18vh",
            marginTop: "30px",
            position: "absolute",
          }}
        ></div>
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" >
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <h3 className="pt-5 pb-3">Verify your identity</h3>
            <p className="txt-3">
              {props.action === "email"
                ? "Please enter the verification code send to your email address"
                : "Please enter the verification code send to your mobile number"}

              <br />
              <span style={{color:"rgba(39, 174, 96, 1)"}}>{mobileNumber? mobileNumber : emailaddress && emailaddress}</span>
            </p>
            <form
              className="mt-5"
              id="otporm"
              onSubmit={
                props.action === "email" ? resetpassVerify : Resetmobileverify
              }
            >
              {inputValues.map((value, index) => (
                <input
                key={`input${index}`}
                name={`input${index}`}
                value={value}
                maxLength={1}
                // className="otp input"
                type="text"
                onKeyDown={(e) => handleKeyDown(index, e)}
                onChange={(e) => handleChange(index, e)}
                ref={(input) => (inputRefs.current[index] = input)}
                onPaste={handlepaste}
                className={`otp input ${
                  value ? "input-field has-value" : "input-field"
                }`}
                />
              ))}

              <p className="txt-2 py-4 text-center">
                I didn’t receive code?{" "}
                <span
                  onClick={
                    props.action === "email" ? ResendOtpemail : ResendOtpMobile
                  }
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                Resend Otp
                </span>
              </p>
              <button type="submit" className="btn btn-2 btn-block"
              disabled={!isFormValid}
               style={{ opacity: isFormValid ? "":"0.2" ,padding:"12px"}} >
                Verify
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Loginverify;
