import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function RequestSend() {
    const navigate = useNavigate();
  return (
    <>
      <div align="center" className="requestSendModal mt-5">
        <img src="./images/sendTick.png" className="img img-fluid" />
        <h6>Request sent</h6>
        <p>Your request for $500 from Satish has been sent.</p>
      </div>
      <div className="contactBox d-flex justify-content-between p-4 mt-5">
        <div className="d-flex">
          <div>
            <img src="./images/contactImg.png" className="img img-fluid me-4" />
            <span>
              <img src="./images/flagImg.png" />
            </span>
          </div>
          <div align="left">
            <h6>Wade Warren</h6>
            <p>GBP account ending in 9044</p>
          </div>
        </div>
        <div>
          <h5>VISA</h5>
          <p>0332 3222 4536</p>
        </div>
      </div>
      <div className="getPaidBox mt-5">
        <Button variant="success" onClick={()=> navigate("/dashboard")}>
          Go to dashboard
        </Button>
      </div>
    </>
  );
}

export default RequestSend;
