import "./App.css";
import Header from "./components/header/Header";
// import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { Fragment, useState, useEffect, Suspense } from "react";
import Refer from "./components/Refercomp/Refer";
import Howitworks from "./components/Howitworks/Howitworks";
import Help from "./components/help/Help";
import Map from "./components/Check";
import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import PlaceComponent from "./components/Check";
import Exchangerate from "./components/Exchangerate/Exchangerate";
import Accountlimit from "./components/Accountlimit/Accountlimit";
import Accountdetails from "./components/Accountlimit/Accountdetails";
import Footr from "./components/Footer/Footr";
import Singup from "./components/Signup/Singup";
import SignupMobile from "./components/Signup/SignupMobile";
import Verify from "./components/Signup/Verify";
import SignupName from "./components/Signup/SignupName";
import Password from "./components/Signup/Password";
import SignupCountry from "./components/Signup/SignupCountry";
import Setuppin from "./components/Signup/Setuppin";
import ForgotPassword from "./components/Login/ForgotPassword";
import Loginverify from "./components/Login/Loginverify";

import Login from "./components/Login/Login";

import LoginresetPassword from "./components/Login/LoginresetPassword";
import Signuplocation from "./components/Signup/Signuplocation";
import SignupDob from "./components/Signup/SignupDob";
import SuccessPage from "./components/Login/SuccessPage";
import Check from "./components/Check";
import DateofBirth from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Addmoney from "./components/Addmoney/Addmoney";
import Addmoneychooseaccount from "./components/Addmoney/Addmoneychosseaccount";
import Addmoneypaymentmethod from "./components/Addmoney/Addmoneypaymentmethod";
import Addmoneyselectedpaymentmethod from "./components/Addmoney/Addmoneyselectedpaymentmethod";
import Addmoneyenterpin from "./components/Addmoney/Addmoneyenterpin";
import Addmoneysucess from "./components/Addmoney/Addmoneysucess";
import Withdrawmoney from "./components/Withdrawmoney/Withdrawmoney";
import Withdrawmoneychooseaccount from "./components/Withdrawmoney/Withdrawmoneychooseaccount";
import Withdrawmoneytransfer from "./components/Withdrawmoney/Withdrawmoneytransfer";
import Withdrawmoneyenterpin from "./components/Withdrawmoney/Withdrawmoneyenterpin";
import Withdrawmoneysuccess from "./components/Withdrawmoney/Withdrawmoneysuccess";
import Transactions from "./components/Transactions/Transactions";
import Insights from "./components/Transactions/Insights";
import Spending from "./components/Transactions/Spending";
import Auth from "./helpers/Auth";
import Accounts from "./components/account/Accounts";
import ConnectBank from "./components/account/ConnectBank";
import Loader from "./components/loader/Loader";
import MyProfile from "./components/profile/MyProfile";
import EditProfile from "./components/profile/EditProfile";
import AccountSetting from "./components/profile/AccountSetting";
import Referral from "./components/profile/Referral";
import Support from "./components/profile/Support";
import Legal from "./components/profile/Legal";
import RequestMoney from "./components/Money/RequestMoney";
import SendMoney from "./components/SendMoney/SendMoney";
import ChatUI from "./components/Chat/ChatUI";
import Example from "./components/Chat/Example";

function App() {
  const [verifyemail, Setverifyemail] = useState("");
  const [active, setActive] = useState("");
  const [action, setaction] = useState("");
  const [signup, setSignup] = useState([]);
  const [textx, Settext] = useState("");
  const [countries, setCountries] = useState([]);
  const [defaultCountry, setDefaultcountry] = useState({});
  const [isLoading, setLoading] = useState(false);
  const toggleLoader = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const GetEmail = (email) => {
    Setverifyemail(email);
  };
  const activeTab = (data) => {
    setaction(data);
  };
  const getallDataforSingup = (data) => {
    setSignup({
      ...signup,
      ...data,
    });
  };
  // const fetchApi = async () => {
  //   const data = await fetch(
  //     "https://admin.simplisend.com/v1/api/country/code/list"
  //   );
  //   const res = await data.json();

  //   const data1 = await fetch(
  //     "https://geolocation-db.com/json/a9e48c70-8b22-11ed-8d13-bd165d1291e3"
  //   );
  //   const res1 = await data1.json();
  //   console.log('res1', res1)
  //   //  console.log(res1)
  //   const allcountry = [...res.response.top5countries, ...res.response.data];
  //   const location = await allcountry.find(
  //     (item) => item.sortName === res1.country_code
  //   );

  //   setDefaultcountry(location);
  //   setCountries(allcountry);
  // };

  const fetchApi = async () => {
    try {
      const data = await fetch(
        "https://admin.simplisend.com/v1/api/country/code/list"
      );
      const res = await data.json();

      // Replace with the ipapi.co service
      const data1 = await fetch("https://ipapi.co/json/");
      const res1 = await data1.json();
      
      const allcountry = [...res.response.top5countries, ...res.response.data];
      const location = allcountry.find(
        (item) => item.sortName === res1.country_code
      );

      setDefaultcountry(location);
      setCountries(allcountry);
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
    }
  }

  useEffect(() => {
    fetchApi();
    getallDataforSingup();
  }, []);
  const isAuthenticated = true; // Replace with your authentication logic

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route
                path="/howitworks"
                element={<Check arrayOfObjects={countries} />}
              />
              <Route
                path="/"
                element={
                  <Login
                    setActive={setActive}
                    getallDataforSingup={getallDataforSingup}
                    setSignup={setSignup}
                  />
                }
              />
              <Route
                path="/login_forgot_password"
                exact
                element={
                  <ForgotPassword email={GetEmail} getactive={activeTab} />
                }
              />
              <Route
                path="/login_verify"
                exact
                element={
                  <Loginverify verifyemail={verifyemail} action={action} />
                }
              />
              <Route
                path="/login_password_reset"
                exact
                element={<LoginresetPassword verifyemail={verifyemail} />}
              />
              <Route
                path="/login_password_reset_succes"
                exact
                element={<SuccessPage />}
              />
              <Route path="/help" element={<Help />} />
              <Route path="/refer" element={<Refer />} />
              <Route
                path="/signup_verify"
                element={
                  <Verify
                    text={
                      textx === "" &&
                      "Please enter the verification code send to your Mobile number"
                    }
                    getallDataforSingup={getallDataforSingup}
                    Settext={textx}
                    defaultCountry={defaultCountry}
                    // fixtext={Settext}
                    Number="XXXX22"
                    signup={signup}
                    active={active}
                  />
                }
              />
              <Route
                path="/signup_pin"
                element={
                  <Setuppin
                    getallDataforSingup={getallDataforSingup}
                    signUpdata={signup}
                    Settext={textx}
                    active={active}
                  />
                }
              />
              {(active === "" ||
                active === "social" ||
                active === undefined) && (
                  <Route
                    path="/signup"
                    element={
                      <SignupMobile
                        active={active}
                        setActive={setActive}
                        Settext={Settext}
                        countries={countries}
                        getallDataforSingup={getallDataforSingup}
                        defaultCountry={defaultCountry}
                        setSignup={setSignup}
                      />
                    }
                  />
                )}

              {active === "email" && (
                <Route
                  path="/signup"
                  element={
                    <Singup
                      setSignup={setSignup}
                      setActive={setActive}
                      active={active}
                      Settext={Settext}
                      text={textx}
                      getallDataforSingup={getallDataforSingup}
                    />
                  }
                />
              )}
              <Route
                path="/signup_country"
                element={
                  <SignupCountry
                    getallDataforSingup={getallDataforSingup}
                    countries={countries}
                    setCountries={setCountries}
                    active={active}
                    defaultCountry={defaultCountry}
                  />
                }
              />
              <Route
                path="/signup_location"
                element={
                  <Signuplocation
                    getallDataforSingup={getallDataforSingup}
                    active={active}
                  />
                }
              />
              <Route
                path="/signup_dob"
                element={
                  <SignupDob getallDataforSingup={getallDataforSingup} />
                }
              />
              <Route
                path="/signup_password"
                element={
                  <Password
                    getallDataforSingup={getallDataforSingup}
                    active={active}
                  />
                }
              />
              <Route
                path="/singup_name"
                element={
                  <SignupName getallDataforSingup={getallDataforSingup} />
                }
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/addmoney" element={<Addmoney />} />
              <Route
                path="/addmoney_chooseaccount"
                element={<Addmoneychooseaccount />}
              />
              <Route
                path="/addmoney_paymentmethod"
                element={<Addmoneypaymentmethod />}
              />
              <Route
                path="/addmoney_selectedpaymentmethod"
                element={<Addmoneyselectedpaymentmethod />}
              />
              <Route path="/addmoney_enterpin" element={<Addmoneyenterpin />} />
              <Route path="/addmoney_success" element={<Addmoneysucess />} />
              <Route path="/withdrawmoney" element={<Withdrawmoney />} />
              <Route
                path="/withdrawmoney_chooseaccount"
                element={<Withdrawmoneychooseaccount />}
              />
              <Route
                path="/withdrawmoney_transfer"
                element={<Withdrawmoneytransfer />}
              />
              <Route
                path="/withdrawmoney_enterpin"
                element={<Withdrawmoneyenterpin />}
              />
              <Route
                path="/withdrawmoney_success"
                element={<Withdrawmoneysuccess />}
              />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/connect-bank" element={<ConnectBank />} />
              <Route path="/myprofile" element={<MyProfile />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/settings" element={<AccountSetting />} />
              <Route path="/request-money" element={<RequestMoney />} />
              <Route path="/send-money" element={<SendMoney />} />
              <Route path="/referral&credit" element={<Referral />} />
              <Route path="/support" element={<Support />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/chat" element={<ChatUI />} />

              <Route path="/chat1" element={<Example />} />

              <Route path="/transactions" element={<Transactions />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/spending" element={<Spending />} />
              <Route path="/account_limit" element={<Accountlimit />} />
              <Route path="/account_detail" element={<Accountdetails />} />
              <Route path="/exchange_rate" element={<Exchangerate />} />
            </Route>
          </Routes>
        </BrowserRouter>
        {/* <Footr/> */}
      </Suspense>
    </Fragment>
  );
}

export default App;
