export const actiontype = {
    USER_LOGIN: "USER_LOGIN",
    LOGIN_ERROR: "LOGIN_ERROR",
    SIGNUP_SUCCESSFULL: "SIGNUP_SUCCESSFULL",
    SIGNUP_ERROR: "SIGNUP_ERROR",
    SIGNUP_USER_FROM_EMAIL_SUCCESSFULL: "SIGNUP_USER_FROM_EMAIL_SUCCESSFULL",
    SIGNUP_USER_FROM_EMAIL_ERROR: "SIGNUP_USER_FROM_EMAIL_ERROR",
    RESET_PASSWORD_SUCCESS: "RESET PASSWORD_SUCESS",
    RESET_PASSWORD_REQUEST: " RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
    VERIFY_OTP_REQUEST: " VERIFY_OTP_REQUEST",
    VERIFY_OTP_FOR_RESET_PASS_SUCCESS: " VERIFY_OTP_FOR_RESET_PASS_SUCCESS",
    VERIFY_OTP_FOR_RESET_PASS_FAIL: " VERIFY_OTP_FOR_RESET_PASS_FAIL",
    SET_CONFIRM_PASS_REQUEST: "SET_CONFIRM_PASS_REQUEST",
    SET_CONFIRM_PASS_SUCCESS: " SET_CONFIRM_PASS_SUCCESS",
    SET_CONFIRM_PASS_FAIL: " SET_CONFIRM_PASS_FAIL",
    SOCIAL_SIGNUP_SUCCESS: "SOCIAL_SIGNUP_SUCCESS",
    SOCIAL_SIGNUP_FAIL: "SOCIAL_SIGNUP_FAIL",
    GET_PROFILE_LIST: "GET_PROFILE_LIST",
    FOR_BANK_LIST:"FOR_BANK_LIST",
}