import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCqjo3AQCEImGNRkXFtecRGAREMo2S-JcE",
  authDomain: "simplisendpayment.firebaseapp.com",
  projectId: "simplisendpayment",
  storageBucket: "simplisendpayment.appspot.com",
  messagingSenderId: "119800894754",
  appId: "1:119800894754:web:27fb4d05aa74d6b444417b",
  measurementId: "G-NQ91HMBSK4"
};

const app = initializeApp(firebaseConfig);
export const auth=getAuth(app)



