import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import LineChart from "react-linechart";
import "./dashboard.css";
import "../../../node_modules/react-linechart/dist/styles.css";
import { getProfileDetails } from "../../Redux/action/Profile";
import { useDispatch, useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import ProfileModal from "./ProfileModal";
// import Header from "../header/Header";

export default function Header() {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const options = useMemo(() => countryList().getData(), []);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const changeHandler = (value) => {
    setValue(value);
  };
  const dispatch = useDispatch();
  const data = [
    {
      color: "steelblue",
      points: [
        { x: 1, y: 2 },
        { x: 3, y: 5 },
        { x: 7, y: -3 },
      ],
    },
  ];

  const [userDetails] = useSelector((Gstate) => [
    Gstate?.ProfileReducers?.userDetails,
  ]);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [userDetails?.length]);

  const handleAPI = () => {
    dispatch(getProfileDetails());
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);
  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <nav className="navbar navbar-expand-lg px-5">
        <a className="navbar-brand" href="/dashboard">
          <img src="./assets/img/logo.png " />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <FaBars />
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* <ul className="navbar-nav mr-auto pl-5">
                        <li className="nav-item active px-3">
                            <a className="nav-link" href="/dashboard">
                                Home
                            </a>
                        </li>
                        <li className="nav-item mx-4 px-3">
                            <a className="nav-link" href="/transactions">
                                Transaction
                            </a>
                        </li>
                        <li className="nav-item px-3">
                            <a className="nav-link" href="#">
                                Recipient
                            </a>
                        </li>
                    </ul> */}
          <ul className="navbar-nav mr-auto pl-5 text-end">
            <li
              className={`nav-item px-3 ${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
            >
              <NavLink exact to="/dashboard" className="nav-link">
                Home
              </NavLink>
            </li>
            <li
              className={`nav-item  px-3 ${
                location.pathname === "/transactions" ? "active" : ""
              }`}
            >
              <NavLink exact to="/transactions" className="nav-link">
                Transaction
              </NavLink>
            </li>
            <li
              className={`nav-item px-3 ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <NavLink exact to="/" className="nav-link">
                Recipient
              </NavLink>
            </li>
          </ul>
          <div className="right-side">
            <div className="langage">
              <Select
                options={countries}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#283593",
                  },
                })}
                value={selectedCountry}
                onChange={(selectedOption) =>
                  setSelectedCountry(selectedOption)
                }
              />
            </div>
            <div className="profile-btn" onClick={handleShow}>
              <img
                className="barCode img img-fluid"
                src="./images/barCode.png"
              />

              {userDetails?.profileImage ? (
                <img
                  src={userDetails?.profileImage}
                  className="profileImgIcon img img-fluid"
                />
              ) : (
                <span className="profileLetter px-2 py-1">
                  {userDetails?.firstName?.[0]}
                </span>
              )}
            </div>
          </div>
        </div>
      </nav>
      <ProfileModal show={show} handleClose={handleClose} />
    </>
  );
}
