import React, { useState } from "react";
import Header from "../Dashboard/Header";
import {
  Container,
  Form,
  Accordion,
  FormCheck,
  FormControl,
  Button,
} from "react-bootstrap";
import "./Profile.css";
import { AiOutlineFileText } from "react-icons/ai";
import { MdOutlineVerifiedUser } from "react-icons/md";

function Legal() {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Header />
      <div>
        <Container>
          <div className="profileHeadingBox my-5">
            <h5>Legal </h5>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="settingSelect">
                {" "}
                <AiOutlineFileText className="headingIcon" />
                Terms of Service
              </Accordion.Header>
              <Accordion.Body>
                <div className="legalBox">
                  <h6 className="my-3">Update: 21 December 2022</h6>
                  <p>
                    These terms of condition (“Terms”) govern your use of the
                    Finance App (“App”) and the services provided by the App. By
                    using the App, you agree to be bound by these Terms. If you
                    do not agree with these Terms, you should not use the App.
                  </p>
                  <p>
                    Services: The App provides financial management and tracking
                    services. You may use the App to manage your finances,
                    including tracking your spending and monitoring your bank
                    accounts.
                  </p>
                  <p>
                    Eligibility: To use the App, you must be 18 years of age or
                    older and have the legal capacity to enter into a binding
                    agreement.
                  </p>
                  <p>
                    Account Information: To use the App, you must create an
                    account and provide us with accurate and complete
                    information, including your name, email address, and phone
                    number. You are responsible for maintaining the
                    confidentiality of your account information and for all
                    activities that occur under your account.
                  </p>
                  <p>
                    Financial Information: You understand that the App requires
                    access to your financial information, including bank account
                    numbers, transaction history, and credit card information.
                    You agree to provide accurate and complete financial
                    information to the App. Usage Information: You agree that we
                    may collect information about how you use the App, including
                    the frequency and duration of use. This information is used
                    to improve the App and provide you with better services.
                    <br />
                    Termination: We may terminate your use of the App at any
                    time and without notice if you violate these Terms. Upon
                    termination, your account and access to the App will be
                    revoked. <br />
                    Disclaimers: The App is provided on an “as is” basis without
                    any warranties of any kind, either express or implied. We do
                    not guarantee that the App will always be available or that
                    it will be error-free. Limitation of Liability: In no event
                    shall we be liable for any damages arising out of or in
                    connection with your use of the App, including but not
                    limited to direct, indirect, incidental, special, or
                    consequential damages.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="settingSelect">
                {" "}
                <MdOutlineVerifiedUser className="headingIcon" /> Privacy Policy
              </Accordion.Header>
              <Accordion.Body>
                <div className="legalBox">
                  <h6 className="my-3">Update: 21 December 2022</h6>
                  <p>
                    Introduction: Our Finance App (“App”) is dedicated to
                    protecting the privacy of its users. This Privacy Policy
                    outlines the types of information we collect, how we use it,
                    and the steps we take to ensure it is protected.
                  </p>
                  <p>
                    Services: The App provides financial management and tracking
                    services. You may use the App to manage your finances,
                    including tracking your spending and monitoring your bank
                    accounts.
                  </p>
                  <p>
                    Eligibility: To use the App, you must be 18 years of age or
                    older and have the legal capacity to enter into a binding
                    agreement.
                  </p>
                  <p>
                    Account Information: To use the App, you must create an
                    account and provide us with accurate and complete
                    information, including your name, email address, and phone
                    number. You are responsible for maintaining the
                    confidentiality of your account information and for all
                    activities that occur under your account.
                  </p>
                  <p>
                    Financial Information: You understand that the App requires
                    access to your financial information, including bank account
                    numbers, transaction history, and credit card information.
                    You agree to provide accurate and complete financial
                    information to the App. Usage Information: You agree that we
                    may collect information about how you use the App, including
                    the frequency and duration of use. This information is used
                    to improve the App and provide you with better services.
                    <br />
                    Termination: We may terminate your use of the App at any
                    time and without notice if you violate these Terms. Upon
                    termination, your account and access to the App will be
                    revoked. <br />
                    Disclaimers: The App is provided on an “as is” basis without
                    any warranties of any kind, either express or implied. We do
                    not guarantee that the App will always be available or that
                    it will be error-free. Limitation of Liability: In no event
                    shall we be liable for any damages arising out of or in
                    connection with your use of the App, including but not
                    limited to direct, indirect, incidental, special, or
                    consequential damages.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </>
  );
}

export default Legal;
