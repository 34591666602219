import React, { Fragment, useEffect } from "react";
import "./header.css";
import { Link, Outlet } from "react-router-dom";

const Header = () => {
  return (
    <Fragment>
      {/* <div id="nav">
      <nav className="navbar navbar-expand-sm navbar-light bg-light">
  <div className="container">
    <Link className="navbar-brand" to='/'><img src="./image 14.png" className="banner"/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    
    <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
         <Link to="/howitworks">How it works</Link>
        </li>
        <li className="nav-item">
        <Link to="/refer">Refer a friend</Link>
        </li>
        <li className="nav-item">
        <Link to="/login">Login</Link>
        </li>
        <li className="nav-item">
        <Link to="/help">Help</Link>
        </li>
        <li className="nav-item">
        <Link to="/account">My account</Link>
        </li>
        <li className="nav-item">
        <Link to="/signup">Sign up</Link>
        </li>
       
              </ul>
    </div>
  </div>
</nav>
      </div>
     <div >
        <Outlet/>
     </div> */}
      <Link to="/login"></Link>
      <Link to="/signup"></Link>
      <Outlet />

    </Fragment>
  );
};

export default Header;
