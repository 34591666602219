import React, { useState } from "react";
import { Button, Container, Row, Col, Nav } from "react-bootstrap";
import "./Money.css";
import Header from "../Dashboard/Header";
import RequestContact from "./RequestContact";

function RequestMoney() {
  const [showFirstComponent, setShowFirstComponent] = useState(true);
  const [showSecondComponent, setShowSecondComponent] = useState(false);
  const [thirdComponent, setThirdComponent] = useState(false);
  const [show, setShow] = useState(false);
  const [tabshow, setTabShow] = useState(true);

  const handleContinue = () => {
    setShowFirstComponent(false);
    setShowSecondComponent(true);
  };

  const handleThird = () => {
    setShowFirstComponent(false);
    setShowSecondComponent(false);
    setThirdComponent(true);
  };
  const handleShow = () => {
    setShowFirstComponent(false);
    setShowSecondComponent(false);
    setThirdComponent(false);
    setShow(true);
    setTabShow(false);
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {};
  return (
    <>
      <Header />
      <Container align="center">
        <img className="cancelImg" src="./images/cancelImg.png" />
        {tabshow && (
          <Nav
            className="d-flex justify-content-center"
            variant="tabs"
            defaultActiveKey="/send-money"
          >
            <Nav.Item>
              <Nav.Link href="/send-money" className="tabTypo">
                Get paid with ease
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1" className="tabTypo">
                Payment link
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2" className="tabTypo">
                Share a payment link
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {showFirstComponent ? (
          <>
            <div>
              <img src="./images/line1.png" className="img img-fluid" />
            </div>
            <div>
              <img
                src="./images/getPaidImg.png"
                className="img img-fluid mt-4"
              />
            </div>
            <div className="getPaidBox mt-5">
              <h6>Get paid with ease</h6>
              <p>
                Request money is a new way to get anyone or your friends to pay
                you online. They use a link to see your request and to pay you
                directly.
              </p>
              <Button variant="success" onClick={handleContinue}>
                Continue
              </Button>
            </div>
          </>
        ) : showSecondComponent ? (
          <>
            <div>
              <img src="./images/line2.png" className="img img-fluid" />
            </div>
            <div>
              <img
                src="./images/PaymentLinkImg.png"
                className="img img-fluid mt-4"
              />
            </div>
            <div className="getPaidBox mt-5">
              <h6>Payment link</h6>
              <p>
                When you request money and share a payment link, the recipient
                can pay via easy bank transfer or by debit or credit card .
              </p>
              <Button variant="success" onClick={handleThird}>
                Continue
              </Button>
            </div>
          </>
        ) : thirdComponent ? (
          <>
            <div>
              <img src="./images/line3.png" className="img img-fluid" />
            </div>
            <div>
              <img
                src="./images/shareLinkImg.png"
                className="img img-fluid mt-4"
              />
            </div>
            <div className="getPaidBox mt-5">
              <h6>Share a payment link</h6>
              <p>
                You'll have the option to 'Share payment link'. You can share
                your payment link online, via email, via SMS, or Whatsapp.
              </p>
              <Button variant="success" onClick={handleShow}>
                Continue
              </Button>
            </div>
          </>
        ) : show ? (
          <RequestContact />
        ) : (
          " "
        )}
      </Container>
    </>
  );
}

export default RequestMoney;
