import axios from "axios";
import { actiontype } from "../actiontype/Actiontype";

export const Useraction = (input) => async (dispatch) => {
  console.log(input);
  let data;

  try {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (emailRegex.test(input.username)) {
      console.log("email");
      data = await axios.post(
        "https://admin.simplisend.com/v1/api/login/",
        {
          email: input.username,
          password: input.password,
        }
      );
      console.log("asaddf", data);
    } else {
      data = await axios.post(
        "https://admin.simplisend.com/v1/api/login/",
        {
          mobileNo: input.username,
          password: input.password,
        }
      );
    }
    dispatch({ type: actiontype.USER_LOGIN, payload: data });
    console.log(data);
  } catch (error) {
    dispatch({ type: actiontype.LOGIN_ERROR, payload: error });
  }
};

export const resetPassword = (input) => async (dispatch) => {
  console.log(input);

  try {
    dispatch({ type: actiontype.RESET_PASSWORD_REQUEST });

    const data = await axios.post(
      "https://admin.simplisend.com/v1/api/forgotpass",
      {
        email: input,
      }
    );
    dispatch({ type: actiontype.RESET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: actiontype.RESET_PASSWORD_SUCCESS, payload: error });
  }
};
export const verifyOtpaction = (email, otp) => async (dispatch) => {
  console.log(email, otp);
  const otpnumber = +otp;
  try {
    dispatch({ type: actiontype.VERIFY_OTP_REQUEST });
    const data = await axios.post(
      "https://admin.simplisend.com/v1/api/verifyotp",
      {
        email,
        otp: otpnumber,
      }
    );

    dispatch({
      type: actiontype.VERIFY_OTP_FOR_RESET_PASS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actiontype.VERIFY_OTP_FOR_RESET_PASS_FAIL,
      payload: error,
    });
  }
};

export const setNewapssword = (email, pass) => async (dispatch) => {
  console.log(email, pass);
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const numberRegex = /^\+?[0-9]+$/;
  let data;
  try {
    dispatch({ type: actiontype.SET_CONFIRM_PASS_REQUEST });
    if (emailRegex.test(email)) {
      data = await axios.post(
        "https://admin.simplisend.com/v1/api/changePassword",
        {
          email,
          newpassword: pass,
        }
      );
      dispatch({ type: actiontype.SET_CONFIRM_PASS_SUCCESS, payload: data });
    } else if (numberRegex.test(email)) {
      data = await axios.post(
        "https://admin.simplisend.com/v1/api/changePassword",
        {
          mobileNo: email,
          newpassword: pass,
        }
      );
      dispatch({ type: actiontype.SET_CONFIRM_PASS_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: actiontype.SET_CONFIRM_PASS_FAIL, payload: error });
  }
};
