import React, { Fragment } from "react";
import Metadecorator from "../../Metadecorator";
import "./help.css";
import Socialsection from "../socialsection/Socialsection";
import Footr from "../Footer/Footr";
const Help = () => {
  return (
    <Fragment>
      <Metadecorator
        title="Need help"
        description="we are ready to help 24 hours"
        imageurl="hfhfh"
      />
      <div className="help-section">
        <p className="help">How can we help you?</p>
      </div>
      <div className="help-section-2">
        <p className="help-2">
          Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
          Velit officia consequat duis enim velit mollit. Exercitation veniam
          consequat sunt nostrud amet.
        </p>
      </div>
      <div
        style={{ width: "646px", height: "64px" }}
        className="search-wrapper"
      >
        <img src="./Ellipse 65.png" alt="ellipse" className="searchicon" />
        <img src="./Vector 109.png" alt="ellipse" className="searchicon-2" />
        <input
          type="search"
          placeholder="Start typing here..."
          className="search-bar"
        />
        <div className="search-button-wrapper">
          <button type="search" className="search-button">
            search
          </button>
        </div>
      </div>
      <div className="help-card">
        <p className="guide">Guide</p>
        <p className="guide-section">
          Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
        </p>
      </div>
      <div className="help-card-2">
        <p className="guide">Faq</p>
        <p className="guide-section">
          Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
        </p>
      </div>
      <div className="help-card-3">
        <p className="guide">Community</p>
        <p className="guide-section">
          Minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
        </p>
      </div>
      <div className="faq3">
        <p className="content">Frequently Asked Questions</p>
        <p className="content-2">
          Lorem ipsum minim mollit non deserunt ullamco est ?
        </p>
        <p className="content-3">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <hr className="line"></hr>
        <p className="content-2">
          Lorem ipsum minim mollit non deserunt ullamco est ?
        </p>
        <hr className="line"></hr>
        <p className="content-2">
          Lorem ipsum minim mollit non deserunt ullamco est ?
        </p>
        <hr className="line"></hr>
        <p className="content-2">
          Lorem ipsum minim mollit non deserunt ullamco est ?
        </p>
        <hr className="line"></hr>
        <p className="content-2">
          Lorem ipsum minim mollit non deserunt ullamco est ?
        </p>
        <hr className="line"></hr>
        <p className="content-2">
          Lorem ipsum minim mollit non deserunt ullamco est ?
        </p>
        <hr className="line"></hr>
        
      </div>
     <Socialsection/>
     <Footr/>
    </Fragment>
  );
};

export default Help;
