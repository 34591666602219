import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./check.css";

const DateofBirth = () => {
  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <div className="date-picker-container">
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={handleDateChange}
        placeholderText="DD/MM/YY"
        maxDate={new Date()}
        className="date-picker"
      />
    </div>
  );
};

export default DateofBirth;
