import { actiontype } from "../actiontype/Actiontype"

let inititalState={
    Email_reset_pass:{},
    error:""
}


const ResetpassReducer=(state=inititalState,action)=>{
    switch (action.type) {
        case actiontype.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                Email_reset_pass:{}
            }
        case actiontype.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                Email_reset_pass:action.payload
            }
         
            
         case actiontype.RESET_PASSWORD_FAIL:
            return{
            ...state,
            error:action.payload
            }
    
        default:
            return state
    }
}

export default ResetpassReducer

export const resetPass = () => {
    return { type: actiontype.RESET_PASSWORD_REQUEST };
  };

