import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Loginresetpass.css'
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import { setNewapssword } from "../../Redux/action/Useraction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { confirmPassrest } from "../../Redux/reducer/Confirmpassreducer";
const LoginresetPassword= (props) => {
  const navigate=useNavigate()
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const [upperCase, setUppercase] = useState(false);
  const [checknumber, SetNumber] = useState(false);
  const [character, setcharacter] = useState(false);
  const [value,setvalue]=useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const [disable, setdisable] = useState(true);
  const [allConditionsMet, setAllConditionsMet] = useState(false);
  const [showpassword,setShowpassword]=useState(false)
  const [showConfirmpassword,setShowConfirmpassword]=useState(false)
  const dispatch=useDispatch()
 const success= useSelector(state=>(state?.ConfirmpassReducer?.confirmPass?.data?.response?.message?.successMessage))
const error=useSelector(state=>state?.ConfirmpassReducer?.confirmPass?.data?.error?.errorMessage) 

  const Handlechange = (event) => {
  
    setPassword(event.target.value);
    if (/[A-Z]/.test(event.target.value)) {
      setUppercase(true);
    } else {
      setUppercase(false);
    }

    if (/[0-9]/.test(event.target.value)) {
      SetNumber(true);
    } else {
      SetNumber(false);
    }
    if (/[!@#%^&*]/.test(event.target.value)) {
      setcharacter(true);
    } else {
      setcharacter(false);
    }
    if(event.target.value.length >= 8){
      setvalue(true)

    }
    else{
      setvalue(false)
    }
  };
  const Confirmpass = (event) => {
    if (password === event.target.value) {
      setdisable(false);
      // setconfirmpassfocus(true);
      setConfirmPassword(event.target.value);
    } else {
      setConfirmPassword("");
      setdisable(true);
    }
  };
 
  
    
  const handleBlur = () => {
    setHasFocus(false);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
   
   
    dispatch(setNewapssword(props.verifyemail,password))
    
    
  };
  useEffect(()=>{
    if (upperCase && checknumber && character && value) {
      setAllConditionsMet(true);
      // setdisable(true)
    } else {
      setAllConditionsMet(false);
     setdisable(true)
    }
    if(error){
      toast.error(error)
    }
    else if(success){
     navigate("/login_password_reset_succes")
    }
    return () => {
     dispatch(confirmPassrest())
    };
    },[success,error,dispatch,upperCase, checknumber, character, value])
  
  return (
    <Fragment>
      <div className="content">
      <Toaster toastOptions={{ duration: 2000 }} />
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5 singup" id="signup_pswrd" >
        <div className="cross-btn">
        <Link to="/"><img src="./dist/img/Vector (14).png" /></Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <h3 className="pt-5 pb-3">Reset password</h3>
            <p className="txt-3">
            It's important to choose a strong and unique password for your
  account to keep it secure.
            </p>
          
            <form onSubmit={handleSubmit} id="reset-password-login">
            <div className="form-group pt-5" style={{ position: "relative" }}>
                <input
                type={showpassword? "text":"password"}
                  className="form-control"
                  id="exampleInputEmail3"
                  aria-describedby="emailHelp"
                  placeholder="Password"
                  value={password}
                  onChange={Handlechange}
                  onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{
                        fontSize:"16px",
                        fontWeight:500,
                        color: "#161F4C",
                        background: "url('./dist/img/Lock_light.png') no-repeat",
                        outline:
                          allConditionsMet && (hasFocus || password !== "")
                            ? "2px solid #27AE60"
                            : "none",
                      }}
                />
                {showpassword ?<img src="./dist/img/eye.png" className="img-1" onClick={()=>setShowpassword(false)}/> : <img src="./dist/img/line-new.png" className="img-1" onClick={()=>setShowpassword(true)}/>}
              </div>
              <div className="form-group pt-2" style={{ position: "relative" }}>
                <input
                  type={showConfirmpassword?'text':"password"}
                className="form-control"
                  id="exampleInputEmail3"
                  aria-describedby="emailHelp"
                  placeholder="Confirm Password"
                  // value={confirmPassword}
                  onChange={Confirmpass}
                  style={{
                    background: "url('./dist/img/Lock_light.png') no-repeat",
                    outline: !disable ? "2px solid #27AE60":"none",
                    
                    fontSize:"16px",
                        fontWeight:500,
                        color: "#161F4C",
                    
                  }}
                />
                {showConfirmpassword ?<img src="./dist/img/eye.png" className="img-1 img-2" onClick={()=>setShowConfirmpassword(false)} style={{ margingTop:"-30px !important" }}/> : <img src="./dist/img/line-new.png" className="img-1 img-2" onClick={()=>setShowConfirmpassword(true)} style={{ margingTop:"-30px"}}/>}
              </div>
              <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="privacy" checked={upperCase} readOnly/>
                          <label className="form-check-label ml-3" htmlFor="privacy">
                              At least one upper case
                          </label>
                      </div>
                      <div className="form-check my-2">
                          <input className="form-check-input" type="checkbox" id="privacy" checked={checknumber} readOnly/>
                          <label className="form-check-label ml-3" htmlFor="privacy">
                              At least one number
                          </label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="privacy" checked={character} readOnly/>
                          <label className="form-check-label ml-3" htmlFor="privacy">
                              At least one special character(e.g $%&)
                          </label>
                      </div>
                      <div className="form-check mt-2">
                          <input className="form-check-input" type="checkbox" id="privacy" checked={value} readOnly/>
                          <label className="form-check-label ml-3" htmlFor="privacy">
                              At least 8 Characters
                          </label>
                      </div>
            
              <button type="submit" className="btn btn-2 btn-block mt-5"  disabled={disable} style={{padding:"12px",opacity:disable && "0.2"}}>
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
     
    </Fragment>
  );
};

export default LoginresetPassword;
