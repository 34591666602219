import React, { Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "../../Redux/action/Useraction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { resetPass } from "../../Redux/reducer/ResetpassReducer";
import { auth } from "../../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";

  const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState("+");
  const [countrycode, setCOuntrycode] = useState("");


  const error = useSelector(
    (state) => state.reset?.Email_reset_pass?.data?.error?.errorMessage
  );
  const success = useSelector(
    (state) =>
      state.reset?.Email_reset_pass?.data?.response?.message?.successMessage
  );

  useEffect(() => {
    if (success) {
      toast.success(success);
      setTimeout(() => {
        navigate("/login_verify");
        props.email(input);
      }, 2000);
    } else if (error) {
      toast.error(error);
    }
    dispatch(resetPass());
  }, [success, error, dispatch, input]);

  const ResetHandler = (e) => {
    e.preventDefault();
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const numberRegex = /^\+?[0-9]+$/;
    if (emailRegex.test(input)) {
      props.getactive("email");
     
   
      props.email(input)
      dispatch(resetPassword(input));
    } else if (numberRegex.test(input)) {
     
      const Isverifiednumber = async () => {
       
        props.getactive("mobile");
        props.email(input)
        
        
       
        try {
          const response = await fetch(
            "https://admin.simplisend.com/v1/api/account/existence/check",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                mobileNo: input,
              }),
            }
          );
          const result = await response.json();

          if (!result.response.isExists) {
            toast.error("this account is not exists");
          } else {
            setCOuntrycode(result.response.countryCode);
                 props.email(input);
            onOtpforMobile(result.response.countryCode);
            
            //  dispatch(resetPassword(`+${result.response.countryCode}` + input));
          }
        } catch (err) {
          console.log(err);
        }
      };
      Isverifiednumber();
    } else {
      toast.error("invalid mobile or phone number");
     
    }
  };

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onOtpforMobile();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
    if(window.recaptchaVerifier){
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onOtpforMobile();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
    window.recaptchaVerifier.render().then((widgetId) => {
      window.grecaptcha.reset(widgetId);
    });
   
  };
  const onOtpforMobile = (result) => {
   
    const phone = result.toString();
    
  
    const Formatphone = `+${phone}` + input;
   


    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, Formatphone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setTimeout(() => {
          toast.success("OTP sended successfully!");
         }, 1000)
        toast.success("OTP sended successfully!");
       navigate("/login_verify")
      })
        
      .catch((error) => {
       toast.error(error.code)
       if (error.code || undefined) {
        {
          window.recaptchaVerifier.render().then((widgetId) => {
            window.grecaptcha.reset(widgetId);
          });
        }
      }
      });
  };
useEffect(()=>{
localStorage.removeItem("mobile")
localStorage.removeItem("email")
},[])
  return (
    <Fragment>
      <div className="content">
        <Toaster toastOptions={{ duration: 2000 }} />
        <div
          id="recaptcha-container"
          style={{
            marginLeft: "18vh",
            marginTop: "30px",
            position: "absolute",
          }}
        ></div>
        <div className="header">
          <img src="./dist/img/image 15.png" className="img-fluid" />
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img src="./dist/img/Ellipse 5.png" />
          <div className="new-2">
            <img src="./dist/img/Mask group.png" />
          </div>
        </div>
      </div>
      <div className="container my-5" id="sign-up">
        <div className="cross-btn">
          <Link to="/">
            <img src="./dist/img/Vector (14).png" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 py-5">
            <h3 className="pt-5 pb-3" >Forgot password</h3>
            <p className="txt-3">
              Please enter the Email or mobile number. We will send an OTP to
              <br /> your registered Email or number to reset your password.
            </p>
            <form onSubmit={ResetHandler}>
              <div className="form-group pt-5 pb-3">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email/Mobile number"
                  onChange={(e) => setInput(e.target.value)}
                  style={{
                    fontWeight: 500,
    fontSize: "16px",
    color: "#161F4C",
    lineHeight: "14px",
    fontFamily: 'Outfit',
    fontStyle: "normal",}}
                />
              </div>
              <button
                type="submit"
            
                className="btn btn-2 btn-block"
                style={{ padding: "12px" }}
              >
                Send OTP
              
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
