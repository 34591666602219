import React, { useState } from "react";
import Header from "../Dashboard/Header";
import {
  Container,
  Form,
  Accordion,
  FormCheck,
  FormControl,
  Button,
} from "react-bootstrap";
import "./Profile.css";
import { BiHelpCircle } from "react-icons/bi";
import { AiOutlineMessage } from "react-icons/ai";

function Support() {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Header />
      <div>
        <Container>
          <div className="profileHeadingBox my-5">
            <h5>Support </h5>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="settingSelect">
                {" "}
                <BiHelpCircle className="headingIcon" />
                Get Help
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="settingSelect">
                {" "}
                <AiOutlineMessage className="headingIcon" /> Give Feedback
              </Accordion.Header>
              <Accordion.Body>
                <div className="classH5 mt-3">
                  <h5>Choose how people can find and pay you on Simplisend</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6>Simplisend.me Link</h6>
                      <p>
                        Allow other to find me at simplisend.me/@andrew.ainsley
                      </p>
                    </div>
                    <FormCheck
                      type="switch"
                      id="custom-switch"
                      checked={checked}
                      onChange={handleChange}
                    />
                  </div>
                  <h6>Phone Number</h6>
                  <p>Allow other to find me with +9217348732 </p>
                  <h6>Email Address</h6>
                  <p>Allow other to find me with andrew09@gmail.com</p>{" "}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </>
  );
}

export default Support;
